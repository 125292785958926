import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import userAvatar from '@/assets/user-avatar.jpg';

import './index.css';

class TroubleSomeList extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			
		}
	}

	render () {
		const { listItem } = this.props;
		const { user, comments } = listItem;
		return (
			<Fragment>
				{/* pc端 */}
				<ul className="troubleSomeWrap">
					<li className="troubleSomeContent clearfix">
						<div className="troubleSomeContentLeft">
							<h2 className="troubleSomeContentLeftTag">
								<NavLink   target="_blank"   to={`/communicateDetails/${listItem.id}`}>
								【疑难求助】
								</NavLink>
							</h2>
							<div className="troubleSomeContentLeftUserAvatar">
								<img src={ user ? user.avatar ? user.avatar : userAvatar : userAvatar } alt="" />
							</div>
							<p className="troubleSomeContentLeftUserName">{ user ? user.realname : '' }</p>
						</div>
						<div className="troubleSomeContentRight">
							<h3 className="troubleSomeContentRightTitle">
								<NavLink   target="_blank"   to={`/communicateDetails/${listItem.id}`}>
									{listItem.title}
								</NavLink>
							</h3>
							<div className="troubleSomeContentRightDescribe" dangerouslySetInnerHTML={{ __html: listItem.content }}></div>
							<ul className="troubleSomeContentRightOther clearfix">
								<li className="troubleSomeContentRightOtherContent">发帖时间：{moment(listItem.created_at).format('YYYY-MM-DD')}</li>
								<li className="troubleSomeContentRightOtherContent">回帖：{listItem.comments_count}</li>
								<li className="troubleSomeContentRightOtherContent">浏览：{listItem.view_num}</li>
							</ul>
							<div className="troubleSomeContentRightExcellent">
								<h3 className="troubleSomeContentRightExcellentTitle">精彩回帖</h3>
								<ul className="troubleSomeContentRightExcellentContentWrap">
									{
										comments.length > 0 ? comments.map((item, index) => {
											return (
												<li className="troubleSomeContentRightExcellentContent clearfix" key={item.id}>
													<div className="troubleSomeContentRightExcellentContentLeft">
														<img src={item.user ? item.user.avatar ? item.user.avatar : userAvatar : userAvatar } alt="" />
													</div>
													<div className="troubleSomeContentRightExcellentContentRight">
														<p className="troubleSomeContentRightExcellentContentRightExcellentResponse" dangerouslySetInnerHTML={{ __html: item.content }}></p>
														<p className="troubleSomeContentRightExcellentContentRightExcellentUserName">{item.user ? item.user.realname : '' }<span>{item.created_at}</span></p>
													</div>
												</li>
											)
										}) : ''
									}
								</ul>
							</div>
						</div>
					</li>
				</ul>
				{/* 移动端 */}
				<ul className="troubleSomeMobileWrap">
					<li className="troubleSomeMobileContent clearfix">
						<div className="troubleSomeMobileContentLeft">
							<h2 className="troubleSomeMobileContentLeftTag">
								<NavLink   target="_blank"   to={`/communicateDetails/${listItem.id}`}>
								【疑难求助】
								</NavLink>
							</h2>
							<div className="troubleSomeMobileContentLeftUserAvatar">
								<img src={ user ? user.avatar ? user.avatar : userAvatar : userAvatar } alt="" />
							</div>
							<p className="troubleSomeMobileContentLeftUserName">{ user ? user.realname : '' }</p>
						</div>
						<div className="troubleSomeMobileContentRight">
							<h3 className="troubleSomeMobileContentRightTitle">
								<NavLink   target="_blank"   to={`/communicateDetails/${listItem.id}`}>
									{listItem.title}
								</NavLink>
							</h3>
							<div className="troubleSomeMobileContentRightDescribe" dangerouslySetInnerHTML={{ __html: listItem.content }}></div>
							<ul className="troubleSomeMobileContentRightOther clearfix">
								<li className="troubleSomeMobileContentRightOtherContent">发帖时间：{moment(listItem.created_at).format('YYYY-MM-DD')}</li>
								<li className="troubleSomeMobileContentRightOtherContent">回帖：{listItem.comments_count}</li>
								<li className="troubleSomeMobileContentRightOtherContent">浏览：{listItem.view_num}</li>
							</ul>
							<div className="troubleSomeMobileContentRightExcellent">
								<h3 className="troubleSomeMobileContentRightExcellentTitle">精彩回帖</h3>
								<ul className="troubleSomeMobileContentRightExcellentContentWrap">
									{
										comments.length > 0 ? comments.map((item, index) => {
											return (
												<li className="troubleSomeMobileContentRightExcellentContent clearfix" key={item.id}>
													<div className="troubleSomeMobileContentRightExcellentContentLeft">
														<img src={item.user ? item.user.avatar ? item.user.avatar : userAvatar : userAvatar } alt="" />
													</div>
													<div className="troubleSomeMobileContentRightExcellentContentRight">
														<p className="troubleSomeMobileContentRightExcellentContentRightExcellentResponse" dangerouslySetInnerHTML={{ __html: item.content }}></p>
														<p className="troubleSomeMobileContentRightExcellentContentRightExcellentUserName">{item.user ? item.user.realname : '' }<span>{item.created_at}</span></p>
													</div>
												</li>
											)
										}) : ''
									}
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</Fragment>
		)
	}
}

TroubleSomeList.propTypes = {
	listItem: PropTypes.object.isRequired
}

TroubleSomeList.defaultProps = {
	listItem: {}
}

export default TroubleSomeList;
