import request from '@/utils/request'

// 获取banner
export function getBanner (params) {
  return request({
    url: '/banner',
    method: 'get',
    params
  })
}

export function getId (params) {
  return request({
    url: '/id',
    method: 'get',
    params
  })
}
