import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Pagination } from 'element-react';

import CommonSubnavLeft from '@/components/commonSubnavLeft';
import CommunicateAllList from '@/components/communicateAllList';
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';

import rightImg from '@/assets/rightImg.jpg';
import index17 from '@/assets/index_17.jpg';

import { getCommunicationList } from '@/api/communicate';

import './index.css';

const subNav = [
	{
		name: '全部',
		path: '/communicate/communicateAll'
	},
	{
		name: '读片分享',
		path: '/communicate/communicateReadShare'
	},
	{
		name: '精彩病例讨论',
		path: '/communicate/communicateCase'
	},
	{
		name: '国际病理交流',
		path: '/communicate/communicateInternational'
	},
	{
		name: '疑难求助',
		path: '/communicate/communicateTroublesome'
	},
	{
		name: '实事讨论',
		path: '/communicate/communicatePractical'
	}
]

class CommunicateAll extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			postData: {
				page: 1,
				start: 0,
				length: 20
			},
			articleList: '',
			articleListTotal: '',
			postList: [],
			responsesList: [],
			boardList: [],//公告栏
			hotList: []
		}
	}

	componentDidMount () {
		this.initPageList();
		this.initHotList();

		getCommunicationList({
			start: 0,
			length: 7
		}).then(res => {
			this.setState({
				postList: res.data.data
			})
		})

		getCommunicationList({
			order: 'comment',
			start: 0,
			length: 7
		}).then(res => {
			this.setState({
				responsesList: res.data.data
			})
		})
		//公告栏
		this.initBoardList();
	}

	initPageList () {
		getCommunicationList(this.state.postData).then(res => {
			this.setState({
				articleList: res.data.data,
				articleListTotal: res.data.total
			})
		})
	}

	// 分页
	handleSizeChange (val) {
		this.setState({
			postData: {
				page: val,
				start: val * 20,
				length: 20,
				kw: ''
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	handleCurrentChange (val) {
		this.setState({
			postData: {
				page: val,
				start: val * 20,
				length: 20,
				kw: ''
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	//公告栏
	initBoardList () {
		getCommunicationList({
			type: 6,
			length: 6
		}).then(res => {
			this.setState({
				boardList: res.data.data
			})
		})
	}

	initHotList() {
		getCommunicationList({
			hot: 1,
			length: 6
		}).then(res => {
			console.log(res.data.data)
			this.setState({
				hotList: res.data.data
			})
		})
	}

	render () {
		const { articleList, articleListTotal, postData, postList, responsesList, boardList, hotList } = this.state;
		const token = sessionStorage.getItem('token');
		return (
			<Fragment>
				{/* pc */}
				<div className="communicateAllWrap">
					{/* 内容左部分 */}
					<div className="communicateAllContentLeft">
						<CommonSubnavLeft subNav={subNav} {...this.props} />
						<div className="communicateAllContentLeftFirst">
							<h1 className="communicateAllContentLeftFirstTitle">热门讨论</h1>
							<div className="communicateAllContentLeftFirstContent clearfix">
								<div className="communicateAllContentLeftFirstContentLeft">
										<img className="maxWidth" src="http://api.remote.dakapath.com/uploads/image/20180622/13/img0792.jpg" alt="" />
										<p>宫颈腺癌还是鳞癌</p>
								</div>
								<ul className="communicateAllContentLeftFirstContentRight">
									{
										hotList.length > 0 ? hotList.map((item, index) => {
											return  (
												<li>
													<NavLink   target="_blank"   to={`/communicateDetails/${item.id}`}>{item.title}</NavLink>
												</li>
											)
										}) : ''
									}
								</ul>
							</div>
						</div>
						<div className="communicateAllContentLeftSecond">
							{
								articleList.length > 0 ? articleList.map((item, index) => {
									return <CommunicateAllList listItem={item} key={index} />
								}) : ''
							}
							{
								articleList.length > 0 ? <div className="communicateAllContentLeftSecondPagination">
								<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
							</div> : ''
							}
						</div>
					</div>
					{/* 内容右部分 */}
					<div className="communicateAllContentRight">
						<div className="communicateAllContentRightFirst">
							<NavLink   target="_blank"   to={!token ? '/login' : '/communicate/communicateReadShare'}>一键发帖</NavLink>
						</div>
						<div className="communicateAllContentRightSecond">
							<CommonTitle title="公告栏" showRightIcon={true} />
							<div className="communicateAllContentRightSecondContent">
								{
									boardList.length > 0 ? boardList.map((item, index) => {
										return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/communicateDetails/${item.id}`} />)
									}) : ''
								}
							</div>
						</div>
						<div className="communicateAllContentRightFour">
							<CommonTitle title="最近主贴" />
							<div className="communicateAllContentRightFourContent">
								{
									postList.length > 0 ? postList.map((item, index) => {
										return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/communicateDetails/${item.id}`} />)
									}) : ''
								}
							</div>
						</div>
						<div className="communicateAllContentRightFifth">
							<CommonTitle title="最近回复" />
							<div className="communicateAllContentRightFifthContent">
								{
									postList.length > 0 ? postList.map((item, index) => {
										return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/communicateDetails/${item.id}`} />)
									}) : ''
								}
							</div>
						</div>
						<div className="communicateAllContentRightSixth">
							<NavLink   target="_blank"   to="/home">
								<img src="http://dia.dakapath.com/Public/static/media/index_17.f182e9eb.jpg" alt="searchRightImgindex17" />
							</NavLink>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CommunicateAll;
