import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// 获取轮播和分页组件
import { Carousel, Pagination } from 'element-react';

import CommonSubnav from '@/components/commonSubnav';
import CommonSpecialTopicList from '@/components/commonSpecialTopicList';
import CommonCategory from '@/components/commonCategory';
import CommonTitleRight from '@/components/commonTitleRight';
import CommonTitle from '@/components/commonTitle';

import findBanner from '@/assets/findBanner.jpg';
import bannerh from '@/assets/banner-h.jpg';

// 获取文章列表接口
import { getArticleList } from '@/api/microClass.js';
// 获取banner接口
import { getBanner } from '@/api/common.js';

const subNav = [
	{
		name: '活动',
		path: '/find/activity'
	},
	{
		name: '专题',
		path: '/find/specialTopic'
	},
	{
		name: '会议',
		path: '/find/meeting'
	}
]
class Meeting extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			postData: {
				page: 1,
				category_id: 60,
				start: 0,
				length: 20
			},
			bannerList: [],// 获取轮播
			articleList: [],// 获取文章列表
			articleListTotal: '',
			nowList: '',
			selectTime: '',
			selectCurrentDate: '2020',
			showDateList: false,
			dateList: [

				['2020.01','2020.02','2020.03','2020.04','2020.05','2020.06','2020.07','2020.08','2020.09','2020.10','2020.11','2020.12'],
				['2021.01','2021.02','2021.03','2021.04','2021.05','2021.06','2021.07','2021.08','2021.09','2021.10','2021.11','2021.12'],
				['2022.01','2022.02','2022.03','2022.04','2022.05','2022.06','2022.07','2022.08','2022.09','2022.10','2022.11','2022.12'],
			]
		}
	}

	componentDidMount () {
		// 获取轮播
		this.initBanner();
		// 获取文章列表
		this.initPageList();
		this.initNow();
	}

	// 获取轮播
	initBanner () {
		getBanner({
			type: 4
		}).then(res => {
			if (res.success) {
				this.setState({
					bannerList: res.data
				})
			}
		})
	}

	// 获取文章列表
	initPageList () {
		getArticleList(this.state.postData).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data,
					articleListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 60,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	handleCurrentChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 60,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	// 最近更新
	initNow () {
		getArticleList({
			category_id : 58,
			length: 9
		}).then(res => {
			if (res.success) {
				this.setState({
					nowList: res.data.data
				})
			}
		})
	}

	showDateList () {
		const { showDateList } = this.state;
		this.setState({
			showDateList: !showDateList
		})
	}

	selectDateOne (date) {
		this.setState({
			selectCurrentDate: date,
			showDateList: false
		})
		this.carousel.current.setActiveItem(0);
	}

	selectDateTwo (date) {
		this.setState({
			selectCurrentDate: date,
			showDateList: false
		})
		this.carousel.current.setActiveItem(1);
	}

	selectDateThree (date) {
		this.setState({
			selectCurrentDate: date,
			showDateList: false
		})
		this.carousel.current.setActiveItem(2);
	}

	selectDate (item) {
		let getDate = item.replace(/\./, '-');
		this.setState({
			selectTime: getDate,
			postData: {
				page: 1,
				category_id: 58,
				start: 0,
				length: 20,
				meet_time: getDate
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	render () {
		const { articleList, articleListTotal, postData, nowList, bannerList, dateList, selectCurrentDate, showDateList } = this.state;
		return (
			<Fragment>
				<CommonSubnav subNav={subNav} {...this.props} />
				<div className="findContentLeft">
					<div className="findContentLeftFirst">
						<Carousel height="3.14rem" indicatorPosition="none">
						  {
							bannerList.map((item, index) => {
							  return (
								<Carousel.Item key={item.id}>
									<a  href={item.link}>
										<img src={item.image ? item.image.src : ''} />
									</a>
								</Carousel.Item>
							  )
							})
						  }
						</Carousel>
					</div>
					<div className="findContentLeftSecond">
						{
							articleList.length > 0 ? articleList.map((item, index) => {
								return (
									<CommonSpecialTopicList key={item.id} item={item} />
								)
							}) : ''
						}
						{
							articleList.length > 0 ? <div className="findContentLeftSecondPagination">
							<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
						</div> : ''
						}
					</div>
				</div>
				<div className="findContentRight">
					<div className="findContentRightFirst">
						<div className="findContentRightFirstContent clearfix">
							<div className="findContentRightFirstContentLeft"></div>
							<h1 className="findContentRightFirstContentMiddle">会议日历</h1>
							<div className="findContentRightFirstContentRight">
								<h1 onClick={this.showDateList.bind(this)}>{selectCurrentDate}</h1>
								{
									showDateList && <ul>
										<li onClick={this.selectDateTwo.bind(this, '2020')}>2020</li>
										<li onClick={this.selectDateThree.bind(this, '2021')}>2021</li>
										<li onClick={this.selectDateOne.bind(this, '2022')}>2022</li>
								</ul>
								}
							</div>
						</div>
						<div className="findContentRightFirstContentList">
							<Carousel height="1.6rem" indicatorPosition="none" arrow="never" autoplay={false} ref={this.carousel}>
							  {
								dateList.map((item, index) => {
								  return (
									<Carousel.Item key={index}>
										<ul>
											{
												item.map((item, index) => {
													return (
														<li onClick={this.selectDate.bind(this, item)}>{item}</li>
													)
												})
											}
										</ul>
									</Carousel.Item>
								  )
								})
							  }
							</Carousel>
						</div>
					</div>
					<div className="findContentRightSecond">
						<CommonTitle title="最近更新" showRightIcon={true} toPath="/find/meeting" />
						<div className="findContentRightSecondContent">
							{
								nowList.length > 0 ? nowList.map((item, index) => {
									if (index > 6) {
										return ''
									}
									return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={`【会议】${item.title}`} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
								}) : ''
							}
						</div>
					</div>
					<div className="findContentRightThird">
						<CommonTitle title="会议" showRightIcon={true} toPath="/find/meeting" />
						<div className="findContentRightThirdContent">
								<div className="findContentRightThirdContentImg">
									<img src={nowList[7] ? nowList[7].cover.src : '' }  />
								</div>
								{
									nowList.length > 0 ? nowList.map((item, index) => {
										if (index < 7){
											return ''
										}
										return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={`【会议】${item.title}`} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
									}) : ''
								}
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default Meeting;
