import React from 'react';
import ReactDOM from 'react-dom';
import 'element-theme-default';
import './index.css';
// import 'bootstrap/dist/css/bootstrap.css';
import '@/utils/rem.js';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
//
// // 禁用右键默认行为
// document.oncontextmenu = function (evt) {
//     evt.preventDefault();
// };
//
// // 属性返回false
// document.oncontextmenu = function(evt) {
//     evt.returnValue = false;
// }
//
// document.oncopy = function(evt) {
//     evt.returnValue = false;
// }
//
//
// // 或者直接返回整个事件
// document.oncontextmenu = function(){
//     return false;
// }

