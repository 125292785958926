import request from '@/utils/request'

// 获取交流列表
export function getCommunicationList (params) {
  return request({
    url: '/communication/list',
    method: 'get',
    params
  })
}

// 获取交流列表详情
export function getCommunicationListDetail (params) {
  return request({
    url: '/communication/detail',
    method: 'get',
    params
  })
}

// 发表帖子
export function communicationCreate (params) {
  return request({
    url: '/communication/create',
    method: 'post',
    data: params
  })
}

// 回帖
export function communicationCommentCreate (params) {
  return request({
    url: 'communication/comment/create',
    method: 'post',
    data: params
  })
}

// // 文章删除
// export function deleteArticle (id) {
//   return request({
//     url: '/article/delete',
//     method: 'post',
//     data: {
//       id
//     }
//   })
// }