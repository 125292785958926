import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './index.css';

class CommonCategory extends Component {
	
	constructor(props) {
		super(props);
	    this.state = {
			
		}
	}
	
	toDetail () {
		const { toPath } = this.props;
		this.props.history.push(toPath)
	}
	
	render () {
		const { categoryName, isShowRightNum, rightNum, toPath } = this.props;
		return (
			<Fragment>
				<div className="commonCategoryContainer clearfix">
					<h1 className="commonCategoryLeft" onClick={this.toDetail.bind(this)}>{categoryName}</h1>
					{ isShowRightNum && <div className="commonCategoryRight">({rightNum})</div> }
				</div>
			</Fragment>
		)
	}
}

CommonCategory.propTypes = {
	categoryName: PropTypes.string.isRequired,
	isShowRightNum: PropTypes.bool,
	rightNum: PropTypes.string,
}
CommonCategory.defaultProps = {
    categoryName: '微课堂分类',
    isShowRightNum: true,
    rightNum: '27'
}

export default CommonCategory;
