import React, { Component, Fragment } from 'react';
import { setData, getData, removeData } from '@/utils/localStorageData.js';
import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';
import { NavLink } from 'react-router-dom';
import misc from '@/assets/misc.gif';
import { getCatchaImg, toUserLogin } from '@/api/login';
import './index.css';
import {setCookie} from "../../utils/cookies";

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tel: '',
			telFlag: false,
			pas: '',
			pasFlag: false,
			code: '',
			codeFlag: false,
			memory: true,
			errMsg: '',
			codeImg: null,
			key: null
		}
		// 图片验证码
		this.initCatchaImg = this.initCatchaImg.bind(this);
		// 通用的获取焦点
		this.handleInputFocus = this.handleInputFocus.bind(this);
		// 用户名和手机号失去焦点
		this.handleTelInputBlur = this.handleTelInputBlur.bind(this);
		// 密码失去焦点
		this.handlePasInputBlur = this.handlePasInputBlur.bind(this);
		// 验证码失去焦点
		this.handleCodeInputBlur = this.handleCodeInputBlur.bind(this);
		// 是否记住密码
		this.handleMemoryInpuChange = this.handleMemoryInpuChange.bind(this);
		// 登录
		this.toLogin = this.toLogin.bind(this);
	}

	componentDidMount () {
		// CommunicationCount: 46
		// avatar: null
		// birthday: null
		// company: null
		// created_at: "2019-11-06 06:45:56"
		// deleted_at: null
		// education: null
		// email: "76563767@qq.com"
		// id: 6
		// integral: 0
		// is_enabled: 1
		// is_root: 1
		// level: null
		// password: "$.e960eb146b2f8943ccff4230d560741d"
		// professional: null
		// realname: "山地车123"
		// region: null
		// salt: "45acda"
		// sex: null
		// telephone: "111222333"
		// token: "26701c7dd31aecd985518c3b15fa430e"
		// updated_at: "2019-11-06 06:45:56"
		// user_id: 2106227561223087
		// username: "root"
		this.initCatchaImg();

		if (sessionStorage.getItem('token')) {
			this.props.history.push('/home');
		}
		if (getData("remember")) {
			const remember = JSON.parse(getData("remember"));
			const { tel, pas } = remember;
			this.setState({
				tel,
				pas
			})
		}
	}

	// 初始化图片验证码
	initCatchaImg () {
		getCatchaImg({}).then(res => {
			if (res.success) {
				const { img, key, sensitive } = res.data;
				this.setState({
					codeImg: img,
					key
				})
			}
		})
	}

	// 通用的获取焦点
	handleInputFocus () {
		this.setState({
			errMsg: ''
		});
	}

	// 用户名和手机号失去焦点
	handleTelInputBlur (event) {
		let val = event.target.value;

		//用户名正则，4（字母，数字，下划线，减号）
		let regName = /^[a-zA-Z0-9_\u4e00-\u9fa5]{4,}$/
		let regTel = /^1[345789]\d{9}$/;

		if (regName.test(val) || regTel.test(val) ) {
			this.setState({
				tel: event.target.value,
				telFlag: true,
				errMsg: ''
			}, () => {
				console.log(this.state.tel)
			});
		} else {
			this.setState({
				errMsg: '请填写登录密码'
			});
		}
	}

	// 密码失去焦点
	handlePasInputBlur (event) {
		let val = event.target.value;

		//只能输入4-16个字母、数字、下划线
		let reg = /^(\w){4,}$/;

		if (reg.test(val)){
			this.setState({
				pas: val,
				pasFlag: true
			}, () => {
				console.log(this.state.pas)
			});
		} else {
			this.setState({
				errMsg: '请填写登录密码'
			});
		}
	}

	// 验证码失去焦点
	handleCodeInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				code: val,
				codeFlag: true
			}, () => {
				console.log(this.state.code)
			});
		} else {
			this.setState({
				errMsg: '请填写验证码'
			});
		}
	}

	// 是否记住密码
	handleMemoryInpuChange (event) {
		let checked = event.target.checked;
		this.setState({
			memory: checked
		}, () => {
			console.log(this.state.memory)
		})
	}

	initLogin (tel, pas, code, key) {
		toUserLogin(
			tel,
			pas,
			code,
			key
		).then(res => {
			if (res.success) {
				const { memory } = this.state;
				if (memory) {
					const remember = {
						tel,
						pas
					}
					setData("remember", JSON.stringify(remember), 7 * 24 * 60 * 60 * 1000);
				} else {
					removeData("remember");
				}
				this.props.history.push('/home');
				const {
					CommunicationCount,//发帖数量
					avatar,//头像
					birthday,//生日
					company,//公司
					education,//最高学历
					email,//邮箱
					integral,//积分
					level,//职称
					professional,//职业
					realname,//昵称
					region,//所在区域
					sex,//性别
					telephone,//电话
					token,
					username,//账号
					} = res.data;

				const userInfo = {
					CommunicationCount,//发帖数量
					avatar,//头像
					birthday,//生日
					company,//公司
					education,//最高学历
					email,//邮箱
					integral,//积分
					level,//职称
					professional,//职业
					realname,//昵称
					region,//所在区域
					sex,//性别
					telephone,//电话
					username,//账号
				}

				sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
				sessionStorage.setItem('token', token);
				setCookie('token', token, 14);
				setCookie('userInfo', JSON.stringify(userInfo), 14);
				var backUrl = new URLSearchParams(this.props.location.search).get('back')
				if (backUrl !== null) {
					window.open(backUrl, '_blank')
				}
			}
		})
	}

	toLogin () {
		const { tel, telFlag, pas, pasFlag, code, codeFlag, key } = this.state;
		if (telFlag && pasFlag && codeFlag) {
			this.initLogin(tel, pas, code, key);
		} else if ((/^(\w){4,16}$/.test(tel) || /^1[345789]\d{9}$/(tel)) && /^(\w){4,16}$/.test(pas) && codeFlag) {
			this.initLogin(tel, pas, code, key);
		} else {
			if (!telFlag) {
				this.setState({
					errMsg: '请填写登录密码'
				});
			} else if (!pasFlag) {
				this.setState({
					errMsg: '请填写登录密码'
				});
			} else if (!codeFlag) {
				this.setState({
					errMsg: '请填写验证码'
				});
			}
		}
	}

	render () {
		const { tel, pas, code, memory, errMsg, codeImg } = this.state;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader {...this.props} token={token} userInfo={userInfo} />
				<div className="loginWrap">
					<div className="loginContainer">
						<div className="loginBg">
							<div className="loginForm">
								<h1>登   录</h1>
								<div className="loginFormContent">
									<p className="loginMsg">{errMsg}</p>
									<div className="loginName clearfix">
										<input autoComplete="off" type="text" name="tel" defaultValue={tel} placeholder="请输入用户名或手机号" onFocus={this.handleInputFocus} onBlur={this.handleTelInputBlur} />
									</div>
									<div className="loginPass clearfix">
										<input autoComplete="off" type="password" name="pas" defaultValue={pas} placeholder="请输入密码" onFocus={this.handleInputFocus} onBlur={this.handlePasInputBlur} />
									</div>
									<div className="loginCodeContainer clearfix">
										<div className="loginCode clearfix">
											<input autoComplete="off" type="text" name="code" defaultValue={code} placeholder="请输入验证码" onFocus={this.handleInputFocus} onBlur={this.handleCodeInputBlur} />
										</div>
										<div className="loginCodeImg" onClick={this.initCatchaImg}>
											<img src={codeImg} alt="codeImg" />
										</div>
									</div>
									<div className="loginOther clearfix">
										<div className="loginOtherLeft clearfix">
											<input type="checkbox" name="memory" id="memory" checked={memory} onChange={this.handleMemoryInpuChange} />
											<label htmlFor="memory">记住密码</label>
										</div>
										<div className="loginOtherRight clearfix">
											<NavLink   target="_blank"   to="/register">注册</NavLink>
											<NavLink   target="_blank"   to="/forgetPass">忘记密码</NavLink>
										</div>
									</div>
									<button className="loginBtn" onClick={this.toLogin}>登录</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="loginMobileWrap">
					<div className="loginMobileContainer">
						<div className="loginMobileBg">
							<div className="loginMobileForm">
								<h1>登   录</h1>
								<div className="loginFormMobileContent">
									<p className="loginMobileMsg">{errMsg}</p>
									<div className="loginMobileName clearfix">
										<input autoComplete="off" type="text" name="tel" defaultValue={tel} placeholder="请输入用户名或手机号" onFocus={this.handleInputFocus} onBlur={this.handleTelInputBlur} />
									</div>
									<div className="loginMobilePass clearfix">
										<input autoComplete="off" type="password" name="pas" defaultValue={pas} placeholder="请输入密码" onFocus={this.handleInputFocus} onBlur={this.handlePasInputBlur} />
									</div>
									<div className="loginMobileCodeContainer clearfix">
										<div className="loginMobileCode clearfix">
											<input autoComplete="off" type="text" name="code" defaultValue={code} placeholder="请输入验证码" onFocus={this.handleInputFocus} onBlur={this.handleCodeInputBlur} />
										</div>
										<div className="loginMobileCodeImg" onClick={this.initCatchaImg}>
											<img src={codeImg} alt="codeImg" />
										</div>
									</div>
									<div className="loginMobileOther clearfix">
										<div className="loginMobileOtherLeft clearfix">
											<input type="checkbox" name="memory" id="memory" checked={memory} onChange={this.handleMemoryInpuChange} />
											<label htmlFor="memory">记住密码</label>
										</div>
										<div className="loginMobileOtherRight clearfix">
											<NavLink   target="_blank"   to="/register">注册</NavLink>
											<NavLink   target="_blank"   to="/forgetPass">忘记密码</NavLink>
										</div>
									</div>
									<button className="loginMobileBtn" onClick={this.toLogin}>登录</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

export default Login;
