import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './index.css';

class CommonToLogin extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			
		}
	}

	toPath = () => {
		this.props.history.push('/login')
	}

	render () {
		const { title } = this.props;
		return (
			<Fragment>
				<div className="commonToLogin">
					<h1>{title}</h1>
					<p>您还没有登录，请先<span onClick={this.toPath}>登录</span></p>
				</div>
			</Fragment>
		)
	}
}

// CommonTitle.propTypes = {
// 	showLeftIcon: PropTypes.bool,
// 	title: PropTypes.string.isRequired,
// 	showRightIcon: PropTypes.bool,
// 	toPath: PropTypes.string
// }
// CommonTitle.defaultProps = {
//     showLeftIcon: true,
//     title: '微课堂分类',
//     showRightIcon: false,
// 	toPath: '/home'
// }

export default CommonToLogin;
