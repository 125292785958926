import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './index.css';

class CommonTitle extends Component {
	render () {
		const { showLeftIcon, title, showRightIcon, toPath } = this.props;
		return (
			<Fragment>
				<div className="commonTitleContainer">
					<NavLink   target="_blank"   to={toPath} className="clearfix">
						{ showLeftIcon && <div className="commonTitleLeft"></div> }
						<h1 className="commonTitleMiddle">{title}</h1>
						{ showRightIcon && <div className="commonTitleRight"></div> }
					</NavLink>
				</div>
			</Fragment>
		)
	}
}

CommonTitle.propTypes = {
	showLeftIcon: PropTypes.bool,
	title: PropTypes.string.isRequired,
	showRightIcon: PropTypes.bool,
	toPath: PropTypes.string
}
CommonTitle.defaultProps = {
    showLeftIcon: true,
    title: '微课堂分类',
    showRightIcon: false,
	toPath: '/home'
}

export default CommonTitle;
