import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';

class CommonSubnav extends Component {
	
	constructor(props) {
	    super(props);
		this.state = {
			
		}
	}
	
	render () {
		const { subNav } = this.props;
		const { pathname } = this.props.location;
		return (
			<Fragment>
				<ul className="subNavLeftWrap clearfix">
					{
						subNav.map((item, index) => {
							return (
								<li key={index}>
									<NavLink   target="_blank"   to={item.path} className={pathname === item.path ? 'navSubSelected' : ''}>{item.name}</NavLink>
								</li>
							)
						})
					}
				</ul>
				<ul className="subNavLeftMobileWrap clearfix">
					{
						subNav.map((item, index) => {
							return (
								<li key={index}>
									<NavLink   target="_blank"   to={item.path} className={pathname === item.path ? 'navSubMobileSelected' : ''}>{item.name}</NavLink>
								</li>
							)
						})
					}
				</ul>
			</Fragment>
		)
	}
}

CommonSubnav.propTypes = {
	subNav: PropTypes.array.isRequired
}


export default CommonSubnav;
