import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';

class CommonMore extends Component {
	render () {
		const {text, toPath} = this.props;
		return (
			<Fragment>
				<NavLink   target="_blank"   to={toPath} className="commonMoreText">{text}</NavLink>
			</Fragment>
		)
	}
}
CommonMore.propTypes = {
	text: PropTypes.string.isRequired,
	toPath: PropTypes.string.isRequired,
}
CommonMore.defaultProps = {
    text: '微课堂分类',
	toPath: '/home'
}

export default CommonMore;
