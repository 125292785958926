import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import myright from '@/assets/myright.jpg';
import './index.css';

class CommonMicroClassDetailsList extends Component {
	render () {
		const {text, toPath} = this.props;
		return (
			<Fragment>
				<div className="commonMicroClassDetailsListContainer clearfix">
					<NavLink   target="_blank"   to="/home" className="clearfix">
						<div className="commonMicroClassDetailsListContainerLeft">
							<img src={myright} alt="myright" />
						</div>
						<p className="commonMicroClassDetailsListContainerRight">如何选择一个合适的样本进行NGS检测？
</p>
					</NavLink>
				</div>
			</Fragment>
		)
	}
}
// CommonMore.propTypes = {
// 	text: PropTypes.string.isRequired,
// 	toPath: PropTypes.string.isRequired,
// }
// CommonMore.defaultProps = {
//     text: '微课堂分类',
// 	toPath: '/home'
// }

export default CommonMicroClassDetailsList;
