import React, { Component, Fragment } from 'react';

import Layout from '@/view/layout';
import All from './children/all';
import Enterprise from './children/enterprise';
import Industry from './children/industry';
import Health from './children/health';
import Tumour from './children/tumour';
import Policy from './children/policy';
import Academic from './children/academic';

import './index.css';

const subRedirect = '/information';
const subToPath = '/information/all';
const subRoute = [
	{
		route: All,
		path: '/information/all'
	},
	{
		route: Enterprise,
		path: '/information/enterprise'
	},
	{
		route: Industry,
		path: '/information/industry'
	},
	{
		route: Health,
		path: '/information/health'
	},
	{
		route: Tumour,
		path: '/information/tumour'
	},
	{
		route: Policy,
		path: '/information/policy'
	},
	{
		route: Academic,
		path: '/information/academic'
	}
]

class Information extends Component {
	render () {
		return (
			<Fragment>
				<Layout subRoute={subRoute} subRedirect={subRedirect} subToPath={subToPath} {...this.props} />
			</Fragment>
		)
	}
}
export default Information;