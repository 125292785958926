import request from '@/utils/request'

// 获取图片验证码
export function getCatchaImg (params) {
  return request({
    url: '/test',
    method: 'get',
    params
  })
}

// 登录
export function toUserLogin (username, password, code, key) {
  return request({
    url: '/user/login',
    method: 'post',
    data: {
      username,
	  password,
	  code,
	  key
    }
  })
}

// 注册
export function toUserRegister (params) {
  return request({
    url: '/user/register',
    method: 'post',
    data: params
  })
}

// 修改
export function toUserEdit (params) {
  return request({
    url: '/user/change/pass',
    method: 'post',
    data: params
  })
}

// 验证码
export function toUserSms (params) {
  return request({
    url: '/sms',
    method: 'post',
    data: params
  })
}

// 修改用户信息
export function userProfileUpdate (
	telephone,
	realname,
	avatar,
	sex,
	birthday,
	region,
	company,
	email,
	professional,
	level,
	education
) {
  return request({
    url: '/user/profile/update',
    method: 'post',
    data: {
		telephone,
		realname,
		avatar,
		sex,
		birthday,
		region,
		company,
		email,
		professional,
		level,
		education
    }
  })
}

// 图片上传
export function imageCreate (params) {
  return request({
    url: '/platform/image/create',
    method: 'post',
    data: params
  })
}

// 帖子列表
export function userCommunicationList (params) {
  return request({
    url: '/user/communication/list',
    method: 'get',
    params
  })
}

// 我的回帖
export function userCommentList (params) {
  return request({
    url: '/user/comment/list',
    method: 'get',
    params
  })
}

// 我的收藏
export function userCollectionList (params) {
  return request({
    url: '/user/collection/list',
    method: 'get',
    params
  })
}

// 我的消息
export function userMessageList (params) {
  return request({
    url: '/user/notice/list',
    method: 'get',
    params
  })
}

// 消息回复
export function userMessageSend (params) {
  return request({
    url: '/user/notice/send',
    method: 'post',
    data: params
  })
}
