import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Pagination } from 'element-react';

import CommonAllList from '@/components/commonAllList';
import CommonSubnav from '@/components/commonSubnav';
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';

import allRightImg from '@/assets/allRightImg.jpg';
import allRightMen from '@/assets/allRightMen.jpg';
import allBottomImg from '@/assets/allBottomImg.jpg';

import { getArticleList } from '@/api/microClass';

const subNav = [
	{
		name: '全部',
		path: '/information/all'
	},
	{
		name: '企业',
		path: '/information/enterprise'
	},
	{
		name: '行业',
		path: '/information/industry'
	},
	{
		name: '健康',
		path: '/information/health'
	},
	{
		name: '肿瘤',
		path: '/information/tumour'
	},
	{
		name: '政策',
		path: '/information/policy'
	},
	{
		name: '学术',
		path: '/information/academic'
	}
]
class All extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			postData: {
				page: 1,
				category_id: 56,
				start: 0,
				length: 20
			},
			articleList: '',
			articleListTotal: '',
			recommendationsList: '',
			pathologyList: '',
			pathology: '',
			readingList: ''
		}
	}

	componentDidMount () {
		this.initPageList();
		this.initRecommendations();
		this.initPathology();
		this.initrReading();
	}

	initPageList () {
		getArticleList(this.state.postData).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data,
					articleListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 56,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	handleCurrentChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 56,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	// 专题推荐
	initRecommendations () {
		getArticleList({
			category_id: 59,
			length: 2
		}).then(res => {
			if (res.success) {
				this.setState({
					recommendationsList: res.data.data
				})
			}
		})
	}

	// 病理微课堂
	initPathology () {
		getArticleList({
			category_id: 4,
			length: 4
		}).then(res => {
			if (res.success) {
				var pathology = res.data.data[0]
				res.data.data.shift()
				this.setState({
					pathologyList: res.data.data,
					pathology: pathology
				})
			}
		})
	}

	// 推荐阅读
	initrReading () {
		getArticleList({
			category_id: 48,
			length: 5
		}).then(res => {
			if (res.success) {
				this.setState({
					readingList: res.data.data
				})
			}
		})
	}

	render () {
		const { articleList, articleListTotal, postData, recommendationsList, pathologyList, readingList, pathology } = this.state;
		return (
			<Fragment>
				<CommonSubnav subNav={subNav} {...this.props} />
				<div className="allWrap">
					<div className="allContentLeft">
						<div className="allContentLeftFirst">
							{
								articleList.length > 0 ? articleList.map((item, index) => {
									return (
										<CommonAllList key={item.id} item={item} />
									)
								}) : ''
							}
							{
								articleList.length > 0 ? <div className="allContentLeftPagination">
								<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
							</div> : ''
							}
						</div>
					</div>
					<div className="allContentRight">
						<div className="allContentRightFirst">
							<CommonTitle title="专题推荐" />
							<div className="allContentRightFirstContent">
								<div className="allContentRightFirstContentImg">
									<img src={allRightImg}  />
								</div>
								{
									recommendationsList.length > 0 ? recommendationsList.map((item, index) => {
										return (
											<NavLink   target="_blank"   to={`/microClassDetails/${item.id}`}>
												<p key={item.id} className="allContentRightFirstContentText">
													{item.title}
												</p>
											</NavLink>
										)
									}) : ''
								}
							</div>
						</div>
						<div className="allContentRightSecond">
							<CommonTitle title="病理微课堂" />
							<div className="allContentRightSecondContent clearfix">
								<NavLink   target="_blank"   to="/home">
									<div className="allContentRightSecondContentLeft">
										<img src={pathology.tmp ? pathology.tmp.avatar : (pathology.cover ? pathology.cover.src : '' )} alt="allRightMen" />
									</div>
									<div className="allContentRightSecondContentRight">
										<h1>{pathology.title}</h1>
										<p>主讲：{pathology.tmp ? pathology.tmp.name : ''}</p>
									</div>
								</NavLink>
							</div>
							<div className="allContentRightSecondList">
								{
									pathologyList.length > 0 ? pathologyList.map((item, index) => {
										return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
									}) : ''
								}
							</div>
						</div>
						<div className="allContentRightThird">
							<CommonTitle title="推荐阅读" />
							<div className="allContentRightThirdContent">
								{
									readingList.length > 0 ? readingList.map((item, index) => {
										return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
									}) : ''
								}
							</div>
						</div>
						<div className="allContentRightFour">
							<CommonTitle title="热门标签" />
							<ul className="clearfix">
								<li>
									<NavLink   target="_blank"   to="/search/PD-1">PD-1</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/精准医学">精准医学</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/穿刺">穿刺</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/慢性淋巴性白血病">慢性淋巴性白血病</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/消化道肿瘤">消化道肿瘤</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/膀胱肉瘤样癌">膀胱肉瘤样癌</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/急性骨髓性白血病">急性骨髓性白血病</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/滤泡性肿瘤">滤泡性肿瘤</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/子宫内膜增生">子宫内膜增生</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/肾脏肿瘤新分类">肾脏肿瘤新分类</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/第三方医学检验">第三方医学检验</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/肿瘤治疗">肿瘤治疗</NavLink>
								</li>
							</ul>
						</div>
						<div className="allContentRightFifth">
							<NavLink   target="_blank"   to="/home">
								<img src={allBottomImg} alt="allBottomImg" />
							</NavLink>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default All;
