import React, { Component, Fragment } from 'react';
import { Message } from 'element-react';

import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';

import misc from '@/assets/misc.gif';

import { getCatchaImg, toUserEdit, toUserSms } from '@/api/login';

import './index.css';

class Login extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tel: '',
			telFlag: false,
			errMsg: '',
			codeImg: '',
			codeImgNum: '',
			codeImgNumFlag: false,
			code: '',
			codeFlag: false,
			codeText: '点击获取验证码',
			pas1: '',
			pas1Flag: false,
			pas2: '',
			pas2Flag: false,
			key: '',
			isEnable: true
		}
		this.initCatchaImg = this.initCatchaImg.bind(this);
		// 通用的获取焦点
		this.handleInputFocus = this.handleInputFocus.bind(this);
		// 手机号失去焦点
		this.handleTelInputBlur = this.handleTelInputBlur.bind(this);
		// 图片验证码失去焦点
		this.handleCodeImgNumInputBlur = this.handleCodeImgNumInputBlur.bind(this);
		// 验证码失去焦点
		this.handleCodeInputBlur = this.handleCodeInputBlur.bind(this);
		// 密码失去焦点1
		this.handlePasInputBlur1 = this.handlePasInputBlur1.bind(this);
		// 密码失去焦点2
		this.handlePasInputBlur2 = this.handlePasInputBlur2.bind(this);
	}

	componentDidMount () {
		this.initCatchaImg();
	}

	// 通用的获取焦点
	handleInputFocus () {
		this.setState({
			errMsg: ''
		});
	}

	// 手机号
	handleTelInputBlur (event) {
		let val = event.target.value;
		let regTel = /^1[345789]\d{9}$/;

		if (regTel.test(val) ) {
			this.setState({
				tel: event.target.value,
				telFlag: true,
				errMsg: ''
			}, () => {
				console.log(this.state.tel)
			});
		} else {
			this.setState({
				errMsg: '请输入正确的手机号'
			});
		}
	}

	// 图片验证码
	handleCodeImgNumInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				codeImgNum: val,
				codeImgNumFlag: true
			}, () => {
				console.log(this.state.codeImgNum)
			});
		} else {
			this.setState({
				errMsg: '请填写图片验证码'
			});
		}
	}

	// 验证码失去焦点
	handleCodeInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				code: val,
				codeFlag: true
			}, () => {
				console.log(this.state.code)
			});
		} else {
			this.setState({
				errMsg: '请填写短信验证码'
			});
		}
	}

	// 密码失去焦点1
	handlePasInputBlur1 (event) {
		let val = event.target.value;

		//只能输入4-16个字母、数字、下划线
		let reg = /^(\w){4,}$/;

		if (reg.test(val)){
			this.setState({
				pas1: val,
				pas1Flag: true
			}, () => {
				console.log(this.state.pas)
			});
		} else {
			this.setState({
				errMsg: '请填写登录密码'
			});
		}
	}
	// 密码失去焦点2
	handlePasInputBlur2 (event) {
		let val = event.target.value;

		//只能输入4-16个字母、数字、下划线
		let reg = /^(\w){4,}$/;

		if (reg.test(val)){
			if (this.state.pas1 === val) {
				this.setState({
					pas2: val,
					pas2Flag: true
				}, () => {
					console.log(this.state.pas)
				});
			} else {
				this.setState({
					errMsg: '两次密码不一致'
				});
			}
		} else {
			this.setState({
				errMsg: '请填写登录密码'
			});
		}
	}

	// 初始化图片验证码
	initCatchaImg () {
		getCatchaImg({}).then(res => {
			if (res.success) {
				const { img, key, sensitive } = res.data;
				this.setState({
					codeImg: img,
					key
				})
			}
		})
	}

	// 发送验证码
	sendCode () {
		const { tel, key, codeImgNum, isEnable } = this.state;
		let time = 60;
		if (!this.state.tel) {
			Message({
			  message: '请填写正确的手机号',
			  type: 'error',
			  showClose: true,
			  duration: 5 * 1000
			})
		} else if (!this.state.codeImgNum) {
			Message({
			  message: '请输入图片验证码',
			  type: 'error',
			  showClose: true,
			  duration: 5 * 1000
			})
		} else {
			if (isEnable) {
				toUserSms({
					tel,
					key,
					code: codeImgNum
				}).then(res => {
					this.timeId = setInterval(() => {
						if (time <= 1) {
							clearInterval(this.timeId)
							this.setState({
								codeText: '点击获取验证码',
								isEnable: true
							})
							return
						}
						time--
						this.setState({
							codeText: `${time}s后重发`,
							isEnable: false
						})
					}, 1000)
				})
			}
		}
	}

	// 提交
	forgetBtn () {
		const { tel, telFlag, code, codeFlag, pas1, pas1Flag, pas2, pas2Flag} = this.state;
		if (telFlag && codeFlag && pas1Flag && pas2Flag) {
			toUserEdit({
				telephone: tel,
				password: pas1,
				code
			}).then(res => {
				Message({
				  message: '修改成功',
				  type: 'success',
				  showClose: true,
				  duration: 5 * 1000
				})
			})
		}
	}

	render () {
		const { tel, errMsg, codeImg, codeImgNum, code, codeText, pas1, pas2, isEnable} = this.state;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				<div className="forgetPassWrap">
					<div className="forgetPassContainer">
						<h1>找回密码</h1>
						<div className="forgetPassContent">
							<p className="forgetMsg">{errMsg}</p>
							<form>
								<div className="forgetTel forgetPassMb22 clearfix">
									<p>账户：</p>
									<input autoComplete="off" type="text" name="tel" placeholder="请输入您的注册手机号码" defaultValue={tel} onFocus={this.handleInputFocus} onBlur={this.handleTelInputBlur} />
								</div>
								<div className="codeImgWrap forgetPassMb22 clearfix">
									<p>图形验证：</p>
									<input autoComplete="off" type="text" name="codeImgNum" placeholder="请输入右侧的图形验证码" defaultValue={codeImgNum} onFocus={this.handleInputFocus} onBlur={this.handleCodeImgNumInputBlur} />
									<div className="codeImg" onClick={this.initCatchaImg}>
										<img src={codeImg} alt="misc" />
									</div>
								</div>
								<div className="forgetPassMb22 clearfix">
									<p>短信验证：</p>
									<input autoComplete="off" type="text" name="code" placeholder="请输入短信验证码" defaultValue={code} onFocus={this.handleInputFocus} onBlur={this.handleCodeInputBlur} />
									<div className="sendCodeNumber" onClick={this.initCatchaImg}>
										<button style={isEnable ? {background: '#99cc00'} : {background: '#a0a0a0'}} type="button" disabled={isEnable ? '' : 'disabled'}>{codeText}</button>
									</div>
								</div>
								<div className="forgetPassMb22 clearfix">
									<p>重置密码：</p>
									<input autoComplete="off" type="password" name="pas1" placeholder="请输入新密码" defaultValue={pas1} onFocus={this.handleInputFocus} onBlur={this.handlePasInputBlur1} />
								</div>
								<div className="forgetPassMb22 clearfix">
									<p>确认密码：</p>
									<input autoComplete="off" type="password" name="pas2" placeholder="请输入新密码" defaultValue={pas2} onFocus={this.handleInputFocus} onBlur={this.handlePasInputBlur2} />
								</div>
								<button className="forgetPassBtn" onClick={this.forgetBtn.bind(this)}>提交</button>
							</form>
						</div>
					</div>
				</div>
				<div className="forgetPassMobileWrap">
					<div className="forgetPassMobileContainer">
						<h1>找回密码</h1>
						<div className="forgetPassMobileContent">
							<form>
								<div className="forgetTelMobile forgetPassMb22Mobile clearfix">
									<p>账户：</p>
									<input autoComplete="off" type="text" name="tel" placeholder="请输入您的注册手机号码" defaultValue={tel} onFocus={this.handleInputFocus} onBlur={this.handleTelInputBlur} />
									<div className="sendCodeNumberMobile" onClick={this.initCatchaImg}>
										<button style={isEnable ? {background: '#99cc00'} : {background: '#a0a0a0'}} type="button" disabled={isEnable ? '' : 'disabled'}>{codeText}</button>
									</div>
								</div>
								<div className="codeImgWrapMobile forgetPassMb22Mobile clearfix">
									<p>图形验证：</p>
									<input type="text" placeholder="请输入右侧的图形验证码" />
									<div className="codeImgMobile">
										<img src={misc} alt="misc" />
									</div>
								</div>
								<div className="forgetPassMb22Mobile clearfix">
									<p>短信验证：</p>
									<input type="text" placeholder="请输入短信验证码" />
								</div>
								<div className="forgetPassMb22Mobile clearfix">
									<p>重置密码：</p>
									<input type="text" placeholder="请输入新密码" />
								</div>
								<div className="forgetPassMb22Mobile clearfix">
									<p>确认密码：</p>
									<input type="text" placeholder="请输入新密码" />
								</div>
								<button className="forgetPassMobileBtn" onClick={this.forgetBtn.bind(this)}>提交</button>
							</form>
						</div>
					</div>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

export default Login;
