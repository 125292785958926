import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel, Pagination } from 'element-react';

import CommonGuessList from '@/components/commonGuessList';
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';

import banner_jct from '@/assets/banner_jct.jpg';
import index17 from '@/assets/index_17.jpg';

import { getArticleList } from '@/api/microClass';
// 获取banner接口
import { getBanner } from '@/api/common.js';

import './index.css';

class CorrectionTable extends Component {

	constructor(props) {
		super(props);
	    this.state = {
			postData: {
				page: 1,
				category_id: 2,
				start: 0,
				length: 20
			},
			articleList: '',
			articleListTotal: '',
			guessLook: '',
			pathology: '',
			bannerList: []
		}
	}

	componentDidMount () {
		// 获取轮播
		this.initBanner();
		this.initPageList();
		this.initGuessLook();
		this.initPathology();
	}

	// 获取轮播
	initBanner () {
		getBanner({
			type: 6
		}).then(res => {
			if (res.success) {
				this.setState({
					bannerList: res.data
				})
			}
		})
	}

	initPageList () {
		getArticleList(this.state.postData).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data,
					articleListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 6,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	handleCurrentChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 6,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	initGuessLook () {
		getArticleList({
			category_id: 6,
			length: 7
		}).then(res => {
			if (res.success) {
				this.setState({
					guessLook: res.data.data
				})
			}
		})
	}

	initPathology () {
		getArticleList({
			category_id: 4,
			length: 6
		}).then(res => {
			if (res.success) {
				this.setState({
					pathology: res.data.data
				})
			}
		})
	}

	render () {
		const { articleList, articleListTotal, postData, guessLook, pathology, bannerList } = this.state;
		return (
			<Fragment>
				<div className="correctionTableBanner">
					<Carousel height="3.14rem" indicatorPosition="none">
					  {
						bannerList.map((item, index) => {
						  return (
							<Carousel.Item key={index}>
							  <img src={item.image.src} />
							</Carousel.Item>
						  )
						})
					  }
					</Carousel>
				</div>
				<div className="correctionTablePreface">
					<h1>前言</h1>
					<p>和其它学科一样，诊断病理学正经历着快速发展期，知识更新不断加速，除了分子诊断技术不断改变着肿瘤分类及诊断标准，指导临床治疗外，一些肿瘤的形态学诊断标准也在不断的改变着。而我们手中的参考资料，包括我们熟记于心的、教科书上的、杂志上的、会议资料上的、病理专著上的、网上的、微信上的、微博上的、QQ群里的...等等，均可能或多、或少的存在着过时的、错误的或者是不准确的信息或“诊断标准”，它们有可能会诱导你对疾病做出不准确甚至是错误的诊断，进而误导临床治疗，给患者带来痛苦，也可能将自己置于医疗纠纷的漩涡中不能自拔！</p>
					<p>因此，我们病理医生除了学习新知识外，还要特别关注我们所掌握的知识、身边各种渠道可获取的知识中哪些是过时的甚至是错误的。为此我们在大伽病理.精准国际病理诊断网及微信平台开设了“纠错台”。 </p>
					<p>欢迎大家将你所了解的那些有关病理诊断的错误信息、理论及“诊断标准”反馈给我们，我们再将您提供的信息和大家分享。您不光可以为您提供的信息使患者避免误诊、漏诊，使病理同行免受医疗纠纷之折磨感到欣慰外，您还将得到我们为您准备的礼物或稿费！</p>
				</div>
				<div className="correctionTableLeft">
					<div className="correctionTableLeftFirst">
						{
							articleList.length > 0 ? articleList.map((item, index) => {
								return (
									<CommonGuessList key={item.id} articleList={item} />
								)
							}) : ''
						}
						{
							articleList.length > 0 ? <div className="correctionTableLeftFirstPagination">
							<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
						</div> : ''
						}
					</div>
				</div>
				<div className="correctionTableRight">
					{/*<div className="correctionTableRightFirst">*/}
					{/*	<NavLink   target="_blank"   to="/">我要纠错</NavLink>*/}
					{/*</div>*/}
					<div className="correctionTableRightSecond">
						<CommonTitle title="猜猜看" showRightIcon={true} toPath="/microClass/guess" />
						<div className="correctionTableRightSecondContent">
							{
								guessLook.length > 0 ? guessLook.map((item, index) => {
									return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
								}) : ''
							}
						</div>
					</div>
					<div className="correctionTableRightThird">
						<CommonTitle title="病理微课堂" showRightIcon={true} toPath="/microClass/micropathology" />
						<div className="correctionTableRightThirdContent">
							{
								pathology.length > 0 ? pathology.map((item, index) => {
									return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
								}) : ''
							}
						</div>
					</div>
					<div className="correctionTableRightThirdImg">
						<NavLink   target="_blank"   to="/software">
							<img src="http://dia.dakapath.com/Public/static/media/index_17.f182e9eb.jpg" alt="searchRightImgindex17" />
						</NavLink>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CorrectionTable;
