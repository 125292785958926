import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Message, Pagination } from 'element-react';
import moment from 'moment';

import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';

import userAvatar from '@/assets/user-avatar.jpg';
import close from '@/assets/close.png';

import { userProfileUpdate, imageCreate, userCommunicationList, userCommentList, userCollectionList, userMessageList, userMessageSend } from '@/api/login';

import './index.css';

class UserInfo extends Component {

	constructor(props) {
		super(props);
	    this.state = {
			avatar: '',
			userName: '',
			realname: '',
			tel: '',
			sexChecked: 1,
			birthday: '',
			region: '',
			company: '',
			email: '',
			edu: '',
			rightActiveOne: true,
			rightActiveTwo: false,
			rightActiveThree: false,
			rightActiveFour: false,
			rightActiveFifth: false,
			userCommunicationListpostData: {
				page: 1,
				start: 0,
				length: 20
			},
			userCommunicationList: [],
			userCommunicationListTotal: '',
			userCommentListData: {
				page: 1,
				start: 0,
				length: 20
			},
			userCommentList: [],
			userCommentListTotal: '',
			userCollectionListData: {
				page: 1,
				start: 0,
				length: 20
			},
			userCollectionList: [],
			userCollectionTotal: '',
			userMessageListData: {
				page: 1,
				start: 0,
				length: 20
			},
			userMessageList: [],
			userMessageTotal: '',
			userMessageListComments: [],
			isShowShadowOne: false,
			isShowShadowTwo: false,
			isShowShadowThree: false,
			replyContent: '',
			replyData: '',
			feedBackContent: ''
		}
	}

	componentDidMount () {
		// 基本信息
		const userInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : '';

		this.setState({
			avatar: userInfo.avatar,
			userName: userInfo.username,
			realname: userInfo.realname,
			tel: userInfo.telephone,
			sexChecked: userInfo.sex,
			birthday: userInfo.birthday,
			region: userInfo.region,
			company: userInfo.company,
			email: userInfo.email,
			edu: userInfo.education
		}, () => {
			console.log(this.state.level)
		})
		// 我的帖子
		this.initCommunicationList();
		// 我的回复
		this.initCommentList();
		// 我的收藏
		this.initCollectionList();
		// 我的消息
		this.initMessageList();
	}

	// 我的帖子
	initCommunicationList () {
		userCommunicationList(this.state.userCommunicationListpostData).then(res => {
			if (res.success) {
				this.setState({
					userCommunicationList: res.data.data,
					userCommunicationListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChangeCommunicationList (val) {
		this.setState({
			userCommunicationListpostData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initCommunicationList();
		})
	}

	handleCurrentChangeCommunicationList (val) {
		this.setState({
			userCommunicationListpostData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initCommunicationList();
		})
	}

	// 我的回复
	initCommentList () {
		userCommentList(this.state.userCommentListData).then(res => {
			if (res.success) {
				this.setState({
					userCommentList: res.data.data,
					userCommentListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChangeCommentList (val) {
		this.setState({
			userCommentListData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initCommentList();
		})
	}

	handleCurrentChangeCommentList (val) {
		this.setState({
			userCommentListData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initCommentList();
		})
	}

	// 我的收藏
	initCollectionList () {
		userCollectionList(this.state.userCollectionListData).then(res => {
			if (res.success) {
				this.setState({
					userCollectionList: res.data.data,
					userCollectionTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChangeCollectionList (val) {
		this.setState({
			userCollectionListData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initCollectionList();
		})
	}

	handleCurrentChangeCollectionList (val) {
		this.setState({
			userCollectionListData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initCollectionList();
		})
	}

	// 我的消息
	initMessageList () {
		userMessageList(this.state.userMessageListData).then(res => {
			this.setState({
				userMessageList: res.data.data,
				userMessageTotal: res.data.total
			})
		})
	}

	// 分页
	handleSizeChangeMessageList (val) {
		this.setState({
			userMessageListData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initMessageList();
		})
	}

	handleCurrentChangeMessageList (val) {
		this.setState({
			userMessageListData: {
				page: val,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initMessageList();
		})
	}

	toPath (pathName) {
		this.props.history.push(pathName)
	}

	selectActive (selectActive) {
		console.log(selectActive)
		if (selectActive === 'rightActiveOne') {
			this.setState({
				rightActiveOne: true,
				rightActiveTwo: false,
				rightActiveThree: false,
				rightActiveFour: false,
				rightActiveFifth : false,
			})
		} else if (selectActive === 'rightActiveTwo') {
			this.setState({
				rightActiveOne: false,
				rightActiveTwo: true,
				rightActiveThree: false,
				rightActiveFour: false,
				rightActiveFifth : false,
			})
		} else if (selectActive === 'rightActiveThree') {
			this.setState({
				rightActiveOne: false,
				rightActiveTwo: false,
				rightActiveThree: true,
				rightActiveFour: false,
				rightActiveFifth : false,
			})
		} else if (selectActive === 'rightActiveFour') {
			this.setState({
				rightActiveOne: false,
				rightActiveTwo: false,
				rightActiveThree: false,
				rightActiveFour: true,
				rightActiveFifth : false,
			})
		} else if (selectActive === 'rightActiveFifth') {
			this.setState({
				rightActiveOne: false,
				rightActiveTwo: false,
				rightActiveThree: false,
				rightActiveFour: false,
				rightActiveFifth : true,
			})
		}
	}

	changeAvatar (event) {
		const file = event.target
		const fileObj = file.files[0];
		const windowURL = window.URL || window.webkitURL;
		if(file && fileObj) {

			// const dataURl = windowURL.createObjectURL(fileObj);
			// this.setState({
			// 	avatar: dataURl
			// })
			let params = new FormData();
			params.append('image', fileObj)
			imageCreate(params).then(res => {
				this.setState({
					avatar: res.data.src
				})
			})
		}
	}

	handelTel (event) {
		let val = event.target.value;
		this.setState({
			tel: val
		});
	}

	handelBirthday (event) {
		let val = event.target.value;
		this.setState({
			birthday: val
		});
	}

	handelRegion (event) {
		let val = event.target.value;
		this.setState({
			region: val
		});
	}

	handelCompany (event) {
		let val = event.target.value;
		this.setState({
			company: val
		});
	}

	handelEmail (event) {
		let val = event.target.value;
		this.setState({
			email: val
		});
	}

	handelEdu (event) {
		let val = event.target.value;
		this.setState({
			edu: val
		});
	}

	handleMajorChange (event) {
		let val = event.target.value;
		this.setState({
			major: val
		});
	}

	handleLevelChange (event) {
		let val = event.target.value;
		this.setState({
			level: val
		});
	}

	handelRadio(event){
		let val = event.target.value;
		this.setState({
		  sexChecked: Number(val)
		}, () => {
			console.log(this.state.sexChecked)
		})
	}

	saveBtn (event) {
		event.preventDefault();
		const {
			avatar,
			userName,
			realname,
			tel,
			sexChecked,
			birthday,
			region,
			company,
			email,
			edu,
			major,
			level
		} = this.state;
		console.log(birthday)
		userProfileUpdate(
			tel,
			realname,
			avatar,
			sexChecked,
			birthday,
			region,
			company,
			email,
			major,
			level,
			edu
		).then(res => {
			if (res.success) {
				const oldUserInfo = JSON.parse(sessionStorage.getItem('userInfo'));
				const newUserInfo = {
					CommunicationCount: oldUserInfo.CommunicationCount,
					avatar,
					birthday,
					company,
					education: edu,
					email,
					integral: oldUserInfo.integral,
					level,
					professional: major,
					realname,
					region,
					sex: sexChecked,
					telephone: tel,
					username: userName
				}
				sessionStorage.setItem('userInfo', JSON.stringify(newUserInfo))
				Message({
				  message: '保存成功',
				  type: 'success',
				  showClose: true,
				  duration: 5 * 1000
				})
			}
		})
	}

	// 查看
	showView (index) {
		this.setState({
			isShowShadowOne: true,
			userMessageListComments: this.state.userMessageList[index].comments
		})
	}

	// 查看关闭
	hideView () {
		this.setState({
			isShowShadowOne: false
		})
	}

	// 输入框
	handleChangeReplyInput (event) {
		let val = event.target.value;
		this.setState({
			replyContent: val
		})
	}

	// 回复
	showReply (item) {
		console.log(item)
		this.setState({
			isShowShadowTwo: true,
			replyData: item
		})
	}

	// 回复关闭
	hideReply () {
		this.setState({
			isShowShadowTwo: false
		})
	}
	// 回复按钮
	reply () {
		const { replyContent, replyData } = this.state;
		console.log(replyContent)
		if (replyContent === '') {
			Message({
				message: '请填写回复内容',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		}

		userMessageSend({
			content: replyContent,
			reply_id: replyData.reply_id,
			// opinion_id: replyData.opinion_id
		}).then(res => {
			if (res.success) {
				this.setState({
					isShowShadowTwo: false
				});
				Message({
					message: '回复成功',
					type: 'success',
					showClose: true,
					duration: 5 * 1000
				});
				this.initMessageList();
			}
		})
	}

	// 意见反馈
	showFeedBack () {
		this.setState({
			isShowShadowThree: true
		})
	}
	// 意见反馈关闭
	hideFeedBack () {
		this.setState({
			isShowShadowThree: false
		})
	}

	handleChangeFeedBackInput (event) {
		let val = event.target.value;
		this.setState({
			feedBackContent: val
		})
	}

	feedBack () {
		const { feedBackContent } = this.state;
		if (feedBackContent === '') {
			Message({
				message: '请填写意见反馈内容',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		}

		userMessageSend({
			content: feedBackContent,
		}).then(res => {
			if (res.success) {
				this.setState({
					isShowShadowThree: false
				});
				Message({
					message: '反馈成功',
					type: 'success',
					showClose: true,
					duration: 5 * 1000
				});
			}
		})
	}

	render () {
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		const {
			avatar,
			userName,
			nickName,
			tel,
			realname,
			sexChecked,
			birthday,
			region,
			company,
			email,
			edu,
			rightActiveOne,
			rightActiveTwo,
			rightActiveThree,
			rightActiveFour,
			rightActiveFifth,
			userCommunicationListpostData,
			userCommunicationList,
			userCommunicationListTotal,
			userCommentListData,
			userCommentList,
			userCommentListTotal,
			userCollectionListData,
			userCollectionList,
			userCollectionTotal,
			userMessageListData,
			userMessageList,
			userMessageTotal,
			userMessageListComments,
			isShowShadowOne,
			isShowShadowTwo,
			isShowShadowThree,
			replyContent,
			feedBackContent
		} = this.state;
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				<div className="userInfoWrap clearfix">
					<div className="userInfoLeft clearfix">
						<div className="userInfoLeftFirst">
							<img src={avatar ? avatar : userAvatar} alt="LoginIcon" />
							<h1>{userInfo ? userInfo.realname : ''}</h1>
							<h1>我的积分 {userInfo ? userInfo.integral : ''}</h1>
							<h1>发帖数量 {userInfo ? userInfo.CommunicationCount : ''}</h1>
						</div>
						<ul className="userInfoLeftSecond">
							<li>
								<p className={rightActiveOne ? "active" : ""} onClick={this.selectActive.bind(this, 'rightActiveOne')}>我的首页</p>
							</li>
							<li>
								<h2>
									<i></i>
									<em></em>
									<span>我的交流</span>
								</h2>
								<p className={rightActiveTwo ? "active" : ""} onClick={this.selectActive.bind(this, 'rightActiveTwo')}>我的发帖</p>
								<p className={rightActiveThree ? "active" : ""} onClick={this.selectActive.bind(this, 'rightActiveThree')}>我的回帖</p>
							</li>
							<li>
								<h2>
									<i></i>
									<em></em>
									<span>我的收藏</span>
								</h2>
								<p className={rightActiveFour ? "active" : ""} onClick={this.selectActive.bind(this, 'rightActiveFour')}>我的收藏</p>
							</li>
							<li>
								<h2>
									<i></i>
									<em></em>
									<span>我的消息</span>
								</h2>
								<p className={rightActiveFifth ? "active" : ""} onClick={this.selectActive.bind(this, 'rightActiveFifth')}>我的消息</p>
							</li>
						</ul>
					</div>
					<div className="userInfoRight">
						{
							rightActiveOne && <div className="userInfoRightFirst">
								<div className="userInfoRightNav">基本信息</div>
								<div className="userInfoRightFirstForm">
									<form encType="multipart/form-data">
										<label className="clearfix userInfoRightFirstFormUploadWrap">
											<p>用户头像：</p>
											<div className="userInfoRightFirstFormUpload">
												<img src={avatar ? avatar : userAvatar} />
												<input type="file" name="avatar" onClick={(event)=> { event.target.value = null }} onChange={this.changeAvatar.bind(this)}/>
											</div>
										</label>
										<label className="clearfix">
											<p>用户名：</p>
											<input type="text" name="userName" defaultValue={userName} readOnly />
										</label>
										<label className="clearfix">
											<p>昵称：</p>
											<input type="text" name="realname" defaultValue={realname} readOnly />
										</label>
										<label className="clearfix">
											<p>手机：</p>
											<input type="text" name="tel" defaultValue={tel} onChange={this.handelTel.bind(this)} />
										</label>
										<label className="clearfix">
											<p>性别：</p>
											<div className="userInfoRightFirstFormSex">
												<input type="radio" name="sex" defaultValue="1" onChange={this.handelRadio.bind(this)} checked={sexChecked === 1} />男
												<input type="radio" name="sex" defaultValue="0" onChange={this.handelRadio.bind(this)} checked={sexChecked === 0} />女
											</div>
										</label>
										<label className="clearfix">
											<p>出生年月：</p>
											<input type="text" name="birthday" defaultValue={birthday} onChange={this.handelBirthday.bind(this)}/>
										</label>
										<label className="clearfix">
											<p>所属地区：</p>
											<input type="text" name="region" defaultValue={region} onChange={this.handelRegion.bind(this)} />
										</label>
										<label className="clearfix">
											<p>单位：</p>
											<input type="text" name="company" defaultValue={company} onChange={this.handelCompany.bind(this)} />
										</label>
										<label className="clearfix">
											<p>E-mail：</p>
											<input type="text" name="email" defaultValue={email} onChange={this.handelEmail.bind(this)} />
										</label>
										<label className="clearfix">
											<p>学历：</p>
											<input type="text" name="edu" defaultValue={edu} onChange={this.handelEdu.bind(this)} />
										</label>
										<label className="clearfix">
											<p>职业：</p>
											<select name="major" defaultValue={userInfo ? userInfo.professional : '0'} onChange={this.handleMajorChange.bind(this)} className="userInfoRightFirstFormMajor">
												<option value="0">---</option>
												<option value="1">病理医师</option>
												<option value="2">病理技术员</option>
												<option value="3">临床医师</option>
												<option value="4">护士</option>
												<option value="5">生物技术</option>
												<option value="6">医学检验</option>
												<option value="7">医学相关</option>
												<option value="8">其它</option>
											</select>
										</label>
										<label className="clearfix">
											<p>职称：</p>
											<select name="level" defaultValue={userInfo ? userInfo.level : '0'} onChange={this.handleLevelChange.bind(this)} className="userInfoRightFirstFormLevel">
												<option value="0">---</option>
												<option value="1">无</option>
												<option value="2">初级</option>
												<option value="3">中级</option>
												<option value="4">副高</option>
												<option value="5">正高</option>
											</select>
										</label>
										<label className="clearfix">
											<p></p>
											<button className="userInfoRightFirstFormSave" onClick={this.saveBtn.bind(this)}>保存</button>
										</label>
									</form>
								</div>
							</div>
						}
						{
							rightActiveTwo && <div className="userInfoRightSecond">
								<div className="userInfoRightSecondAll">全部主题</div>
								<ul className="userInfoRightSecondNav clearfix">
									<li className="userInfoRightSecondNavNew">最新</li>
									<li>作者</li>
									<li>回复/查看</li>
									<li>最后发表</li>
								</ul>
								<ul className="userInfoRightSecondList">
									{
										userCommunicationList.length > 0 ? userCommunicationList.map((item, index) => {
											return (
												<li className="clearfix" key={item.id}>
													<h2 onClick={this.toPath.bind(this, '/communicateDetails/' + item.id)}>{item.title}</h2>
													{
														item.user ? <p>
														{item.user.realname}
														<span>{moment(item.user.created_at).format('YYYY-MM-DD')}</span>
														</p> : <p>
															该用户已注销
															<span></span>
														</p>
													}
													{
														item.user ? <p>
															{ item.user.CommunicationCount }
															<span>{item.view_num}</span>
														</p> : <p>
															0
															<span>0</span>
														</p>
													}
													{
														item.LastComment ? <p>
														{item.LastComment.user.realname}
														<span>{moment(item.LastComment.created_at).format('YYYY-MM-DD HH:mm')}</span>
														</p> : <p>暂无回复</p>
													}
												</li>
											)
										}) : <div className="userInfoRightNotfound">没有找到相关帖子</div>
									}
								</ul>
								<div className="sendBtn" onClick={this.toPath.bind(this, '/communicate/communicateReadShare')}>我要发帖</div>
								{
									userCommunicationList.length > 0 ? <div className="pagination">
										<Pagination onSizeChange={this.handleSizeChangeCommunicationList.bind(this)} onCurrentChange={this.handleCurrentChangeCommunicationList.bind(this)} currentPage={userCommunicationListpostData.page} pageSizes="[5,10,15,20]" pageSize={userCommunicationListpostData.length} layout="prev, pager, next"  total={userCommunicationListTotal}/>
								</div> : ''
								}

							</div>
						}
						{
							rightActiveThree && <div className="userInfoRightSecond">
								<div className="userInfoRightSecondAll">全部主题</div>
								<ul className="userInfoRightSecondNav clearfix">
									<li className="userInfoRightSecondNavNew">最新</li>
									<li>作者</li>
									<li>回复/查看</li>
									<li>最后发表</li>
								</ul>
								<ul className="userInfoRightSecondList">
									{
										userCommentList.length > 0 ? userCommentList.map((item, index) => {
											return (
												<li className="clearfix" key={item.id}>
													<h2 onClick={this.toPath.bind(this, '/communicateDetails/' + item.id)}>{item.communication.title}</h2>
													{
														item.communication.user ? <p>
															{item.communication.user.realname}
															<span>
															{moment(item.communication.user.created_at).format('YYYY-MM-DD')}
															</span>
															</p> : <p>
															该用户已注销
															<span></span>
														</p>
													}
													{
														item.communication.user ? <p>
															{ item.communication.user.CommunicationCount }
															<span>{item.communication.view_num}</span>
														</p> : <p>
															0
															<span>0</span>
														</p>
													}
													{
														item.communication.LastComment ? <p>
														{item.communication.LastComment.user.realname}
														<span>
														{moment(item.communication.LastComment.created_at).format('YYYY-MM-DD HH:mm')}
														</span>
														</p> : <p>暂无回复</p>
													}
												</li>
											)
										}) : <div className="userInfoRightNotfound">没有找到相关帖子</div>
									}
								</ul>
								<div className="sendBtn" onClick={this.toPath.bind(this, '/communicate/communicateReadShare')}>我要发帖</div>
								{
									userCommentList.length > 0 ? <div className="pagination">
									<Pagination onSizeChange={this.handleSizeChangeCommentList.bind(this)} onCurrentChange={this.handleCurrentChangeCommentList.bind(this)} currentPage={userCommentListData.page} pageSizes="[5,10,15,20]" pageSize={userCommentListData.length} layout="prev, pager, next"  total={userCommentListTotal}/>
								</div> : ''
								}

							</div>
						}
						{
							rightActiveFour && <div className="userInfoRightSecond">
								<ul className="userInfoRightSecondNav clearfix">
									<li className="userInfoRightSecondNavNew">最新</li>
									<li>来源</li>
									<li>回复/查看</li>
								</ul>
								<ul className="userInfoRightSecondList">
									{
										userCollectionList.length > 0 ? userCollectionList.map((item, index) => {
											return (
												<li className="clearfix" key={item.id}>
													<h2 onClick={this.toPath.bind(this, '/microClassDetails/' + item.id)}>{item.article.title}</h2>
													{
														 item.article.form ? <p>
															{item.article.form}
															<span></span>
															</p> : ''
													}
													{
														item.article.view_num ? <p>
															<span>{item.article.view_num}</span>
														</p> : <p>
															<span>0</span>
														</p>
													}
												</li>
											)
										}) : <div className="userInfoRightNotfound">没有找到相关帖子</div>
									}
								</ul>
								{
									userCollectionList.length > 0 ? <div className="pagination">
									<Pagination onSizeChange={this.handleSizeChangeCollectionList.bind(this)} onCurrentChange={this.handleCurrentChangeCollectionList.bind(this)} currentPage={userCollectionListData.page} pageSizes="[5,10,15,20]" pageSize={userCollectionListData.length} layout="prev, pager, next"  total={userCollectionTotal}/>
								</div> : ''
								}

							</div>
						}
						{
							rightActiveFifth && <div className="userInfoRightThird">
								<ul className="userInfoRightThirdNav clearfix">
									<li className="userInfoRightThirdNavNew">内容</li>
									<li>操作</li>
								</ul>
								<ul className="userInfoRightThirdList">
									{
										userMessageList.length > 0 ? userMessageList.map((item, index) => {
											return (
												<li className="clearfix" key={item.id}>
													<h2>{item.content}</h2>
													<p>
														<span onClick={this.showView.bind(this, index)}>查看</span>
														<span onClick={this.showReply.bind(this, item)}>回复</span>
													</p>
												</li>
											)
										}) : <div className="userInfoRightNotfound">没有找到相关消息</div>
									}
								</ul>
								{
									userMessageList.length > 0 ? <div className="pagination">
									<Pagination onSizeChange={this.handleSizeChangeMessageList.bind(this)} onCurrentChange={this.handleCurrentChangeMessageList.bind(this)} currentPage={userMessageListData.page} pageSizes="[5,10,15,20]" pageSize={userMessageListData.length} layout="prev, pager, next"  total={userMessageTotal}/>
								</div> : ''
								}
								<div className="userInfoRightFixBtn" onClick={this.showFeedBack.bind(this)}>意见反馈 </div>
							</div>
						}
					</div>
				</div>
				{
					isShowShadowOne && <div className="userInfoRightThirdViewShadow">
					<div className="userInfoRightThirdViewShadowContent">
						<h1>消息<img src={close} onClick={this.hideView.bind(this)}/></h1>
						<ul className="userInfoRightThirdViewShadowContentList">
							{
								userMessageListComments.length > 0 ? userMessageListComments.map((item, index) => {
									return (
										<li className="clearfix" key={index}>
											<div className="userInfoRightThirdViewShadowContentListLeft">
												<img src={item.user.avatar} />
											</div>
											<div className="userInfoRightThirdViewShadowContentListRight">
												<p className="userInfoRightThirdViewShadowContentListRightRealname">{item.user.realname}</p>
												<p className="userInfoRightThirdViewShadowContentListRightContent">{item.content}</p>
												<p className="userInfoRightThirdViewShadowContentListRightCommunicationCount">{item.created_at}</p>
											</div>
										</li>
									)
								}) : ''
							}
						</ul>
					</div>
				</div>
				}
				{
					isShowShadowTwo && <div className="userInfoRightThirdReplyShadow">
					<div className="userInfoRightThirdReplyShadowContent">
						<h1>回复<img src={close} onClick={this.hideReply.bind(this)}/></h1>
						<div className="userInfoRightThirdReplyShadowContentArea">
							<textarea rows="3" cols="20" placeholder="请输入回复内容" onChange={this.handleChangeReplyInput.bind(this)} defultvalue={replyContent}></textarea>
						</div>
						<button onClick={this.reply.bind(this)}>回复</button>
					</div>
				</div>
				}
				{
					isShowShadowThree && <div className="userInfoRightThirdReplyShadow">
					<div className="userInfoRightThirdReplyShadowContent">
						<h1>意见反馈<img src={close} onClick={this.hideFeedBack.bind(this)}/></h1>
						<div className="userInfoRightThirdReplyShadowContentArea">
							<textarea rows="3" cols="20" placeholder="请输入意见反馈内容" onChange={this.handleChangeFeedBackInput.bind(this)} defultvalue={feedBackContent}></textarea>
						</div>
						<button onClick={this.feedBack.bind(this)}>反馈</button>
					</div>
				</div>
				}
				<CommonFooter />
			</Fragment>
		)
	}
}

export default UserInfo;
