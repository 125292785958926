import React, { Component, Fragment } from 'react';

import Layout from '@/view/layout';
import Activity from './children/activity';
import SpecialTopic from './children/specialTopic';
import Meeting from './children/meeting';

import './index.css';

const subRedirect = '/find';
const subToPath = '/find/meeting';
const subRoute = [
	{
		route: Activity,
		path: '/find/activity'
	},
	{
		route: SpecialTopic,
		path: '/find/specialTopic'
	},
	{
		route: Meeting,
		path: '/find/meeting'
	}
]

class Find extends Component {

	constructor (props) {
	    super(props);
		this.state = {
			
		}
	}

	render () {
		return (
			<Fragment>
				<Layout subRoute={subRoute} subRedirect={subRedirect} subToPath={subToPath} {...this.props}/>
			</Fragment>
		)
	}
}
export default Find;