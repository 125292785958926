import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './index.css';

class CommonFooter extends Component {
	render () {
		return (
			<Fragment>
				<div className="footerWrap">
					<div className="footerContainer clearfix">
						<div className="footerLeft">
							<div className="footerImg">
								<img src={logo} alt="logo" />
							</div>
							{/*<ul className="footerNav clearfix">*/}
							{/*	<li>*/}
							{/*		<a href="#">编委会</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">网站管理</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">友情链接</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">广告与服务</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">加入我们</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">关于我们</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">收藏本站</a>*/}
							{/*	</li>*/}
							{/*</ul>*/}
							<p className="footerInstruct">Copyright  2016-2022 All Rights Reserved.杭州大伽信息科技有限公司 版权所有   联系电话：13396585932</p>
							<p className="footerICP">大伽病理•精准国际病理诊断网备案/许可证编号：<a href="http://www.miitbeian.gov.cn">浙ICP备19012671号</a></p>
						</div>
						<div className="footerRight">
							<ul>
								<li>
									<div>
										<img src="http://dia.dakapath.com/Public/Home/images/footer-app.png" alt="" />
									</div>
									<p>扫描下载APP客户端</p>
								</li>
								<li>
									<div>
										<img src="http://dia.dakapath.com/Public/Home/images/footer-wx.png.jpeg" alt="" />
									</div>
									<p>扫描关注微信公众号</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="footerMobileWrap">
					<div className="footerMobileContainer">
						<div className="footerMobileLeft">
							<div className="footerMobileImg">
								<img src={logo} alt="logo" />
							</div>
							{/*<ul className="footerMobileNav clearfix">*/}
							{/*	<li>*/}
							{/*		<a href="#">编委会</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">网站管理</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">友情链接</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">广告与服务</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">加入我们</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">关于我们</a>*/}
							{/*	</li>*/}
							{/*	<li>*/}
							{/*		<a href="#">收藏本站</a>*/}
							{/*	</li>*/}
							{/*</ul>*/}
							<p className="footerMobileInstruct">Copyright  2016-2022 All Rights Reserved.杭州大伽信息科技有限公司 版权所有   联系电话：13396585932</p>
							<p className="footerMobileICP">大伽病理•精准国际病理诊断网备案/许可证编号：<a href="http://www.miitbeian.gov.cn">浙ICP备19012671号</a></p>
						</div>
						<div className="footerMobileRight">
							<ul className="clearfix">
								<li>
									<div>
										<img src="http://dia.dakapath.com/Public/Home/images/footer-app.png" alt="" />
									</div>
									<p>扫描下载APP客户端</p>
								</li>
								<li>
									<div>
										<img src="http://dia.dakapath.com/Public/Home/images/footer-wx.png.jpeg" alt="" />
									</div>
									<p>扫描关注微信公众号</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CommonFooter;
