import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import CommonTitle from '@/components/commonTitle';
import CommonFooter from '@/components/commonFooter';

import softLogo from '@/assets/logo.jpg';
import indext from '@/assets/indext.jpg';
import index_14 from '@/assets/index_14.jpg';
import index_18 from '@/assets/index_18.jpg';
import index_22 from '@/assets/index_22.jpg';
import index_26 from '@/assets/index_26.jpg';
import index_30 from '@/assets/index_30.jpg';
import index_34 from '@/assets/index_34.jpg';

import './index.css';

class Software extends Component {
	
	constructor(props) {
	    super(props);
		this.state = {};
	}
	
	toPath (pathName) {
		this.props.history.push('/' + pathName)
	}
	
	loginOut () {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('userInfo');
		this.props.history.push('/login');
	}
	
	render () {
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<div className="softwareFirst clearfix">
					<div className="softwareFirstLeft clearfix">
						<div className="softwareFirstLeftLogo">
							<img src={softLogo} alt="softLogo" />
						</div>
						<div className="softwareFirstLeftLogoText">&nbsp;|&nbsp;工具</div>
					</div>
					<div className="softwareFirstRight">
						<ul>
							{
								(!token || !userInfo) ? <li onClick={this.toPath.bind(this, 'login')}>登录</li> : <li onClick={this.toPath.bind(this, 'userInfo')}>{userInfo.realname}</li>
							}
							<li>&nbsp;/&nbsp;</li>
							{
								(!token || !userInfo) ? <li onClick={this.toPath.bind(this, 'register')}>注册</li> : <li onClick={this.loginOut.bind(this)}>[退出]</li>
							}
						</ul>
					</div>
				</div>
				<div className="softwareSecond"></div>
				<div className="softwareThird">
					<div className="softwareThirdTitle clearfix">
						<div className="softwareThirdTitleLeft"></div>
						<div className="softwareThirdTitleText">智能病理管理系统1.0</div>
					</div>
					<ul className="softwareThirdFirstList clearfix">
						<li>
							<div className="softwareThirdFirstListImg">
								<img src={indext} alt="indext" />
							</div>
							<p className="softwareThirdFirstListText">要写的我们都尽量帮您写好</p>
						</li>
						<li>
							<div className="softwareThirdFirstListImg">
								<img src={indext} alt="indext" />
							</div>
							<p className="softwareThirdFirstListText">您提出要求，我为您定制</p>
						</li>
						<li>
							<div className="softwareThirdFirstListImg">
								<img src={indext} alt="indext" />
							</div>
							<p className="softwareThirdFirstListText">提供标准操作程序、标准模板、避免信息遗漏</p>
						</li>
						<li>
							<div className="softwareThirdFirstListImg">
								<img src={indext} alt="indext" />
							</div>
							<p className="softwareThirdFirstListText">人机对话、自动生成报告</p>
						</li>
						<li>
							<div className="softwareThirdFirstListImg">
								<img src={indext} alt="indext" />
							</div>
							<p className="softwareThirdFirstListText">标本接收、取材、制片、报告、档案管理全程监控</p>
						</li>
						<li>
							<div className="softwareThirdFirstListImg">
								<img src={indext} alt="indext" />
							</div>
							<p className="softwareThirdFirstListText">设备维护、耗材管理、质量监控、绩效考核、能力评估全面覆盖</p>
						</li>
					</ul>
					<div className="softwareThirdComputerImg">
						<img src={index_14} alt="index_14" />
					</div>
					<p className="softwareThirdContentText">
						<span>大伽智能病理管理系统</span>
						遵循病理诊断的流程，规范引导病理诊断行为，对病理标本信息进行数字化管理；该系统的六大优点——可以直接调用系统已编好的内容的人性化；根据每个病例需求进行私人订制的个性化；取材、报告参照CAP Checklist的规范化；人机对话、报告向导、报告自动生成的智能化；设备维护、试剂管理、能力评估、绩效考核、质量控制的全能化；样本接收、制片、报告签发、档案管理全面覆盖的全程化。
					</p>
					<p className="softwareThirdContentText">
						<span>大伽智能病理管理系统</span>
						预设了病理诊断知识库，提炼了病理诊断工作中常用的诊断分类，集成国内外病理专家丰富经验，精心制作了对应的模板数据，还配合了录音、摄像、扫描等多媒体辅助技术手段供病理医生使用。该系统包括组织病理和细胞病理两个子系统，还可以根据客户需求提供染色体核型分型、流式细胞免疫分型及分子诊断系统。系统提供了灵活的用户权限管理，组织病理库设置了登记、取材、脱水、包埋、切片、染色、冰冻、初诊、复诊、免疫组化、试剂管理、档案管理、数据统计等工作站。为病理科检查流程中各个环节提供指导和帮助；大大提高病理科的工作效率，同时充分发挥医院信息系统的质量控制作用。
					</p>
				</div>
				<div className="softwareFour">
					<h1>除了在流程管理及样本跟踪定位等有精细化设计外，</h1>
					<h2>与同类软件相比的六大优势</h2>
					<p className="softwareFourFirst">优势一</p>
					<p className="softwareFourFirstText">针对常见取材部位或器官列举相应手术方式，在根据手术方式设置大体描述模板。手术方式与部位相关联，标本类型与手术方式关联，大体描述模板与标本类型一一对应，选择部位后可依次选择手术方式、标本类型，并<span>自动调取对应的大体描述模板。避免在大量描述模板中挑选目标模板造成时间浪费，同时提高准确性。</span></p>
					<div className="softwareFourFirstImg">
						<img src={index_18} alt="index_18" />
					</div>
					<p className="softwareFourFirst">优势二</p>
					<p className="softwareFourSecondText">大体描述模板采用填【】式录入，<span>点击“</span><img src={index_22} alt="index_22" /><span>”键直接进入下一个待填【】，空格设置默认值，默认值不需修改时，按右向键进入下一个【】。可根据实际情况任意填写。【】以外内容一般不需要修改。避免在完整模板上寻找需要修改的地方再进行修改，不会因漏改造成描述错误。</span>大体描述模板可以替换成客户自己的模板。</p>
					<p className="softwareFourSecondTexts"><span>例：乳腺改良根治标本大体乳腺部分描述模板</span>【福尔马林固定】【】侧乳腺癌改良根治标本，总体积【】cmx 【】cmx 【】cm。表面附梭形皮肤，面积【】cm x【】 cm，乳 头【无】凹陷，皮肤【无】橘皮外观，触之【不均匀】。多面切 开，于乳腺【外上】象限见【1】个【肿 瘤】，肿瘤最大径【】 cm，其他两径【】cmx【】cm。肿瘤距【】侧切缘最近，距该 切缘【】cm，肿瘤距基地切缘【】cm，切缘涂【】色。肿瘤界限【不清】，【无】包膜，切面【灰白】色，【颗粒】状，质地【硬】，【无】出血，【无】坏死，【无】钙化，其余乳腺组织【无特殊改变】。
					</p>
					<div className="softwareFourSecondImg">
						<img src={index_26} alt="index_26" />
					</div>
					<p className="softwareFourFirst">优势三</p>
					<p className="softwareFourThirdText"><span>除主标本外，设置附加标本及相应的大体描述模板，</span>取材时根据送检情况选取附加标本，调取相应的模板，完成填空即可。</p>
					<p className="softwareFourThirdTexts"><span>例：</span>如下图乳腺改良根治标本设置了“淋巴结”附加标本，根据临床常见送检标本情况，将淋巴结分设“前哨淋巴结、腋窝淋巴结、腋窝上淋巴结、腋窝下淋巴结”等。取材时根据实际情况任意选择其中一组或多组，并可调取大体描述模板。</p>
					<div className="softwareFourThirdImg">
						<img src={index_30} alt="index_30" />
					</div>
					<p className="softwareFourFirst">优势四</p>
					<p className="softwareFifthText">病理报告“书写”<br/>根据标本类型，列举了必须报告的信息及根据取材时选择附加标本的相应信息，并设置了相应的诊断术语，省去了大部分人工录入工作，提高了工作效率，也避免了因人工录入造成的差错。</p>
					<p className="softwareFifthTexts"><span>例：</span>乳腺浸润性癌改良根治标本列举了组织诊断、原位癌成分、脉管、神经、肿瘤分级、淋巴结（取材时选择淋巴结组名称，如取材时选择有前哨淋巴结和腋窝淋巴结两组，此处仅显示前哨淋巴结和腋窝淋巴结）、免疫组化（HER2、ER、PR、Ki-67）。打开报告向导，选择主诊断后可以直接调取大体描述里的关键参数（如标本类型、肿瘤位置、肿瘤最大径、其它两径等），不需要重复填写。</p>
					<div className="softwareFifthImg">
						<img src={index_34} alt="index_34" />
					</div>
					<p className="softwareFourFirst">优势五</p>
					<p className="softwareFourSixth"><span>整合质量体系文件（标准操作程序）及流程/实验记录表，</span>可采用系统标准配备文件及记录表格，也可换成客户自己的文件及记录表格。</p>
					<p className="softwareFourFirst">优势六</p>
					<p className="softwareFourSeventh"><span>“一键式”质量指标统计功能：</span>根据客户对质量指标统计周期的要求，设置“一键”调取以下质量统计表。</p>
					<ul className="softwareFourSeventhText clearfix">
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
						<li>> 外科标本固定情况统计表</li>
					</ul>
					<p className="softwareFourSeventhTexts">申明：为描述方便，有些图片和真实版本可能不同，最终效果以真实软件为准。</p>
				</div>	
				<CommonFooter />
			</Fragment>
		)
	}
}

export default Software;
