import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// import PropTypes from 'prop-types';
import moment from 'moment';
import './index.css';

class CommonListCard extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}


	render () {
		const { articleList } = this.props;
		return (
			<Fragment>
				<ul className="microSecondListWrap clearfix">
					{
						articleList.length > 0 ? articleList.map((item, index) => {
							return (
								<NavLink   target="_blank"   to={`/microClassDetails/${item.id}`}>
								<li className="microSecondListContent" key={item.id}>
									<div className="microSecondListContentImg">
										<img src={item.cover ? item.cover.src : ''} alt="" />
									</div>
									<h1 className="microSecondListContentTitle">{item.title}</h1>
									<p className="microSecondListContentDesc">{item.desc}</p>
									<ul className="microSecondListContentFooter clearfix">
										<li>{moment(item.publish_time).format('YYYY-MM-DD')}</li>
										<li>浏览：{item.view_num}</li>
										<li>评论：{item.comments_count}</li>
									</ul>
								</li>
								</NavLink>
							)
						}) : ''
					}
				</ul>
			</Fragment>
		)
	}
}

export default CommonListCard;
