import axios from 'axios'

import { Message } from 'element-react';
import { getToken } from '@/utils/auth'

let BASE_API = ''

// 创建axios实例
if (process.env.NODE_ENV === 'development') { // 测试环境
  BASE_API = 'http://api.remote.dakapath.com/'
}

if (process.env.NODE_ENV === 'production') { // 发布环境
  BASE_API = 'http://api.remote.dakapath.com/'
}

const service = axios.create({
  baseURL: BASE_API, // api 的 base_url
  timeout: 5000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
			// 在发送请求之前做些什么
		const token = getToken()
		if (token) {
			// 将获取到的token设置给header中的Authorization
			config.headers.Authorization = token
		}
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    if (res.success !== true) {
		Message({
		  message: res.err_msg,
		  type: 'error',
		  showClose: true,
		  duration: 5 * 1000
		})
      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      // if (res.status_code === 50008 || res.status_code === 50012 || res.status_code === 50014) {
      //   MessageBox.confirm(
      //     '你已被登出，可以取消继续留在该页面，或者重新登录',
      //     '确定登出',
      //     {
      //       confirmButtonText: '重新登录',
      //       cancelButtonText: '取消',
      //       type: 'warning'
      //     }
      //   ).then(() => {
      //     store.dispatch('FedLogOut').then(() => {
      //       location.reload() // 为了重新实例化vue-router对象 避免bug
      //     })
      //   })
      // }
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
    console.log('err' + error) // for debug
	Message({
	  message: error.message,
	  type: 'error',
	  showClose: true,
	  duration: 5 * 1000
	})
    return Promise.reject(error)
  }
)

export default service
