//设置/修改cookie
export function setCookie(cookieName, cookieValue, cookieDay, cookiePath) {
	var cookieValue = encodeURIComponent(cookieValue);
	var cookiePath = cookiePath || "/";
	var oDate = new Date();
	oDate.setDate(oDate.getDate() + cookieDay);
	document.cookie = cookieName + "=" + cookieValue + ";expires=" + oDate + ";path=" + cookiePath + ";domain=dakapath.com" ;
}
//setCookie("op","你好xdsads",10);

//获取cookie
export function getCookie(cookieName) {
	var oCookie = decodeURIComponent(document.cookie);
	var oArr = oCookie.split("; ");
	for (var i = 0; i < oArr.length; i++) {
		var oEarr = oArr[i].split("=");
		if (oEarr[0] == cookieName) {
			return oEarr[1];
		}
	}
}
//console.log(getCookie("op"));

//删除cookie
export function removeCookie(cookieName) {
	setCookie(cookieName, "", -10);
}
//removeCookie("op");
//重新获取cookie时候获取不到
//console.log(getCookie("op"));
