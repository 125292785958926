import React, { Component, Fragment } from 'react';

import userAvatar from '@/assets/user-avatar.jpg';
import tieziLeft from '@/assets/tiezi-left.png';

import './index.css';

class CommonDetailsList extends Component {
	constructor(props) {
	    super(props);
	}
	render () {
		const { details, parent } = this.props;
		const { user } = details;
		return (
			<Fragment>
				{/* pc端 */}
				<div className="commonDetailsListWrap clearfix">
					{
						user ? <div className="commonDetailsListLeft">
						<div className="commonDetailsListLeftUserAvatar">
							<img src={user.avatar} />
						</div>
						<p className="commonDetailsListLeftName">{user.realname}</p>
						<p className="commonDetailsListLeftNum">帖子：{user.CommunicationCount}     积分：{user.integral}</p>
						<p className="commonDetailsListLeftTime">注册时间：{user.created_at}</p>
					</div> : <div className="commonDetailsListLeft">
						<div className="commonDetailsListLeftUserAvatar">
							<img src={userAvatar} />
						</div>
						<p className="commonDetailsListLeftName">用户已注销</p>
						<p className="commonDetailsListLeftNum">帖子：0     积分：0</p>
						<p className="commonDetailsListLeftTime">用户已注</p>
					</div>
					}
					<div className="commonDetailsListRight">
						<div className="commonDetailsListRightContent" dangerouslySetInnerHTML={{ __html: details.content }}></div>
						<div className="commonDetailsListRightInfo"></div>
						<div className="commonDetailsListRightBottom clearfix">
							<ul className="commonDetailsListRightBottomRight clearfix">
								<li>发帖时间：{parent.created_at}</li>
							</ul>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CommonDetailsList;
