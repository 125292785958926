import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import userAvatar from '@/assets/user-avatar.jpg';
import tieziLeft from '@/assets/tiezi-left.png';

import './index.css';

class commonDetailsListAuther extends Component {
	constructor(props) {
	    super(props);
		this.state = {};
	}

	renderInfo () {
		const { parent, total } = this.props;
		const { user } = parent;
		if (user) {
			return (
				<div className="commonDetailsListAutherLeft">
					<div className="commonDetailsListAutherLeftUserAvatar">
						<img src={user.avatar} />
					</div>
					<p className="commonDetailsListAutherLeftName">{user.realname}</p>
					<p className="commonDetailsListAutherLeftNum">帖子：{user.CommunicationCount}     积分：{user.integral}</p>
					<p className="commonDetailsListAutherLeftTime">注册时间：{user.created_at}</p>
					<div className="commonDetailsListAutherLeftAuthorTag">
						<img src={tieziLeft} alt="tieziLeft" />
					</div>
				</div>
			)
		} else {
			return (
				<div className="commonDetailsListAutherLeft">
					<div className="commonDetailsListAutherLeftUserAvatar">
						<img src={userAvatar} />
					</div>
					<p className="commonDetailsListAutherLeftName">用户已注销</p>
					<p className="commonDetailsListAutherLeftNum"></p>
					<p className="commonDetailsListAutherLeftTime"></p>
					<div className="commonDetailsListAutherLeftAuthorTag">
						<img src={tieziLeft} alt="tieziLeft" />
					</div>
				</div>
			)
		}
	}

	renderMobileInfo () {
		const { parent, total } = this.props;
		const { user } = parent;
		if (user) {
			return (
				<div className="commonDetailsListAutherMobileLeft">
					<div className="commonDetailsListAutherMobileLeftUserAvatar">
						<img src={user.avatar} />
					</div>
					<p className="commonDetailsListAutherMobileLeftName">{user.realname}</p>
					<p className="commonDetailsListAutherMobileLeftNum">帖子：{user.CommunicationCount}     积分：{user.integral}</p>
					<p className="commonDetailsListAutherMobileLeftTime">注册时间：{user.created_at}</p>
					<div className="commonDetailsListAutherMobileLeftAuthorTag">
						<img src={tieziLeft} alt="tieziLeft" />
					</div>
				</div>
			)
		} else {
			return (
				<div className="commonDetailsListAutherMobileLeft">
					<div className="commonDetailsListAutherMobileLeftUserAvatar">
						<img src={userAvatar} />
					</div>
					<p className="commonDetailsListAutherMobileLeftName">用户已注销</p>
					<p className="commonDetailsListAutherMobileLeftNum"></p>
					<p className="commonDetailsListAutherMobileLeftTime"></p>
					<div className="commonDetailsListAutherMobileLeftAuthorTag">
						<img src={tieziLeft} alt="tieziLeft" />
					</div>
				</div>
			)
		}
	}

	render () {
		const { parent, total } = this.props;
		const { user } = parent;
		return (
			<Fragment>
				<div className="commonDetailsListAutherWrap clearfix">
					{ this.renderInfo() }
					<div className="commonDetailsListAutherRight">
						<h1>{parent.title}</h1>
						<div className="commonDetailsListAutherRightContetn" dangerouslySetInnerHTML={{ __html: parent.content }}></div>
						<div className="commonDetailsListAutherRightMore">
							<img src="http://api.remote.dakapath.com/static/web/css/../img/tiezi-more.png" alt="" />
							<p>点击查看更多</p>
						</div>
						<div className="commonDetailsListAutherRightBottom clearfix">
							<div className="commonDetailsListAutherRightBottomLeft">
								[<span>收藏</span>]
							</div>
							<ul className="commonDetailsListAutherRightBottomRight clearfix">
								<li>回帖：{ total }</li>
								<li>浏览：{parent.view_num}</li>
								<li>发帖时间：{parent.created_at}</li>
							</ul>
						</div>
					</div>
				</div>
				{/* 移动端 */}
				<div className="commonDetailsListAutherMobileWrap clearfix">
					{ this.renderMobileInfo() }
					<div className="commonDetailsListAutherMobileRight">
						<h1>{parent.title}</h1>
						<div className="commonDetailsListAutherMobileRightContetn" dangerouslySetInnerHTML={{ __html: parent.content }}></div>
						<div className="commonDetailsListAutherMobileRightMore">
							<img src="http://api.remote.dakapath.com/static/web/css/../img/tiezi-more.png" alt="" />
							<p>点击查看更多</p>
						</div>
						<div className="commonDetailsListAutherMobileRightBottom clearfix">
							<div className="commonDetailsListAutherMobileRightBottomLeft">
								[<span>收藏</span>]
							</div>
							<ul className="commonDetailsListAutherMobileRightBottomRight clearfix">
								<li>回帖：{ total }</li>
								<li>浏览：{parent.view_num}</li>
								<li>发帖时间：{parent.created_at}</li>
							</ul>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default commonDetailsListAuther;
