import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Message } from 'element-react';

import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';

import misc from '@/assets/misc.gif';

import { getCatchaImg, toUserRegister, toUserSms} from '@/api/login';

import './index.css';

class register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tel: '',
			telFlag: false,
			userName: '',
			userNameFlag: false,
			code: '',
			codeFlag: false,
			pas: '',
			pasFlag: false,
			work: '',
			workFlag: false,
			errMsg: '',
			codeText: '点击获取验证码',
			codeImg: '',
			codeImgNum: '',
			codeImgNumFlag: false,
			key: '',
			isEnable: true
		};
		this.initCatchaImg = this.initCatchaImg.bind(this);
		// 通用的获取焦点
		this.handleInputFocus = this.handleInputFocus.bind(this);
		// 手机号失去焦点
		this.handleTelInputBlur = this.handleTelInputBlur.bind(this);
		// 用户名失去焦点
		this.handleUserNameInputBlur = this.handleUserNameInputBlur.bind(this);
		// 图片验证码失去焦点
		this.handleCodeImgNumInputBlur = this.handleCodeImgNumInputBlur.bind(this);
		// 验证码失去焦点
		this.handleCodeInputBlur = this.handleCodeInputBlur.bind(this);
		// 密码失去焦点
		this.handlePasInputBlur = this.handlePasInputBlur.bind(this);
		// 职业
		this.handleWorkChange = this.handleWorkChange.bind(this);
	}

	componentDidMount() {
		this.initCatchaImg();
	}

	// 通用的获取焦点
	handleInputFocus () {
		this.setState({
			errMsg: ''
		});
	}

	handleTelInputBlur (event) {
		let val = event.target.value;

		//用户名正则，4到16位（字母，数字，下划线，减号）
		let regName = /^[a-zA-Z0-9_\u4e00-\u9fa5]{4,}$/
		let regTel = /^1[345789]\d{9}$/;

		if (regName.test(val) || regTel.test(val) ) {
			this.setState({
				tel: event.target.value,
				telFlag: true,
				errMsg: ''
			}, () => {
				console.log(this.state.tel)
			});
		} else {
			this.setState({
				errMsg: '请输入用户名或手机号'
			});
		}
	}

	handleUserNameInputBlur (event) {
		let val = event.target.value;

		//用户名正则，4到16位（字母，数字，下划线，减号）
		let regName = /^(\w){4,16}$/;

		if (regName.test(val)) {
			this.setState({
				userName: event.target.value,
				userNameFlag: true,
				errMsg: ''
			}, () => {
				console.log(this.state.userName)
			});
		} else {
			this.setState({
				errMsg: '请输入昵称/姓名'
			});
		}
	}

	// 图片验证码
	handleCodeImgNumInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				codeImgNum: val,
				codeImgNumFlag: true
			}, () => {
				console.log(this.state.codeImgNum)
			});
		} else {
			this.setState({
				errMsg: '请填写图片验证码'
			});
		}
	}

	// 验证码失去焦点
	handleCodeInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				code: val,
				codeFlag: true
			}, () => {
				console.log(this.state.code)
			});
		} else {
			this.setState({
				errMsg: '请填写短信验证码'
			});
		}
	}

	// 密码失去焦点
	handlePasInputBlur (event) {
		let val = event.target.value;

		//只能输入4-16个字母、数字、下划线
		let reg = /^(\w){4,}$/;

		if (reg.test(val)){
			this.setState({
				pas: val,
				pasFlag: true
			}, () => {
				console.log(this.state.pas)
			});
		} else {
			this.setState({
				errMsg: '请填写登录密码'
			});
		}
	}

	handleWorkChange (event) {
		let val = event.target.value;
		this.setState({
			work: val
		}, () => {
			console.log(this.state.work)
		})
	}

	// 初始化图片验证码
	initCatchaImg () {
		getCatchaImg({}).then(res => {
			if (res.success) {
				const { img, key, sensitive } = res.data;
				this.setState({
					codeImg: img,
					key
				})
			}
		})
	}

	// 发送验证码
	sendCode () {
		const { tel, key, codeImgNum, isEnable } = this.state;
		let time = 60;
		if (!this.state.tel) {
			Message({
			  message: '请填写正确的手机号',
			  type: 'error',
			  showClose: true,
			  duration: 5 * 1000
			})
		} else if (!this.state.codeImgNum) {
			Message({
			  message: '请输入图片验证码',
			  type: 'error',
			  showClose: true,
			  duration: 5 * 1000
			})
		} else {
			if (isEnable) {
				toUserSms({
					tel,
					key,
					code: codeImgNum
				}).then(res => {
					this.timeId = setInterval(() => {
						if (time <= 1) {
							clearInterval(this.timeId)
							this.setState({
								codeText: '点击获取验证码',
								isEnable: true
							})
							return
						}
						time--
						this.setState({
							codeText: `${time}s后重发`,
							isEnable: false
						})
					}, 1000)
				})
			}
		}
	}

	// 注册按钮
	registerBtn () {
		const { tel, telFlag, code, codeFlag, pas, pasFlag  } = this.state;
		if (telFlag && codeFlag && pasFlag) {
			toUserRegister({
				telephone: tel,
				password: pas,
				code
			}).then(res => {
				Message({
				  message: '注册成功',
				  type: 'success',
				  showClose: true,
				  duration: 5 * 1000
				})
				this.props.history.push('/home');
				const {
					CommunicationCount,//发帖数量
					avatar,//头像
					birthday,//生日
					company,//公司
					education,//最高学历
					email,//邮箱
					integral,//积分
					level,//职称
					professional,//职业
					realname,//昵称
					region,//所在区域
					sex,//性别
					telephone,//电话
					token,
					username,//账号
				} = res.data;

				const userInfo = {
					CommunicationCount,//发帖数量
					avatar,//头像
					birthday,//生日
					company,//公司
					education,//最高学历
					email,//邮箱
					integral,//积分
					level,//职称
					professional,//职业
					realname,//昵称
					region,//所在区域
					sex,//性别
					telephone,//电话
					username,//账号
				}
				sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
				sessionStorage.setItem('token', token);
			})
		}
	}

	render () {
		const { tel, errMsg, userName, code, pas, work, codeText, codeImg, codeImgNum, isEnable} = this.state;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				<div className="registerWrap">
					<div className="registerContainer">
						<div className="registerBg">
							<div className="registerForm">
								{/*<h1>注   册</h1>*/}
								<div className="registerFormContent">
									<p className="registerMsg">{errMsg}</p>
									<div className="registerTel clearfix">
										<input autoComplete="off" type="text" name="tel" placeholder="请输入手机号" defaultValue={tel} onFocus={this.handleInputFocus} onBlur={this.handleTelInputBlur} />
									</div>
									<div className="registerName clearfix">
										<input autoComplete="off" type="text" name="userName" placeholder="昵称/姓名" defaultValue={userName} onFocus={this.handleInputFocus} onBlur={this.handleUserNameInputBlur} />
									</div>
									<div className="registerCodeContainer clearfix">
										<div className="registerCodeImgLeft clearfix">
										<input autoComplete="off" type="text" name="codeImgNum" defaultValue={codeImgNum} placeholder="请输入验证码" onFocus={this.handleInputFocus} onBlur={this.handleCodeImgNumInputBlur} />
										</div>
										<div className="registerCodeImg" onClick={this.initCatchaImg}>
											<img src={codeImg} alt="codeImg" />
										</div>
									</div>
									<div className="registerCodeContainer clearfix">
										<div className="registerCode clearfix">
											<input autoComplete="off" type="text" name="code" placeholder="请输入验证码" defaultValue={code} onFocus={this.handleInputFocus} onBlur={this.handleCodeInputBlur} />
										</div>
										<div className="registerCodeButton" onClick={this.sendCode.bind(this)}>
											<button style={isEnable ? {background: '#99cc00'} : {background: '#a0a0a0'}} type="button" disabled={isEnable ? '' : 'disabled'}>{codeText}</button>
										</div>
									</div>
									<div className="registerPass clearfix">
										<input autoComplete="off" type="password" name="pas" placeholder="请输入密码" defaultValue={pas} onFocus={this.handleInputFocus} onBlur={this.handlePasInputBlur} />
									</div>
									{/*<div className="registerSelect">*/}
									{/*	<select className="registerSelectList" value={work} onChange={this.handleWorkChange}>*/}
									{/*	  <option value ="volvo">Volvo</option>*/}
									{/*	  <option value ="saab">Saab</option>*/}
									{/*	  <option value="opel">Opel</option>*/}
									{/*	  <option value="audi">Audi</option>*/}
									{/*	</select>*/}
									{/*</div>*/}
									<p className="registerOther">我同意并接受<span>《大伽用户协议》</span></p>
									<button className="registerBtn" onClick={this.registerBtn.bind(this)}>立即注册</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="registerMobileWrap">
					<div className="registerMobileContainer">
						<div className="registerMobileBg">
							<div className="registerMobileForm">
					
								<div className="registerMobileFormContent">
									<p className="registerMobileMsg">{errMsg}</p>
									<div className="registerMobileTel clearfix">
									<input autoComplete="off" type="text" name="tel" placeholder="请输入手机号" defaultValue={tel} onFocus={this.handleInputFocus} onBlur={this.handleTelInputBlur} />
									</div>
									<div className="registerMobileName clearfix">
									<input autoComplete="off" type="text" name="userName" placeholder="昵称/姓名" defaultValue={userName} onFocus={this.handleInputFocus} onBlur={this.handleUserNameInputBlur} />
									</div>
									<div className="registerMobileCodeContainer clearfix">
										<div className="registerMobileCode clearfix">
											<input autoComplete="off" type="text" name="codeImgNum" defaultValue={codeImgNum} placeholder="请输入验证码" onFocus={this.handleInputFocus} onBlur={this.handleCodeImgNumInputBlur} />
										</div>
										<div className="registerMobileCodeImg" onClick={this.initCatchaImg}>
											<img src={codeImg} alt="codeImg" />
										</div>
									</div>
									<div className="registerMobileCodeContainer clearfix">
										<div className="registerMobileCode clearfix">
										<input autoComplete="off" type="text" name="code" placeholder="请输入验证码" defaultValue={code} onFocus={this.handleInputFocus} onBlur={this.handleCodeInputBlur} />
										</div>
										<div className="registerMobileCodeButton" onClick={this.sendCode.bind(this)}>
											<button style={isEnable ? {background: '#99cc00'} : {background: '#a0a0a0'}} type="button" disabled={isEnable ? '' : 'disabled'}>{codeText}</button>
										</div>
									</div>
									<div className="registerMobilePass clearfix">
									<input autoComplete="off" type="password" name="pas" placeholder="请输入密码" defaultValue={pas} onFocus={this.handleInputFocus} onBlur={this.handlePasInputBlur} />
									</div>
									{/* <div className="registerMobileSelect">
										<select className="registerMobileSelectList">
										  <option value ="volvo">Volvo</option>
										  <option value ="saab">Saab</option>
										  <option value="opel">Opel</option>
										  <option value="audi">Audi</option>
										</select>
									</div> */}
									<p className="registerMobileOther">我同意并接受<span>《大伽用户协议》</span></p>
									<button className="registerMobileBtn" onClick={this.registerBtn.bind(this)}>立即注册</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

export default register;
