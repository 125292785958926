import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Pagination } from 'element-react';

import CommonSubnav from '@/components/commonSubnav';
import CommonGuessList from '@/components/commonGuessList'
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';

import searchRightImg from '@/assets/searchRightImg.jpg';
import index17 from '@/assets/index_17.jpg';

import { getArticleList } from '@/api/microClass';

import './index.css';

const subNav = [
	{
		name: '搜一搜',
		path: '/microClass/search'
	},
	{
		name: '猜猜看',
		path: '/microClass/guess'
	}
]

class Guess extends Component {

	constructor(props) {
		super(props);
	    this.state = {
			postData: {
				page: 1,
				category_id: 6,
				start: 0,
				length: 20
			},
			articleList: '',
			articleListTotal: '',
			pathology: '',
			hotNews: ''
		}
	}

	componentDidMount () {
		this.initPageList();
		this.initPathology();
		this.initHotNews();
	}

	initPageList () {
		getArticleList(this.state.postData).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data,
					articleListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 6,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	handleCurrentChange (val) {
		this.setState({
			postData: {
				page: val,
				category_id: 6,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	initPathology () {
		getArticleList({
			category_id: 4,
			length: 6
		}).then(res => {
			if (res.success) {
				this.setState({
					pathology: res.data.data
				})
			}
		})
	}

	initHotNews () {
		getArticleList({
			category_id: 48,
			is_hot: 1,
			length: 6
		}).then(res => {
			if (res.success) {
				this.setState({
					hotNews: res.data.data
				})
			}
		})
	}

	render () {
		const { articleList, articleListTotal, postData, pathology, hotNews } = this.state;
		return (
			<Fragment>
				<CommonSubnav subNav={subNav} {...this.props}/>
				<div className="guessContentLeft">
					<div className="guessContentLeftFirst">
						{
							articleList.length > 0 ? articleList.map((item, index) => {
								return (
									<CommonGuessList key={item.id} articleList={item} />
								)
							}) : ''
						}
						{
							articleList.length > 0 ? <div className="guessContentLeftFirstPagination">
							<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
						</div> : ''
						}
					</div>
				</div>
				<div className="guessContentRight">
					<div className="guessContentRightFirst">
							<img src={searchRightImg} alt="searchRightImg" />
					</div>
					<div className="guessContentRightSecond">
						<CommonTitle title="病理微课堂" showRightIcon={true} toPath="/microClass/micropathology" />
						<div className="guessContentRightSecondContent">
							{
								pathology.length > 0 ? pathology.map((item, index) => {
									return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
								}) : ''
							}
						</div>
					</div>
					<div className="guessContentRightThird">
						<CommonTitle title="热门资讯" showRightIcon={true} toPath="/information/all" />
						<div className="guessContentRightThirdImg">
								<img src={searchRightImg} alt="searchRightImg" />
						</div>
						<p className="guessContentRightThirdText">
							<NavLink   target="_blank"   to="/microClass/guess">不会诊断怎么办？找“病理侦探”！</NavLink>
						</p>
						<div className="guessContentRightThirdList">
							{
								hotNews.length > 0 ? hotNews.map((item, index) => {
									return (<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />)
								}) : ''
							}
						</div>
						<div className="guessContentRightThirdBottomImg">
							<NavLink   target="_blank"   to="/software">
								<img src="http://dia.dakapath.com/Public/static/media/index_17.f182e9eb.jpg" alt="searchRightImgindex17" />
							</NavLink>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default Guess;
