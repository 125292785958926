import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './index.css';

class CommonWantPost extends Component {
	render () {
		const { showLeftIcon, title, showRightIcon, toPath } = this.props;
		return (
			<Fragment>
				<div className="commonWantPost">
					我要发帖
				</div>
			</Fragment>
		)
	}
}

// CommonTitle.propTypes = {
// 	showLeftIcon: PropTypes.bool,
// 	title: PropTypes.string.isRequired,
// 	showRightIcon: PropTypes.bool,
// 	toPath: PropTypes.string
// }
// CommonTitle.defaultProps = {
//     showLeftIcon: true,
//     title: '微课堂分类',
//     showRightIcon: false,
// 	toPath: '/home'
// }

export default CommonWantPost;
