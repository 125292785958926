import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Carousel, Pagination } from 'element-react';

import CommonSubnav from '@/components/commonSubnav';
import CommonListCard from '@/components/commonListCard';
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';

import w from '@/assets/w.jpg';
import doctorName from '@/assets/doctorName.jpg';

import { getArticleList, getArticleCategoryList, getSpecialistList } from '@/api/microClass';
// 获取banner接口
import { getBanner } from '@/api/common.js';

import './index.css';

const subNav = [
	{
		name: '纠错台',
		path: '/microClass/correctionTable'
	},
	{
		name: '搜一搜',
		path: '/microClass/search'
	},
	{
		name: '猜猜看',
		path: '/microClass/guess'
	},
	{
		name: '微病理',
		path: '/microClass/micropathology'
	}
]
class Micropathology extends Component {

	constructor(props) {
	    super(props);
		let path = this.props.location.pathname.split('/')
		let category_id = ''
		if ((parseInt(path[path.length - 1]))) {
			category_id = path[path.length - 1]
		} else {
			category_id = 4
		}

		this.state = {
			bannerList: [],
			postData: {
				page: 1,
				category_id: category_id,
				start: 0,
				length: 20
			},
			category_id: category_id,
			articleList: [],// 文章列表
			articleListTotal: '',// 文章总数
			categoryList: [],// 微课堂分类
			hotArticleList: [],// 热门课程
			specialistList: [],// 编委会
			specialistMenList: [],
			showName: false,
			showNameIndex: 0
		}
	}

	componentDidMount () {
		// 获取轮播
		this.initBanner();
		// 文章列表
		this.initPageList();
		// 微课堂分类
		this.initArticleCategoryList();
		// 热门课程
		this.initHotArticleList();
		// 编委会
		this.initSpecialistList();
	}

	// 获取轮播
	initBanner () {
		getBanner({
			type: 5
		}).then(res => {
			if (res.success) {
				this.setState({
					bannerList: res.data
				})
			}
		})
	}

	// 文章列表
	initPageList () {
		getArticleList(this.state.postData).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data,
					articleListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		const { category_id } = this.state;
		this.setState({
			postData: {
				page: val,
				category_id,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	// 当前页
	handleCurrentChange (val) {
		const { category_id } = this.state;
		this.setState({
			postData: {
				page: val,
				category_id,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initPageList(this.state.postData);
		})
	}

	// 微课堂分类
	initArticleCategoryList () {
		getArticleCategoryList({
			parent_id: 4
		}).then(res => {
			if (res.success) {
				this.setState({
					categoryList: res.data
				})
			}
		})
	}

	// 课程
	initHotArticleList () {
		getArticleList({
			length: 6,
			is_hot: 1
		}).then(res => {
			if (res.success) {
				this.setState({
					hotArticleList: res.data.data
				})
			}
		})
	}

	// 编委会
	initSpecialistList () {
		getSpecialistList().then(res => {
			if (res.success) {
				this.setState({
					specialistList: res.data.data,
					specialistMenList: [
						res.data.data.slice(0, 6),
						res.data.data.slice(7, 13),
						res.data.data.slice(14, 19)
					]
				})
			}
		})
	}

	// 选择分类渲染对应数据
	changeArticle (category_id) {
		this.setState({
			category_id,
			postData: {
				page: 1,
				category_id,
				start: 0,
				length: 20
			}
		}, () => {
			this.initPageList();
		})
	}

	// 跳转到详情
	toArticleDetail (id) {
		this.props.history.push(`/microClassDetails/${id}`)
	}

	showName (id) {
		this.setState({
			showName: true,
			showNameIndex: id
		})
	}

	render () {
		const { articleList, articleListTotal, postData, categoryList, hotArticleList, specialistList , bannerList, specialistMenList, showName, showNameIndex } = this.state;
		return (
			<div>
				<CommonSubnav subNav={subNav} {...this.props} />
				<div className="micropathologyWrap clearfix">
					<div className="micropathologyLeft">
						<div className="micropathologyLeftFirst">
							<Carousel height="3.14rem" indicatorPosition="none">
							  {
								bannerList.map((item, index) => {
								  return (
									<Carousel.Item key={index}>
									  <img src={item.image ? item.image.src : ''} />
									</Carousel.Item>
								  )
								})
							  }
							</Carousel>
						</div>
						<div className="micropathologyLeftSecond">
							<CommonListCard articleList={articleList} {...this.props} />
							{
								articleList.length > 0 ? <div className="micropathologyLeftSecondPagination">
								<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
							</div> : ''
							}
						</div>
					</div>
					<div className="micropathologyRight">
						<div className="micropathologyRightFirst">
							<CommonTitle title="微课堂分类" toPath="/microClass/micropathology" />
							<div className="micropathologyRightFirstContent">
								{
									categoryList.length > 0 ? categoryList.map((item, index) => {
										return (
											<div className="micropathologyRightFirstContentList clearfix" key={item.id} onClick={this.changeArticle.bind(this, item.id)}>
												<h1 className="micropathologyRightFirstContentListLeft">{item.name}</h1>
												<div className="micropathologyRightFirstContentListRight">({item.article_count})</div>
											</div>
										)
									}) : ''
								}
							</div>
						</div>
						<div className="micropathologyRightSecond">
							<CommonTitle title="编委会" showRightIcon={true} toPath="/tmp"/>
							<div className="micropathologyRightSecondContent">
									<div className="micropathologyRightSecondContentLeft">
										<img src={specialistList[0] ? specialistList[0].avatar : doctorName}  />
									</div>
									<div className="micropathologyRightSecondContentRight">
										<h1 className="micropathologyRightSecondContentRightDoctorName">{specialistList[0] ? specialistList[0].name : ''}</h1>
										<div className="micropathologyRightSecondContentRightDoctorIntroduct">
											<p dangerouslySetInnerHTML={{ __html: specialistList[0] ? specialistList[0].content : ''}}></p>
										​</div>
									</div>
							</div>
							<div className="micropathologyRightSecondContentSpecialistMenList">
								<Carousel height="2.46rem" arrow="never" indicatorPosition="none" autoplay={true}>
								  {
									specialistMenList.map((item, index) => {

									  return (
										<Carousel.Item key={index}>
											<ul>
												{
													item.map((item, index) => {
														return (
															<li key={index} onMouseOver={this.showName.bind(this, item.id)}>
																<NavLink   target="_blank"   to={`/experts/${item.id}`}>
																<img src={item.avatar} />
																</NavLink>
																{
																	showName && showNameIndex === item.id  && <p className="micropathologyRightSecondContentSpecialistMenListActive">{item.name}</p>
																}
															</li>
														)
													})
												}
											</ul>
										</Carousel.Item>
									  )
									})
								  }
								</Carousel>
							</div>
						</div>
						<div className="micropathologyRightThird">
							<CommonTitle title="热门课程" toPath="/microClass/micropathology"/>
							<div className="micropathologyRightThirdContent">
								{
									hotArticleList.length > 0 ? hotArticleList.map((item, index) => {
										return (
											<div className="commonCategoryContainer clearfix" key={item.id} onClick={this.toArticleDetail.bind(this, item.id)}>
												<h1 className="commonCategoryLeft">{item.title}</h1>
											</div>
										)
									}) : ''
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Micropathology;
