import React, { Component, Fragment } from 'react';
import { Pagination, Message } from 'element-react';
import { NavLink } from 'react-router-dom';
import E from 'wangeditor';

import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';
import CommonDetailsList from '@/components/commonDetailsList';
import CommonDetailsListAuther from '@/components/commonDetailsListAuther';
import CommonWantPost from '@/components/commonWantPost';
import CommonToLogin from '@/components/commonToLogin';

import { getCommunicationListDetail, communicationCommentCreate } from '@/api/communicate';
import { getCatchaImg } from '@/api/login';

import './index.css';

const positionArr = [
	{
		name: '首页',
		path: '/home'
	},
	{
		name: '交流',
		path: '/communicate'
	}
]

class CommunicateDetails extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			// 图片验证码
			codeImg: '',
			// 图片验证码key
			key: '',
			title: '',
			titleFlag: false,
			content: '',
			code: '',
			codeFlag: false,
			postData: {
				page: 1,
				id: '',
				start: 0,
				length: 20,
				is_only: ''
			},
			articleList: '',
			articleListTotal: '',
			parent: '',
			total: '',
			isShowAuther: false,
			isShowAutherText: '只看该作者'
		}
	}

	componentDidMount () {
		const { id } = this.props.match.params;
		this.setState({
			postData: {
				page: 1,
				id,
				start: 0,
				length: 20,
				is_only: ''
			}
		}, () => {
			this.initPageList();
		})
		const token = sessionStorage.getItem('token');
		if (token) {
			//this.initEditor();
			this.initMobileEditor();
			this.initCatchaImg();
		}
	}
	// 快速回帖相关
	// 初始化图片验证码
	initCatchaImg () {
		getCatchaImg({}).then(res => {
			if (res.success) {
				const { img, key, sensitive } = res.data;
				this.setState({
					codeImg: img,
					key
				})
			}
		})
	}

	// 标题
	handleTitleInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				title: val,
				titleFlag: true
			}, () => {
				console.log(this.state.title)
			});
		} else {
			Message({
				message: '请填写标题',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		}
	}

	// 验证码失去焦点
	handleCodeInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				code: val,
				codeFlag: true
			}, () => {
				console.log(this.state.code)
			});
		} else {
			Message({
				message: '请填写验证码',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		}
	}

	// 发表按钮
	sendBtn () {
		this.setState({
			content: this.editor.txt.html()
		})
		const { content } = this.state;
		const { id } = this.props.match.params;
		if (!content) {
			Message({
				message: '请填写要发表的内容',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		} else {
			communicationCommentCreate({
				communication_id: id,
				content
			}).then(res => {
				if (res.success) {
					Message({
						message: '发表成功',
						type: 'success',
						showClose: true,
						duration: 5 * 1000
					})
				}
			})
		}
	}

	// 富文本
	initEditor () {
	    const elem = this.refs.communicateDetailsEditoElem
	    const editor = new E(elem)
	    this.editor = editor
	    editor.customConfig.zIndex = 100
	    editor.customConfig.uploadImgServer = 'http://api.remote.dakapath.com/platform/image/create'
	    // 限制一次最多上传 1 张图片
	    editor.customConfig.uploadImgMaxLength = 1
	    editor.customConfig.customUploadImg = function (files, insert) {
	      // files 是 input 中选中的文件列表
	      console.log(files)
	      if (files[0]) {
	        const formData = new window.FormData()
	        formData.append('file', files[0], 'cover.jpg')
	        fetch('http://api.remote.dakapath.com/platform/image/create', {
	          method: 'POST',
	          body: formData
	        }).then((res) => {
	          return res.json()
	        }).then((res) => {
	          const data = res.resultData
	          if (data) {
	            // 上传代码返回结果之后，将图片插入到编辑器中
	            insert(data.resourceUrl)
	          } else {
	            console.log(data.msg)
	          }
	        })
	      } else {
			  Message({
				message: '請選擇要上傳的圖片',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			  })
	      }
	    }
	    editor.customConfig.menus = [
	      'head', // 标题
	      'bold', // 粗体
	      'fontSize', // 字号
	      // 'fontName', // 字体
	      'italic', // 斜体
	      'underline', // 下划线
	      'strikeThrough', // 删除线
	      'foreColor', // 文字颜色
	      // 'backColor', // 背景颜色
	      'link', // 插入链接
	      'list', // 列表
	      'justify', // 对齐方式
	      'quote', // 引用
	      // 'emoticon', // 表情
	      'image', // 插入图片
	      // 'table', // 表格
	      // 'video', // 插入视频
	      // 'code', // 插入代码
	      'undo', // 撤销
	      'redo' // 重复
	    ]
	    editor.customConfig.lang = {
	      '设置标题': 'Title',
	      '字号': 'Size',
	      '文字颜色': 'Color',
	      '设置列表': 'List',
	      '有序列表': '',
	      '无序列表': '',
	      '对齐方式': 'Align',
	      '靠左': '',
	      '居中': '',
	      '靠右': '',
	      '正文': 'p',
	      '链接文字': 'link text',
	      '链接': 'link',
	      '上传图片': 'Upload',
	      '网络图片': 'Web',
	      '图片link': 'image url',
	      '插入视频': 'Video',
	      '格式如': 'format',
	      '上传': 'Upload',
	      '创建': 'init'
	    }
	    editor.create()
	  }

	  // 富文本
	  initMobileEditor () {
	      const elem = this.refs.communicateDetailsEditoElem
	      const editor = new E(elem)
	      this.editor = editor
	      editor.customConfig.zIndex = 100
	      editor.customConfig.uploadImgServer = 'http://api.remote.dakapath.com/platform/image/create'
	      // 限制一次最多上传 1 张图片
	      editor.customConfig.uploadImgMaxLength = 1
	      editor.customConfig.customUploadImg = function (files, insert) {
	        // files 是 input 中选中的文件列表
	        console.log(files)
	        if (files[0]) {
	          const formData = new window.FormData()
	          formData.append('file', files[0], 'cover.jpg')
	          fetch('http://api.remote.dakapath.com/platform/image/create', {
	            method: 'POST',
	            body: formData
	          }).then((res) => {
	            return res.json()
	          }).then((res) => {
	            const data = res.resultData
	            if (data) {
	              // 上传代码返回结果之后，将图片插入到编辑器中
	              insert(data.resourceUrl)
	            } else {
	              console.log(data.msg)
	            }
	          })
	        } else {
	  		  Message({
	  			message: '請選擇要上傳的圖片',
	  			type: 'error',
	  			showClose: true,
	  			duration: 5 * 1000
	  		  })
	        }
	      }
	      editor.customConfig.menus = [
	        'head', // 标题
	        'bold', // 粗体
	        'fontSize', // 字号
	        // 'fontName', // 字体
	        'italic', // 斜体
	        'underline', // 下划线
	        'strikeThrough', // 删除线
	        'foreColor', // 文字颜色
	        // 'backColor', // 背景颜色
	        'link', // 插入链接
	        'list', // 列表
	        'justify', // 对齐方式
	        'quote', // 引用
	        // 'emoticon', // 表情
	        'image', // 插入图片
	        // 'table', // 表格
	        // 'video', // 插入视频
	        // 'code', // 插入代码
	        'undo', // 撤销
	        'redo' // 重复
	      ]
	      editor.customConfig.lang = {
	        '设置标题': 'Title',
	        '字号': 'Size',
	        '文字颜色': 'Color',
	        '设置列表': 'List',
	        '有序列表': '',
	        '无序列表': '',
	        '对齐方式': 'Align',
	        '靠左': '',
	        '居中': '',
	        '靠右': '',
	        '正文': 'p',
	        '链接文字': 'link text',
	        '链接': 'link',
	        '上传图片': 'Upload',
	        '网络图片': 'Web',
	        '图片link': 'image url',
	        '插入视频': 'Video',
	        '格式如': 'format',
	        '上传': 'Upload',
	        '创建': 'init'
	      }
	      editor.create()
	    }

	initPageList () {
		getCommunicationListDetail(this.state.postData).then(res => {
			if (res.success) {
				console.log(res.data.data.parent)
				this.setState({
					articleList: res.data.data.data,
					articleListTotal: res.data.total,
					parent:res.data.data.parent
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		const { id } = this.props.match.params;
		this.setState({
			postData: {
				page: val,
				id,
				start: 0,
				length: val * 20,
				is_only: ''
			}
		}, () => {
			this.initPageList();
		});
	}

	handleCurrentChange (val) {
		const { id } = this.props.match.params;
		this.setState({
			postData: {
				page: val,
				id,
				start: 0,
				length: val * 20,
				is_only: ''
			}
		}, () => {
			this.initPageList();
		})
	}

	onlyAuther = () => {
		const { isShowAuther } = this.state;
		const { id } = this.props.match.params;
		if (isShowAuther) {
			const { id } = this.props.match.params;
			this.setState({
				postData: {
					page: 1,
					id,
					start: 0,
					length: 20,
					is_only: 1
				},
				isShowAuther: false,
				isShowAutherText: '取消只看该作者'
			}, () => {
				this.initPageList();
			})
		} else {
			this.setState({
				postData: {
					page: 1,
					id,
					start: 0,
					length: 20,
					is_only: ''
				},
				isShowAuther: true,
				isShowAutherText: '只看该作者'
			}, () => {
				this.initPageList();
			})
		}
	}

	render () {
		const { articleList, articleListTotal, postData, parent, total, isShowAutherText, title, codeImg, code } = this.state;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				{/* pc端 */}
				<div className="communicateDetailsWrap">
					<div className="communicateDetailsContent">
						{/*<CommonPosition positionArr={positionArr} title={parent.title}/>*/}
						<div className="communicateDetailsContentFirst clearfix">
							<h1 className="communicateDetailsContentFirstLeft">{parent.title}</h1>
							<div className="communicateDetailsContentFirstRight">
								<span className="communicateDetailsContentFirstRightAuthor" onClick={this.onlyAuther}>{isShowAutherText}</span>
								<span className="communicateDetailsContentFirstRightReply">回复</span>
							</div>
						</div>
						<div className="communicateDetailsContentSecond">
							<CommonDetailsListAuther parent={parent} total={total} />
							{
								articleList.length > 0 ? articleList.map((item, index) => {
									return (<CommonDetailsList parent={parent} total={total} details={item} key={item.id} />)
								}) : ''
							}
						</div>
						<div className="communicateDetailsContentThird">
							<CommonWantPost />
						</div>
						{
							articleList.length > 0 ? <div className="communicateDetailsContentPagination">
							<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
						</div> : ''
						}
						{
							!token ? <div className="communicateDetailsContentFour">
							<CommonToLogin {...this.props} title="快速回帖" />
						</div> : <div className="communicateDetailsContentFour">
							<h1>快速回帖</h1>
							<div className="communicateDetailsContentFourEditor">
								<div ref='communicateDetailsEditoElem' style={{ textAlign: 'left', fontSize: '.16rem' }} />
								<div className="communicateDetailsContentFourCode clearfix">
									<p className="communicateDetailsContentFourCodeTest">验证码</p>
									<input autoComplete="off" type="text" name="code" defaultValue={code} onBlur={this.handleCodeInputBlur.bind(this)}/>
									<div className="communicateDetailsContentFourCodeImg" onClick={this.initCatchaImg.bind(this)}>
										<img src={codeImg} alt="codeImg" />
									</div>
									<p className="communicateDetailsContentFourCodeChange" onClick={this.initCatchaImg.bind(this)}>换一个</p>
								</div>
								<div className="communicateDetailsContentFourCodeBottom clearfix">
									<input type="submit" value="我要发表" onClick={this.sendBtn.bind(this)}/>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

export default CommunicateDetails;
