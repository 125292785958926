import request from '@/utils/request'

// 获取文章列表
export function getArticleList (params) {
  return request({
    url: '/article/list',
    method: 'get',
    params
  })
}

// 获取微课堂分类列表详情
export function getArticleListDetail (params) {
  return request({
    url: '/article/detail',
    method: 'get',
    params
  })
}

// 文章收藏
export function articleCollection (params) {
  return request({
    url: '/article/collection',
    method: 'post',
    data: params
  })
}

// 文章评论
export function articleComment (params) {
  return request({
    url: '/article/comment',
    method: 'post',
    data: params
  })
}

// 文章纠错
export function articleOpinion (params) {
  return request({
    url: '/article/opinion',
    method: 'post',
    data: params
  })
}

// 获取微课堂分类列表
export function getArticleCategoryList (params) {
  return request({
    url: '/article/category/list',
    method: 'get',
    params
  })
}

// 获取编委会
export function getSpecialistList (params) {
  return request({
    url: '/specialist/list',
    method: 'get',
    params
  })
}

// 获取编委会
export function getSpecialistDetail (params) {
  return request({
    url: '/specialist/detail',
    method: 'get',
    params
  })
}
