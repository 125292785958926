import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import allImg from '@/assets/allImg.png';
import moment from 'moment';
import './index.css';

class CommonAllList extends Component {

	constructor(props) {
	    super(props);
		this.state = {

		}
	}

	render () {
		const { item } = this.props;
		return (
			<Fragment>
				<div className="commonAllListWrap clearfix">
					<NavLink   target="_blank"   to={`/microClassDetails/${item.id}`}>
						<div className="commonAllListContentLeft">
							<img src={item.cover ? item.cover.src : allImg}  />
						</div>
						<div className="commonAllListContentRight">
							<h1 className="commonAllListContentRightTitle">{item.title}</h1>
							<p className="commonAllListContentRightDesc">{item.desc}</p>
							<div className="commonAllListContentRightTag clearfix">
								<div className="commonAllListContentRightTagName">标签：{item.tags}</div>
								<div className="commonAllListContentRightTagTime">{moment(item.publish_time).format('YYYY/MM/DD')}</div>
							</div>
						</div>
					</NavLink>
				</div>
			</Fragment>
		)
	}
}

export default CommonAllList;
