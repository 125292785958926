import React, { Component, Fragment } from 'react';
import { NavLink } from "react-router-dom";
import { Pagination, Message } from 'element-react';
import E from 'wangeditor';

import CommunicateAllList from '@/components/communicateAllList';
import CommonToLogin from '@/components/commonToLogin';
import CommonPosition from '@/components/commonPosition';
import CommonWantPost from '@/components/commonWantPost';

import { getCommunicationList, communicationCreate } from '@/api/communicate';
import { getCatchaImg } from '@/api/login';

import './index.css';

const positionArr = [
	{
		name: '首页',
		path: '/home'
	},
	{
		name: '交流',
		path: '/communicate'
	}
]

class CommunicateDetail extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			// 图片验证码
			codeImg: '',
			// 图片验证码key
			key: '',
			title: '',
			titleFlag: false,
			content: '',
			code: '',
			codeFlag: false,
			postData: {
				page: 1,
				type: 1,
				start: 0,
				length: 20
			},
			articleList: '',
			articleListTotal: '',
			activeName: '1',
			activeTitle: '读片分享'
		}
	}

	componentDidMount () {
		const { pathname } = this.props.location;
		switch (pathname){
			case '/communicate/communicateReadShare':
				this.setState({
					postData: {
						page: 1,
						type: 1,
						start: 0,
						length: 20
					},
					activeName: '1',
					activeTitle: '读片分享'
				}, () => {
					this.initData();
				});
				break;
			case '/communicate/communicateCase':
				this.setState({
					postData: {
						page: 1,
						type: 2,
						start: 0,
						length: 20
					},
					activeName: '2',
					activeTitle: '精彩病例讨论'
				}, () => {
					this.initData();
				});
				break;
			case '/communicate/communicateInternational':
				this.setState({
					postData: {
						page: 1,
						type: 3,
						start: 0,
						length: 20
					},
					activeName: '3',
					activeTitle: '国际病理交流'
				}, () => {
					this.initData();
				});
				break;
			case '/communicate/communicateTroublesome':
				this.setState({
					postData: {
						page: 1,
						type: 4,
						start: 0,
						length: 20
					},
					activeName: '4',
					activeTitle: '疑难求助'
				}, () => {
					this.initData();
				});
				break;
			case '/communicate/communicatePractical':
				this.setState({
					postData: {
						page: 1,
						type: 5,
						start: 0,
						length: 20
					},
					activeName: '5',
					activeTitle: '实事讨论'
				}, () => {
					this.initData();
				});
				break;
			case '/communicate/communicateBoard':
				this.setState({
					postData: {
						page: 1,
						type: 6,
						start: 0,
						length: 20
					},
					activeName: '6',
					activeTitle: '公告板'
				}, () => {
					this.initData();
				});
				break;
		}
		const token = sessionStorage.getItem('token');
		if (token) {
			this.initEditor();
			this.initMobileEditor();
			this.initCatchaImg();
		}
	}

	// 快速发帖相关
	// 初始化图片验证码
	initCatchaImg () {
		getCatchaImg({}).then(res => {
			if (res.success) {
				const { img, key, sensitive } = res.data;
				this.setState({
					codeImg: img,
					key
				})
			}
		})
	}

	// 标题
	handleTitleInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				title: val,
				titleFlag: true
			}, () => {
				console.log(this.state.title)
			});
		} else {
			Message({
				message: '请填写标题',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		}
	}

	// 验证码失去焦点
	handleCodeInputBlur (event) {
		let val = event.target.value;
		if (val !== "") {
			this.setState({
				code: val,
				codeFlag: true
			}, () => {
				console.log(this.state.code)
			});
		} else {
			Message({
				message: '请填写验证码',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		}
	}

	// 发表按钮
	sendBtn () {
		this.setState({
			content: this.editor.txt.html()
		})
		const { title, titleFlag, content, code, codeFlag} = this.state;

		if (!content) {
			Message({
				message: '请填写要发表的内容',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		} else if (content && titleFlag && codeFlag) {
			communicationCreate({
				title,
				content,
				type: 1
			}).then(res => {
				if (res.success) {
					Message({
						message: '发表成功',
						type: 'success',
						showClose: true,
						duration: 5 * 1000
					})
				}
			})
		}
	}

	// 富文本
	initEditor () {
		const elem = this.refs.communicateDetailEditoElem
		const editor = new E(elem)
		this.editor = editor
		editor.customConfig.zIndex = 100
		editor.customConfig.uploadImgServer = 'http://api.remote.dakapath.com/platform/image/create'
		// 限制一次最多上传 1 张图片
		editor.customConfig.uploadImgMaxLength = 1
		editor.customConfig.customUploadImg = function (files, insert) {
		  // files 是 input 中选中的文件列表
		  console.log(files)
		  if (files[0]) {
			const formData = new window.FormData()
			formData.append('file', files[0], 'cover.jpg')
			fetch('http://api.remote.dakapath.com/platform/image/create', {
			  method: 'POST',
			  body: formData
			}).then((res) => {
			  return res.json()
			}).then((res) => {
			  const data = res.resultData
			  if (data) {
				// 上传代码返回结果之后，将图片插入到编辑器中
				insert(data.resourceUrl)
			  } else {
				console.log(data.msg)
			  }
			})
		  } else {
			  Message({
				message: '請選擇要上傳的圖片',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			  })
		  }
		}
		editor.customConfig.menus = [
		  'head', // 标题
		  'bold', // 粗体
		  'fontSize', // 字号
		  // 'fontName', // 字体
		  'italic', // 斜体
		  'underline', // 下划线
		  'strikeThrough', // 删除线
		  'foreColor', // 文字颜色
		  // 'backColor', // 背景颜色
		  'link', // 插入链接
		  'list', // 列表
		  'justify', // 对齐方式
		  'quote', // 引用
		  // 'emoticon', // 表情
		  'image', // 插入图片
		  // 'table', // 表格
		  // 'video', // 插入视频
		  // 'code', // 插入代码
		  'undo', // 撤销
		  'redo' // 重复
		]
		editor.customConfig.lang = {
		  '设置标题': 'Title',
		  '字号': 'Size',
		  '文字颜色': 'Color',
		  '设置列表': 'List',
		  '有序列表': '',
		  '无序列表': '',
		  '对齐方式': 'Align',
		  '靠左': '',
		  '居中': '',
		  '靠右': '',
		  '正文': 'p',
		  '链接文字': 'link text',
		  '链接': 'link',
		  '上传图片': 'Upload',
		  '网络图片': 'Web',
		  '图片link': 'image url',
		  '插入视频': 'Video',
		  '格式如': 'format',
		  '上传': 'Upload',
		  '创建': 'init'
		}
		editor.create()
	}

	// 富文本
	initMobileEditor () {
		const elem = this.refs.communicateDetailMobileEditoElem
		const editor = new E(elem)
		this.editor = editor
		editor.customConfig.zIndex = 100
		editor.customConfig.uploadImgServer = 'http://api.remote.dakapath.com/platform/image/create'
		// 限制一次最多上传 1 张图片
		editor.customConfig.uploadImgMaxLength = 1
		editor.customConfig.customUploadImg = function (files, insert) {
		  // files 是 input 中选中的文件列表
		  console.log(files)
		  if (files[0]) {
			const formData = new window.FormData()
			formData.append('file', files[0], 'cover.jpg')
			fetch('http://api.remote.dakapath.com/platform/image/create', {
			  method: 'POST',
			  body: formData
			}).then((res) => {
			  return res.json()
			}).then((res) => {
			  const data = res.resultData
			  if (data) {
				// 上传代码返回结果之后，将图片插入到编辑器中
				insert(data.resourceUrl)
			  } else {
				console.log(data.msg)
			  }
			})
		  } else {
			  Message({
				message: '請選擇要上傳的圖片',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			  })
		  }
		}
		editor.customConfig.menus = [
		  'head', // 标题
		  'bold', // 粗体
		  'fontSize', // 字号
		  // 'fontName', // 字体
		  'italic', // 斜体
		  'underline', // 下划线
		  'strikeThrough', // 删除线
		  'foreColor', // 文字颜色
		  // 'backColor', // 背景颜色
		  'link', // 插入链接
		  'list', // 列表
		  'justify', // 对齐方式
		  'quote', // 引用
		  // 'emoticon', // 表情
		  'image', // 插入图片
		  // 'table', // 表格
		  // 'video', // 插入视频
		  // 'code', // 插入代码
		  'undo', // 撤销
		  'redo' // 重复
		]
		editor.customConfig.lang = {
		  '设置标题': 'Title',
		  '字号': 'Size',
		  '文字颜色': 'Color',
		  '设置列表': 'List',
		  '有序列表': '',
		  '无序列表': '',
		  '对齐方式': 'Align',
		  '靠左': '',
		  '居中': '',
		  '靠右': '',
		  '正文': 'p',
		  '链接文字': 'link text',
		  '链接': 'link',
		  '上传图片': 'Upload',
		  '网络图片': 'Web',
		  '图片link': 'image url',
		  '插入视频': 'Video',
		  '格式如': 'format',
		  '上传': 'Upload',
		  '创建': 'init'
		}
		editor.create()
	}

	// 获取详情
	initPageList (activeNames) {
		if (activeNames === '1') {
			this.setState({
				postData: {
					page: 1,
					type: 1,
					start: 0,
					length: 20
				},
				activeName: '1',
				activeTitle: '读片分享'
			}, () => {
				this.initData();
			});
		} else if (activeNames === '2') {
			this.setState({
				postData: {
					page: 1,
					type: 2,
					start: 0,
					length: 20
				},
				activeName: '2',
				activeTitle: '精彩病例讨论'
			}, () => {
				this.initData();
			});
		} else if (activeNames === '3') {
			this.setState({
				postData: {
					page: 1,
					type: 3,
					start: 0,
					length: 20
				},
				activeName: '3',
				activeTitle: '国际病理交流'
			}, () => {
				this.initData();
			});
		} else if (activeNames === '4') {
			this.setState({
				postData: {
					page: 1,
					type: 4,
					start: 0,
					length: 20
				},
				activeName: '4',
				activeTitle: '疑难求助'
			}, () => {
				this.initData();
			});
		} else if (activeNames === '5') {
			this.setState({
				postData: {
					page: 1,
					type: 5,
					start: 0,
					length: 20
				},
				activeName: '5',
				activeTitle: '实事讨论'
			}, () => {
				this.initData();
			});
		} else if (activeNames === '6') {
			this.setState({
				postData: {
					page: 1,
					type: 6,
					start: 0,
					length: 20
				},
				activeName: '6',
				activeTitle: '公告板'
			}, () => {
				this.initData();
			});
		}
	}

	// 获取交流列表
	initData () {
		getCommunicationList(this.state.postData).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data,
					articleListTotal: res.data.total
				})
			}
		})
	}

	// 分页
	handleSizeChange (val) {
		const { type } = this.state.postData;
		this.setState({
			postData: {
				page: val,
				type,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initData();
		})
	}

	handleCurrentChange (val) {
		const { type } = this.state.postData;
		this.setState({
			postData: {
				page: val,
				type,
				start: (val - 1) * 20,
				length: 20
			}
		}, () => {
			this.initData();
		})
	}

	// 最新
	new () {
		this.initData()
	}

	// 热门
	hot () {
		this.initData('is_hot');
	}

	render () {
		const { pathname } = this.props.location;
		const { articleList, articleListTotal, postData, activeName, activeTitle, title, codeImg, code } = this.state;
		const token = sessionStorage.getItem('token');
		return (
			<Fragment>
				{/* pc端 */}
				<div className="communicateDetailWrap">
					<CommonPosition positionArr={positionArr} title={activeTitle}/>
					{/* 左部分内容 */}
					<div className="communicateDetailLeft">
						<div className="communicateDetailLeftFirst">
							<h1>板块导航</h1>
							<ul>
								<li className={activeName === '1' ? 'communicateDetailActive' : ''} onClick={this.initPageList.bind(this, '1')}>读片分享</li>
								<li className={activeName === '2' ? 'communicateDetailActive' : ''} onClick={this.initPageList.bind(this, '2')}>精彩病例讨论</li>
								<li className={activeName === '3' ? 'communicateDetailActive' : ''} onClick={this.initPageList.bind(this, '3')}>国际病理交流</li>
								<li className={activeName === '4' ? 'communicateDetailActive' : ''} onClick={this.initPageList.bind(this, '4')}>疑难求助</li>
								<li className={activeName === '5' ? 'communicateDetailActive' : ''} onClick={this.initPageList.bind(this, '5')}>实事讨论</li>
								<li className={activeName === '6' ? 'communicateDetailActive' : ''} onClick={this.initPageList.bind(this, '6')}>公告板</li>
							</ul>
						</div>
					</div>
					{/* 右部分内容 */}
					<div className="communicateDetailRight">
						<div className="communicateDetailRightFirst clearfix">
							<div className="communicateDetailRightFirstLeft">
								<h2>疑难求助</h2>
								<p>管理员：</p>
							</div>
							<div className="communicateDetailRightFirstRight">
								<NavLink   target="_blank"   to={token ? '/communicate/communicateReadShare' : '/login'}>
									我要发帖
								</NavLink>
							</div>
						</div>
						<div className="communicateDetailRightSecond">
							<ul className="communicateDetailRightSecondContentNav clearfix">
								<li onClick={this.new}>最新</li>
								<li onClick={this.hot}>热门</li>
								<li onClick={this.good}>精华</li>
								<li onClick={this.more}>更多</li>
							</ul>
							{
								articleList.length > 0 ? articleList.map((item, index) => {
									return <CommunicateAllList listItem={item} key={index} />
								}) : ''
							}
							<div className="communicateDetailRightSecondContentToPost">
								<CommonWantPost />
							</div>
							{
								articleList.length > 0 ? <div className="communicateDetailRightSecondContentPagination">
								<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
							</div> : ''
							}
						</div>
						{
							!token ? <div className="communicateDetailRightThird">
							<CommonToLogin {...this.props} title="快速发帖" />
						</div> : <div className="communicateDetailRightThird">
							<h1>快速发帖</h1>
							<div className="communicateDetailRightThirdEditor">
								<div className="communicateDetailRightThirdEditorTitle clearfix">
									<input type="text" defaultValue={title} onBlur={this.handleTitleInputBlur.bind(this)}/>
									<p>还可以输入80个字符</p>
								</div>
								<div ref='communicateDetailEditoElem' style={{ textAlign: 'left', fontSize: '.16rem' }} />
								<div className="communicateDetailRightThirdCode clearfix">
									<p className="communicateDetailRightThirdTest">验证码</p>
									<input autoComplete="off" type="text" name="code" defaultValue={code} onBlur={this.handleCodeInputBlur.bind(this)}/>
									<div className="communicateDetailRightThirdCodeImg" onClick={this.initCatchaImg.bind(this)}>
										<img src={codeImg} alt="codeImg" />
									</div>
									<p className="communicateDetailRightThirdCodeChange" onClick={this.initCatchaImg.bind(this)}>换一个</p>
								</div>
								<div className="communicateDetailRightThirdBottom clearfix">
									<input type="submit" value="我要发表" onClick={this.sendBtn.bind(this)}/>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
				{/* 移动端 */}
				<div className="communicateDetailMobileWrap">
					<CommonPosition positionArr={positionArr} title={activeTitle}/>
					{/* 左部分内容 */}
					<div className="communicateDetailMobileLeft">
						<div className="communicateDetailMobileLeftFirst">
							<h1>板块导航</h1>
							<ul className="clearfix">
								<li className={activeName === '1' ? 'communicateDetailMobileActive' : ''} onClick={this.initPageList.bind(this, '1')}>读片分享</li>
								<li className={activeName === '2' ? 'communicateDetailMobileActive' : ''} onClick={this.initPageList.bind(this, '2')}>精彩病例讨论</li>
								<li className={activeName === '3' ? 'communicateDetailMobileActive' : ''} onClick={this.initPageList.bind(this, '3')}>国际病理交流</li>
								<li className={activeName === '4' ? 'communicateDetailMobileActive' : ''} onClick={this.initPageList.bind(this, '4')}>疑难求助</li>
								<li className={activeName === '5' ? 'communicateDetailMobileActive' : ''} onClick={this.initPageList.bind(this, '5')}>实事讨论</li>
								<li className={activeName === '6' ? 'communicateDetailMobileActive' : ''} onClick={this.initPageList.bind(this, '6')}>公告板</li>
							</ul>
						</div>
					</div>
					{/* 右部分内容 */}
					<div className="communicateDetailMobileRight">
						<div className="communicateDetailMobileRightFirst clearfix">
							<div className="communicateDetailMobileRightFirstLeft">
								<h2>疑难求助</h2>
								<p>管理员：</p>
							</div>
							<div className="communicateDetailMobileRightFirstRight">
								<NavLink   target="_blank"   to={token ? '/communicate/communicateReadShare' : '/login'}>
									我要发帖
								</NavLink>
							</div>
						</div>
						<div className="communicateDetailMobileRightSecond">
							<ul className="communicateDetailMobileRightSecondContentNav clearfix">
								<li onClick={this.new}>最新</li>
								<li onClick={this.hot}>热门</li>
								<li onClick={this.good}>精华</li>
								<li onClick={this.more}>更多</li>
							</ul>
							{
								articleList.length > 0 ? articleList.map((item, index) => {
									return <CommunicateAllList listItem={item} key={index} />
								}) : ''
							}
							<div className="communicateDetailMobileRightSecondContentToPost">
								<CommonWantPost />
							</div>
							{
								articleList.length > 0 ? <div className="communicateDetailMobileRightSecondContentPagination">
								<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={articleListTotal}   />
							</div> : ''
							}
						</div>
						{
							!token ? <div className="communicateDetailMobileRightThird">
							<CommonToLogin {...this.props} title="快速发帖" />
						</div> : <div className="communicateDetailMobileRightThird">
							<h1>快速发帖</h1>
							<div className="communicateDetailMobileRightThirdEditor">
								<div className="communicateDetailMobileRightThirdEditorTitle clearfix">
									<input type="text" defaultValue={title} onBlur={this.handleTitleInputBlur.bind(this)}/>
									<p>还可以输入80个字符</p>
								</div>
								<div ref='communicateDetailMobileEditoElem' style={{ overflow: 'hidden', textAlign: 'left', fontSize: '.16rem' }} />
								<div className="communicateDetailMobileRightThirdCode clearfix">
									<p className="communicateDetailMobileRightThirdTest">验证码</p>
									<input autoComplete="off" type="text" name="code" defaultValue={code} onBlur={this.handleCodeInputBlur.bind(this)}/>
									<div className="communicateDetailMobileRightThirdCodeImg" onClick={this.initCatchaImg.bind(this)}>
										<img src={codeImg} alt="codeImg" />
									</div>
									<p className="communicateDetailMobileRightThirdCodeChange" onClick={this.initCatchaImg.bind(this)}>换一个</p>
								</div>
								<div className="communicateDetailMobileRightThirdBottom clearfix">
									<input type="submit" value="我要发表" onClick={this.sendBtn.bind(this)}/>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CommunicateDetail;
