import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// 消息提示组件
import { Message } from 'element-react';
// 格式化时间
import moment from 'moment';

import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';
import CommonPosition from '@/components/commonPosition';
import CommonTitle from '@/components/commonTitle';
import CommonMicroClassDetailsList from '@/components/commonMicroClassDetailsList';

import allBottomImg from '@/assets/allBottomImg.jpg';
import loginIcon from '@/assets/LoginIcon.png';
import close from '@/assets/close.png';

// 获取文章列表/获取文章详情/文章收藏接口
import { getArticleList, getArticleListDetail, articleCollection, articleComment, articleOpinion } from '@/api/microClass';

import './index.css';

class comDetails extends Component {

	constructor(props) {
		super(props);

		this.state = {
			articleList: '',
			detail: '',
			commonsList: [],
			commonsListTotal: 0,
			commonContent: '',
			errorContent: '',
			showMask: false,
			positionArr: [
				{
					name: '首页',
					url: '/home'
				}
			]
		}
	}

	componentDidMount () {
		const { id } = this.props.match.params;
		this.initGetDetail(id);
		this.initArticleList();
	}

	// 收藏文章
	collectArticle () {
		const { id } = this.props.match.params;
		articleCollection({
			article_id: id
		}).then(res => {
			if (res.success) {
				Message({
					message: '收藏成功',
					type: 'success',
					showClose: true,
					duration: 5 * 1000
				})
			}
		})
	}

	initArticleList () {
		getArticleList({
			category_id : 4,
			length: 6
		}).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data
				})
			}
		})
	}

	// 获取详情
	initGetDetail (id) {
		const { positionArr } = this.state;

		getArticleListDetail({
			id
		}).then(res => {
			positionArr.push(res.data.category.parent.path)
			positionArr.push(res.data.category.path)

			this.setState({
				detail: res.data,
				commonsList: res.data.comments,
				positionArr: positionArr
			})
		})
	}

	toArticleDetail (id) {
		this.initGetDetail(id);
		this.props.history.push(`/microClassDetails/${id}`);
	}

	// 退出登录
	loginOut () {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('userInfo');
		this.props.history.push('/login');
	}

	// 评论内容失去焦点
	handleArea (event) {
		let val = event.target.value;
		this.setState({
			commonContent: val
		})
	}

	// 点击发表评论
	sendComments (event) {
		event.preventDefault();
		const { commonContent } = this.state;
		const { id } = this.props.match.params;
		if (commonContent === '') {
			Message({
				message: '评论内容不能为空',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		} else {
			articleComment({
				article_id: id,
				reply_id: '',
				content: commonContent
			}).then(res => {
				if (res.success) {
					Message({
						message: '发表成功',
						type: 'success',
						showClose: true,
						duration: 5 * 1000
					})
				}
				this.setState({
					commonContent: ''
				})
				this.initGetDetail(id)
			})
		}
	}

	handleAreaError (event) {
		event.stopPropagation();
		let val = event.target.value;
		this.setState({
			errorContent: val
		})
	}

	sendError (event) {
		event.preventDefault();
		event.stopPropagation();
		const { errorContent } = this.state;
		const { id } = this.props.match.params;
		if (errorContent === '') {
			Message({
				message: '纠错内容不能为空',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
		} else {
			articleOpinion({
				article_id: id,
				content: errorContent
			}).then(res => {
				if (res.success) {
					Message({
						message: '纠错成功',
						type: 'success',
						showClose: true,
						duration: 5 * 1000
					})
					this.setState({
						showMask: false,
						errorContent: ''
					})
				}
			})
		}
	}

	// 关闭弹窗
	hideMask (event) {
		event.stopPropagation();
		this.setState({
			showMask: false
		})
	}

	showMask () {
		if (sessionStorage.getItem('token') == null) {
			Message({
				message: '未登录',
				type: 'error',
				showClose: true,
				duration: 5 * 1000
			})
			return false;
		}

		this.setState({
			showMask: true
		})
	}

	render () {
		const { articleList, detail, commonContent, commonsList, errorContent, showMask, positionArr } = this.state;
		const { attrs } = detail;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				<div className="microClassDetailsWrap clearfix">
					<div className="microClassDetailsContentLeft">
						<CommonPosition positionArr={positionArr} title={detail.title}/>
						<div className="microClassDetailsContentLeftFirst">
							<h1 className="microClassDetailsContentLeftFirstTitle">{detail.title}</h1>
							<ul className="microClassDetailsContentLeftFirstBottom clearfix">
								<li>{moment(detail.publish_time).format('YYYY-MM-DD')}</li>
								{
									detail.form &&	<li>来源：{detail.form}</li>
								}

								{
									detail.author && <li>作者: {detail.author}</li>
								}
								<li>阅读量：{detail.view_num}</li>
								<li onClick={this.collectArticle.bind(this)}>[<span>收藏</span>]</li>
							</ul>
							{
								detail.tmp_id && <div className="microClassDetailsContentLeftFirstIntroduction clearfix">
									<div className="microClassDetailsContentLeftFirstIntroductionLeft">导读</div>
									<div className="microClassDetailsContentLeftFirstIntroductionRight" dangerouslySetInnerHTML={{__html: detail.tmp.content}}></div>
								</div>
							}
							{
								attrs ? attrs.length > 0 ? attrs.map((item, index) => {
									return (
										<div key={item.id} className="microClassDetailsContentLeftSecond" dangerouslySetInnerHTML={{ __html: item.val}}></div>
									)
								}) : '' : ''
							}
							<div className="microClassDetailsContentLeftThird">
								<h2 className="microClassDetailsContentLeftThirdTitle">版权申明：</h2>
								<p className="microClassDetailsContentLeftThirdText">本网站所有注明“来源：大伽病理•精准国际病理诊断网”的文字、图片及音视频资料，版权均归大伽病理•精准国际病理诊断网所有，欢迎转载，但请务必注明出处“大伽病理•精准国际病理诊断网”，否则将追究法律责任。本网站所有注明来源为其他媒体的内容仅处于传递更多信息目的，版权归原作者所有，不希望被转载的媒体或个人，请与我们联系，我们将立刻进行删除处理。</p>
								<div className="microClassDetailsContentLeftThirdShare">
									<div className="bshare-custom icon-medium-plus">
										<div className="bsPromo bsPromo2"></div>
										<a title="分享到QQ空间" className="bshare-qzone"></a>
										<a title="分享到新浪微博" className="bshare-sinaminiblog"></a>
										<a title="分享到人人网" className="bshare-renren"></a>
										<a title="分享到腾讯微博" className="bshare-qqmb"></a>
										<a title="分享到网易微博" className="bshare-neteasemb"></a>
										<a title="更多平台" className="bshare-more bshare-more-icon more-style-addthis"></a>
									</div>
								</div>
							</div>
							{
								!token ? <div className="microClassDetailsContentLeftFour">
									<h2 className="microClassDetailsContentLeftFourTitle">精彩评论：	<span>{commonsList.length}条</span></h2>
									<div className="microClassDetailsContentLeftFourToLogin">您还没有登录，请先<NavLink   target="_blank"   to="/login">登录</NavLink></div>
								</div> : <div className="microClassDetailsContentLeftFour">
									<h2 className="microClassDetailsContentLeftFourTitle">精彩评论：	<span>{commonsList.length}条</span></h2>
									<form className="microClassDetailsContentLeftFourForm">
										<div className="microClassDetailsContentLeftFourFormInfo clearfix">
											<span>{userInfo.realname}</span><i>|</i><span onClick={this.loginOut.bind(this)}>退出</span>
										</div>
										<div className="microClassDetailsContentLeftFourFormAreaWrap clearfix">
											<div className="microClassDetailsContentLeftFourFormAreaLogo">
												<img src={loginIcon} alt="loginIcon" />
											</div>
											<textarea className="microClassDetailsContentLeftFourFormAreaArea" name="commonContent" placeholder="随便说点吧" defaultValue={commonContent} onBlur={this.handleArea.bind(this)}></textarea>
										</div>
										<div className="microClassDetailsContentLeftFourFormAreaBtn clearfix">
											<button onClick={this.sendComments.bind(this)}>评论</button>
										</div>
									</form>
								</div>
							}
							<ul className="microClassDetailsContentLeftFourFormCommonList">
								{
									commonsList.length > 0 ? commonsList.map((item, index) => {

										return (
											<li key={item.id} className="clearfix">
												<div className="microClassDetailsContentLeftFourFormCommonListHead">
													<img src={item.user ? (item.user.avatar !== null ? item.user.avatar : loginIcon ): loginIcon} />
												</div>
												<div className="microClassDetailsContentLeftFourFormCommonListWord">
													<h2>{item.user ? item.user.realname : '未知用户'}</h2>
													<h3>{moment(item.created_at).format('YYYY-MM-DD')}</h3>
													<p>{item.content}</p>
												</div>
											</li>
										)
									}) : ''
								}
							</ul>
						</div>
					</div>
					<div className="microClassDetailsContentRight">
						<div className="microClassDetailsContentRightFirst">
							<CommonTitle title="推荐文章" />
							<div className="microClassDetailsContentRightFirstContent">
								{
									articleList.length > 0 ? articleList.map((item, index) => {
										return (
											<div className="microClassDetailsContentRightFirstContentList clearfix" onClick={this.toArticleDetail.bind(this, item.id)} key={item.id}>
												<div className="microClassDetailsContentRightFirstContentListLeft">
													<img src={item.cover ? item.cover.src : 'cover.jpg'} alt="myright" />
												</div>
												<p className="microClassDetailsContentRightFirstContentListRight">{item.title}</p>
											</div>
										)
									}) : ''
								}
							</div>
						</div>
						<div className="microClassDetailsContentRightSecond">
							<img src={allBottomImg} alt="allBottomImg" />
						</div>
					</div>
				</div>
				<CommonFooter />
				<div className="microClassDetailsFixBtn" onClick={this.showMask.bind(this)}>
					纠错
				</div>
				{
					showMask && <div className="microClassDetailsShadow" >
						<div className="microClassDetailsShadowContent">
							<div className="microClassDetailsShadowContentTitle clearfix">
								<h4>请填写纠错内容</h4>
								<div className="microClassDetailsShadowContentTitleClose">
									<img src={close} onClick={this.hideMask.bind(this)} />
								</div>
							</div>
							<form className="microClassDetailsShadowContentForm">
								<div className="microClassDetailsShadowContentFormInfo clearfix">
									<span>{userInfo ? userInfo.realname : '未登录'}</span>
								</div>
								<div className="microClassDetailsShadowContentFormAreaWrap clearfix">
									<div className="microClassDetailsShadowContentFormAreaLogo">
										<img src={loginIcon} alt="loginIcon" />
									</div>
									<textarea className="microClassDetailsShadowContentFormAreaArea" name="errorContent" placeholder="随便说点吧" defaultValue={errorContent} onBlur={this.handleAreaError.bind(this)}></textarea>
								</div>
								<div className="microClassDetailsShadowContentFormAreaBtn clearfix">
									<button onClick={this.sendError.bind(this)}>纠错</button>
								</div>
							</form>
						</div>
					</div>
				}
			</Fragment>
		)
	}
}

export default comDetails;
