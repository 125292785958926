export function setData(key, value, pastTime) {
	let currentTime = new Date().getTime()
	// // 设置过期时间 (7天)
	// let pastTime = 7 * 24 * 60 * 60 * 1000
	localStorage.setItem(key, JSON.stringify({
		data: value,
		time: currentTime + pastTime
	}))
}

export function getData(key) {
	let dataObj = JSON.parse(localStorage.getItem(key));
	if (dataObj) {
		if (new Date().getTime() > dataObj.time) {
			return null;
		} else {
			return dataObj.data
		}
	}
}

export function removeData(key) {
  localStorage.removeItem(key)
}