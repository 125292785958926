import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import './index.css';

class CommonSpecialTopicList extends Component {
	
	constructor(props) {
	    super(props);
		this.state = {
			
		}
	}
	
	render () {
		const { item } = this.props;
		return (
			<Fragment>
				<div className="commonSpecialTopicListWrap">
					<NavLink   target="_blank"   to={`/microClassDetails/${item.id}`} className="clearfix">
						<div className="commonSpecialTopicListContentLeft">
							<img src={item.cover ? item.cover.src : ''} />
						</div>
						<div className="commonSpecialTopicListContentRight">
							<h2>{item.title}</h2>
							<p>{item.desc}</p>
						</div>
					</NavLink>
				</div>
			</Fragment>
		)
	}
}

export default CommonSpecialTopicList;
