import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
// 获取轮播和分页组件
import { Carousel, Pagination } from 'element-react';
import moment from 'moment';

// 组件
import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';
import CommonMore from '@/components/commonMore';

// 图片
import taglogo from '@/assets/taglogo.jpg';
import index_17 from '@/assets/index_17.jpg';
import homerightimg1 from '@/assets/homerightimg1.jpg';
import index18 from '@/assets/index18.jpg';

// 获取文章列表接口
import { getArticleList } from '@/api/microClass.js';
// 获取banner接口
import { getBanner } from '@/api/common.js';
// 获取交流列表
import { getCommunicationList } from '@/api/communicate.js';

import './index.css';

class Home extends Component {

	constructor (props) {
		super(props);
		this.carousel = React.createRef();
		this.state = {
			bannerIndex: 1,
			isAutoplay: true,
			bannerList: [],// 获取轮播
			microClassList: [],// 微课堂
			microClassHotList: [],// 热门资讯
			academicList: [],// 推荐 学术
			communicationList: [],// 病例讨论 交流
			meetingsList: [],// 会议,
			micronId: '',
			hotId:'',
			postData: {
				page: 1,
				start: 0,
				length: 20,
				is_hot: 1
			},
			hotList: [],
			hotTotal: ''
		}
	}

	componentDidMount () {
		// 获取轮播
		this.initBanner();
		// 微课堂
		this.initMicroClassList();
		// 热门资讯
		this.initMicroClassHotList();
		// 推荐 学术
		this.initAcademicList();
		// 病例讨论 交流
		this.initCommunicationList();
		// 会议
		this.initMeetingsList();
		// 热门资讯列表
		this.initHotList();
	}
	// 热门资讯列表
	initHotList () {
		getArticleList(this.state.postData).then(res => {
			this.setState({
				hotList: res.data.data,
				hotTotal: res.data.total
			})
		})
	}

	// 获取轮播
	initBanner () {
		getBanner({
			type: 1
		}).then(res => {
			if (res.success) {
				this.setState({
					bannerList: res.data
				})
			}
		})
	}

	// 微课堂
	initMicroClassList () {
		getArticleList({
			category_id: 4,
			length: 11
		}).then(res => {
			this.setState({
				microClassList: res.data.data,
				micronId: res.data.data[0].id,
			})
		})
	}

	// 热门资讯
	initMicroClassHotList () {
		getArticleList({
			category_id: 48,
			is_hot: 1,
			length: 4
		}).then(res => {
			this.setState({
				microClassHotList: res.data.data,
				hotId: res.data.data[0].id
			})
		})
	}

	// 推荐 学术
	initAcademicList () {
		getArticleList({
			category_id: 52,
			length: 6
		}).then(res => {
			this.setState({
				academicList: res.data.data
			})
		})
	}

	// 病例讨论 交流
	initCommunicationList () {
		getCommunicationList({
			length: 6
		}).then(res => {
			this.setState({
				communicationList: res.data.data
			})
		})
	}

	// 会议
	initMeetingsList () {
		getArticleList({
			category_id: 58,
			length: 3,
			order: 'index'
		}).then(res => {
			this.setState({
				meetingsList: res.data.data
			})
		})
	}

	// 轮播改变的时候
	onCarouselChange (index) {
		this.setState({
			bannerIndex: index
		});
	}

	// 鼠标经过圆点
	onMouseOver (index) {
		this.setState({
			bannerIndex: index
		});
		this.carousel.current.setActiveItem(index);
	}

	// 跳转路径
	toPath (pathName) {
		this.props.history.push(pathName)
	}

	render () {
		const { bannerList, bannerIndex, isAutoplay, microClassList, microClassHotList, academicList, communicationList, meetingsList, postData, hotList, hotTotal, micronId, hotId} = this.state;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		const  styleObj = {
			float: 'left',
		//	width: '4.16rem',
			// fontSize: '.22rem',
			// lineHeight: '.45rem',
			// overflow: 'hidden',
			// position: 'absolute',
			// left: 0,
			// bottom: 0,
			// color: 'white',
			margin: '0 0 0 .2rem',
		}

		const pback = {
			background: 'rgba(0, 0, 0, .5)',
			width: '100%',
			float: 'left',
			fontSize: '.22rem',
			lineHeight: '.45rem',
			overflow: 'hidden',
			position: 'absolute',
			left: 0,
			bottom: 0,
			color: 'white',
		}

		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props}  />
				<div className="homeWrap">
					<div className="homeFirst clearfix">
						<div className="homeFirstLeft">首页—欢迎访问大伽病理•精准国际病理诊断网</div>
						<div className="homeFirstRight clearfix">
							<div className="homeFirstRightTag">
								热门标签
								<i></i>
							</div>
							<ul className="homeFirstRightTagList clearfix">
								<li>
									<NavLink   target="_blank"   to="/search/肾脏肿瘤新分类">肾脏肿瘤新分类</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/急性骨髓性白血病">急性骨髓性白血病</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/消化道肿瘤">消化道肿瘤</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/精准医学">精准医学</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/穿刺">穿刺</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/慢性淋巴性白血病">慢性淋巴性白血病</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/PD-1">PD-1</NavLink>
								</li>
							</ul>
						</div>
					</div>
					<div className="homeSecond clearfix">
						<div className="homeSecondLeft">
							<Carousel height="3.32rem" autoplay={isAutoplay} indicatorPosition="none" onChange={this.onCarouselChange.bind(this)} ref={this.carousel}>
								{
									bannerList.map((item, index) => {
										return (
											<Carousel.Item key={item.id}>
												<a href={item.link} >
													<img src={item.image ? item.image.src : ''}  />
													<div style={pback}>
														<p style={styleObj}>{item.title}</p>
													</div>
												</a>

											</Carousel.Item>
										)
									})
								}
							</Carousel>
							<div className="homeSecondLeftBannerBottom clearfix">
								<p></p>
								<ul className="homeSecondLeftBannerBottomContent clearfix">
									{
										bannerList.map((item, index) => {
											if (index == 1) {
												return null;
											} else {
												return (
													<li key={index}
														className={bannerIndex === index ? 'bannerActiveCircle' : ''}
														onMouseOver={this.onMouseOver.bind(this, index)}>
													</li>
												)
											}
										})
									}
								</ul>
							</div>
						</div>
						<div className="homeSecondRight">
							<ul className="homeSecondRightList clearfix">
								<li className="homeSecondRightListFirst">
									<NavLink   target="_blank"   to="/microClass/correctionTable">
										课件/纠错台
									</NavLink>
								</li>
								<li className="homeSecondRightListSecond">
									<NavLink   target="_blank"   to="/microClass/guess">
										猜猜看 | 搜一搜
									</NavLink>
								</li>
								<li>
									<a href="http://dia.dakapath.com/">
										病理信息查询
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/diagnosis/category.html">
										病理诊断与鉴别诊断
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/article/immunebx.html">
										肿瘤免疫表型介绍
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/article/gross.html">
										规范化病理诊断
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/article/tnm.html">
										2017版肿瘤TNM分期
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/article/tumourwho.html">
										肿瘤WHO分类
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/article/immunezh.html">
										免疫组化抗体介绍
									</a>
								</li>
								<li>
									<a href="http://dia.dakapath.com/home/article/immunepersonal.html">
										肿瘤个体化治疗
									</a>
								</li>
								<li className="homeSecondRightListLast">
									<img src={taglogo} alt="" />
								</li>
							</ul>
						</div>
					</div>
					<div className="homeThird clearfix">
						<div className="homeThirdLeft">
							<div className="homeThirdLeftFirst">
								<CommonTitle title="病理微课堂" showRightIcon={true} toPath="/microClass/micropathology"/>
								<div className="homeThirdLeftFirstMenInduct clearfix">

									<NavLink   target="_blank"   to={`/microClassDetails/${micronId}`}>
										<div className="homeThirdLeftFirstMenInductLeft">
											<img src={microClassList.length > 0 ? microClassList[0].tmp ? microClassList[0].tmp.avatar : '' : ''}  />
										</div>
										<div className="homeThirdLeftFirstMenInductRight">
											<h1>{microClassList.length > 0 ? microClassList[0].title : ''}</h1>
											<p>{microClassList.length > 0 ? microClassList[0].desc : ''}</p>
										</div>
									</NavLink>
								</div>
								<div className="homeThirdLeftFirstContent">
									{
										microClassList.length > 0 ? microClassList.map((item, index) => {
											if (index == 0) {
												return '';
											}

											return (
												<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props}/>
											)
										}) : ''
									}
									<div className="homeThirdLeftFirstContentMore">
										<CommonMore text="更多" toPath="/microClass/micropathology" />
									</div>
								</div>
								<div className="homeThirdLeftSecond">
									<CommonTitle title="热门资讯" showRightIcon={true} toPath="/information/all" />
									<div className="homeThirdLeftFirstMenInduct clearfix">

										<NavLink   target="_blank"   to={`/microClassDetails/${hotId}`}>

											<div className="homeThirdLeftFirstMenInductLeft">
												<img src={microClassHotList.length > 0 ? microClassHotList[0].cover ? microClassHotList[0].cover.src : '' : ''}  />
											</div>
											<div className="homeThirdLeftFirstMenInductRight">
												<h1>{microClassHotList.length > 0 ? microClassHotList[0].title : ''}</h1>
												<p>{microClassHotList.length > 0 ? microClassHotList[0].desc : ''}</p>
											</div>
										</NavLink>
									</div>
									<div className="homeThirdLeftSecondContentList">
										{
											microClassHotList.length > 0 ? microClassHotList.map((item, index) => {
												if (index == 0) {
													return ''
												}

												return (
													<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props}/>
												)
											}) : ''
										}
										<div className="homeThirdLeftFirstContentMore">
											<CommonMore text="更多" toPath="/information" />
										</div>
									</div>
								</div>
								<div className="homeThirdLeftThird">
									<div className="homeTitleWrap clearfix">
										<div className="homeTitleLeft"></div>
										<h1 className="homeTitleMiddle"><span className="homeTitleActive" onClick={this.toPath.bind(this, '/find/meeting')} >会议</span>&nbsp;|&nbsp;<span onClick={this.toPath.bind(this, '/find/specialTopic')}>专题</span></h1>
										<div className="homeTitleRight" onClick={this.toPath.bind(this, '/find/meeting')}></div>
									</div>
									<div className="homeThirdLeftThirdContent">
										<div className="homeThirdLeftThirdContentImgOne">
											<NavLink   target="_blank"   to={`/microClassDetails/${meetingsList[0] ? meetingsList[0].id : ''}`}>
												<img src={meetingsList[0] ? meetingsList[0].cover.src : ''} />
											</NavLink>
										</div>
										<div className="homeThirdLeftThirdContentList">
											{
												meetingsList.length > 0 ? meetingsList.map((item, index) => {
													return (
														<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props}/>
													)
												}) : ''
											}
											<div className="homeThirdLeftFirstContentMore">
												<CommonMore text="更多" toPath="/find" />
											</div>
										</div>
										<div className="homeThirdLeftThirdContentImgTwo">
											<img src="http://dia.dakapath.com/Public/static/media/index_17.f182e9eb.jpg" />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="homeThirdRight">
							<div className="homeThirdRightFirst clearfix">
								<div className="homeThirdRightFirstLeft">
									<a href="https://mp.weixin.qq.com/s/zoBkO2pKxRh6s0svFFbLUw?">
										<img src="http://dia.dakapath.com/Public/static/media/homerightimg1.200e48af.jpg"  />
									</a>
								</div>
								<div className="homeThirdRightFirstRight">
									<a href="http://www.dakapath.com/news/qiye/1535610101.html">
										<img src="http://api.remote.dakapath.com/uploads/image/20180204/15/cb4e79361f5886d60b5c17468fc8acb3.jpg" />
									</a>
								</div>
							</div>
							<div className="homeThirdRightSecond">
								<CommonTitle title="病理诊断与鉴别诊断" showRightIcon={true}  url="http://dia.dakapath.com/home/diagnosis/category.html"/>
								<ul className="homeThirdRightSecondContent clearfix">
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/227.html">膀胱、输尿管、尿道</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/360.html">鼻窦、鼻腔</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/412.htmlhttp://dia.dakapath.com/home/diagnosis/catelist/id/431.html">垂体及鞍区肿瘤</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/217.html">胆囊及肝外胆管</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/397.html">耳</a>
                                    </li>
									<li>
										<a href="http://dia.dakapath.com/home/diagnosis/catelist/id/136.html">肺</a>
									</li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/213.html">肝及肝内胆管</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/315.html">肛管</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/259.html">睾丸</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/439.html">骨</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/366.html">喉</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/92.html">甲状旁腺</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/88.html">甲状腺</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/206.html">间皮病变</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/308.html">结直肠</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/253.html">精囊腺</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/376.html">口腔</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/264.html">阑尾</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/236.html">淋巴造血</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/93.html">卵巢</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/320.html">皮肤非肿瘤性病变</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/321.html">皮肤肿瘤</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/247.html">前列腺</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/61.html">乳腺</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/52.html">软组织</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/555.html">肾病</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/584.html">肾上腺</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/232.html">肾脏</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/294.html">食管</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/435.html">输卵管</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/301.html">胃</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/403.html">胃肠共有疾病</a>
                                    </li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/431.html">涎腺</a>
                                    </li>

                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/305.html">小肠</a>
                                    </li>
                                    <li>
										<a href="http://dia.dakapath.com/home/diagnosis/catelist/id/535.html">胸腺及纵膈</a>
									</li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/382.html">咽、鼻咽</a>
                                    </li>
									<li>
										<a href="http://dia.dakapath.com/home/diagnosis/catelist/id/389.html">胰腺</a>
									</li>
                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/506.html">阴道</a>
                                    </li>
									<li>
										<a href="http://dia.dakapath.com/home/diagnosis/catelist/id/532.html">阴茎</a>
									</li>

                                    <li>
                                        <a href="http://dia.dakapath.com/home/diagnosis/catelist/id/408.html">中枢神经系统</a>
                                    </li>
									<li>
										<a href="http://dia.dakapath.com/home/diagnosis/catelist/id/140.html">子宫颈</a>
									</li>
									<li>
										<a href="http://dia.dakapath.com/home/diagnosis/catelist/id/159.html">子宫体</a>
									</li>

								</ul>
							</div>
							<div className="homeThirdRightThird clearfix">
								<div className="homeThirdRightThirdLeft">
									<CommonTitle title="免疫组化应用抗体" showRightIcon={true} url="http://dia.dakapath.com/home/article/immunezh.html" />
									<ul className="homeThirdRightThirdLeftContent clearfix">
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/290.html">A-B</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/289.html">CD系列</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/288.html">CK系列</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/287.html">C-F</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/286.html">G-K</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/285.html">L-N</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/284.html">O-R</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/283.html">S-T</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/immunezhcate/id/282.html">U-Z及数字开头</a>
										</li>
									</ul>
									<div className="homeThirdRightThirdLeftContentImg">
										<a href="https://www.uscap.org/meetings/detail/2018-annual-meeting">
											<img src="http://api.remote.dakapath.com/uploads/image/20180204/14/1.jpg"  />
										</a>
									</div>
								</div>
								<div className="homeThirdRightThirdRight">
									<CommonTitle title="肿瘤WHO分类" showRightIcon={true} url="http://dia.dakapath.com/home/article/tumourwho.html"/>
									<ul className="homeThirdRightThirdRightContent clearfix">
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/495.html">骨及软组织</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/505.html">内分泌</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/504.html">肺与纵膈</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/503.html">乳腺</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/502.html">消化系统</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/501.html">泌尿和男性生殖</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/500.html">女性生殖</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/499.html">头颈</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/498.html">中枢神经</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/497.html">皮肤</a>
										</li>
										<li>
											<a href="http://dia.dakapath.com/home/article/tumourwhocate/id/517.html">淋巴造血</a>
										</li>
									</ul>
									<div className="homeThirdRightThirdRightContentImg">
										<a href="http://www.cmlabs.com.cn/">
											<img src="http://api.remote.dakapath.com/uploads/image/20190225/11/725f14b79984e608092088d38ecf4c3b.jpg"  />
										</a>
									</div>
								</div>
							</div>
							<div className="homeThirdRightFour">
								<CommonTitle title="病理信息查询" showRightIcon={true} url="http://dia.dakapath.com/home/index/index.html" />
								<div className="homeThirdRightFourContent">
									<ul className="commonHomeListWrap clearfix">
										<li>
											<h1>由病理信息查询肿瘤</h1>
											<p>输入相应的关键词，查找具有相应特征的肿瘤（或疾病），查看其诊断要点及鉴别诊。</p>
											<a href="http://dia.dakapath.com/home/search/one.html">点击查询</a>
										</li>
										<li>
											<h1>肿瘤免疫组化表型查询</h1>
											<p>输入肿瘤名称，查看该肿瘤的免疫表型特征。</p>
											<a href="http://dia.dakapath.com/home/search/two.html">点击查询</a>
										</li>
										<li>
											<h1>免疫组化抗体特征查询</h1>
											<p>输入抗体名称，查看该抗体在病理诊断中的应用及在肿瘤中的表达情况。</p>
											<a href="http://dia.dakapath.com/home/search/three.html">点击查询</a>
										</li>
										<li>
											<h1>肿瘤鉴别诊断抗体组合推荐</h1>
											<p>输入需要鉴别的肿瘤名称，查看该组肿瘤免疫表型差异，从中可挑选抗体将它们鉴别开来。</p>
											<a href="http://dia.dakapath.com/home/search/four.html">点击查询</a>
										</li>
										<li>
											<h1>特定肿瘤特定抗原表达查询</h1>
											<p>输入特定的肿瘤及个人感兴趣的抗体，查看该组肿瘤相应抗原表达情况。</p>
											<a href="http://dia.dakapath.com/home/search/five.html">点击查询</a>
										</li>
										<li>
											<h1>由免疫组化结果推测肿瘤诊断</h1>
											<p>由免疫组化结果推测肿瘤诊断输入免疫组化染色项目，选择染色结果，查看可能的诊断。</p>
											<a href="http://dia.dakapath.com/home/search/six.html">点击查询</a>
										</li>
										<li>
											<h1>形态与免疫组化联合查询</h1>
											<p>输入发病部位、形态结构、所属类型，再输入免疫组化染色结果，查询可能的诊断</p>
											<a href="http://dia.dakapath.com/home/search/seven.html">点击查询</a>
										</li>
										<li className="CommonHomeListLast">
											<NavLink   target="_blank"   to="/information">
												<img src={index18} alt="index18" />
											</NavLink>
											<p className="CommonHomeListLastText">信息查询视频演示</p>
										</li>
									</ul>
								</div>
								<div className="homeThirdRightFourImg clearfix">
									<div className="homeThirdRightFourImgLeft">
										<img src="http://api.remote.dakapath.com/uploads/image/20180203/19/index07.jpg"  />
									</div>
									<div className="homeThirdRightFourImgRight">
										<img src="http://api.remote.dakapath.com/uploads/image/20180204/15/1.jpg"  />
									</div>
								</div>
							</div>
							{/*第五模块*/}
							<div className="homeThirdRightFifth clearfix">
								{/*第五模块左侧*/}
								<div className="homeThirdRightFifthLeft">
									<CommonTitle title="最新推荐" showRightIcon={true} topath="/communicate/communicateAll" />
									<div className="homeThirdRightFifthLeftContent clearfix">
										<NavLink   target="_blank"   to={`/microClassDetails/${academicList[0] ? academicList[0].id : ''}`}>
										<div className="homeThirdRightFifthLeftContentLeft">
											<img src={academicList.length > 0 ? academicList[0].cover ? academicList[0].cover.src : '' : ''}  />
										</div>

										<div className="homeThirdRightFifthLeftContentRight">
											<h2>{academicList.length > 0 ? academicList[0].title : ''}</h2>
											<p>{academicList.length > 0 ? academicList[0].desc : ''}</p>
										</div>
										</NavLink>
									</div>
									<div className="homeThirdRightFifthLeftContentList">
										{
											academicList.length > 0 ? academicList.map((item, index) => {
												if (index == 0){
													return ''
												}
												return (
													<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props}/>
												)
											}) : ''
										}
										<div className="homeThirdRightFifthLeftContentListMore">
											<CommonMore text="更多" toPath="/information" />
										</div>
										<div className="homeThirdRightFifthLeftContentListImg">
											<img src="http://api.remote.dakapath.com/uploads/image/20180204/15/2.jpg"  />
										</div>
									</div>
								</div>
								{/*第五模块右侧*/}
								<div className="homeThirdRightFifthRight">
									<CommonTitle title="病例讨论" showRightIcon={true} toPath="/communicate/communicateAll"/>

									<div className="homeThirdRightFifthRightContent clearfix">
										<NavLink   target="_blank"   to={`/communicateDetails/${academicList[0] ? academicList[0].id : ''}`}>
										<div className="homeThirdRightFifthRightContentLeft">
											<img src="http://api.remote.dakapath.com/uploads/image/20170915/09/3_1505440005.jpg"  />
										</div>

										<div className="homeThirdRightFifthRightContentRight">
											<h2>{communicationList.length > 0 ? communicationList[0].title : ''}</h2>
											<p>{communicationList.length > 0 ? communicationList[0].desc : ''}</p>
										</div>
										</NavLink>
									</div>
									<div className="homeThirdRightFifthRightContentList">
										{
											communicationList.length > 0 ? communicationList.map((item, index) => {
												if (index == 0){
													return ''
												}
												return (
													<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/communicateDetails/${item.id}`} {...this.props}/>
												)
											}) : ''
										}
										<div className="homeThirdRightFifthRightContentListMore">
											<CommonMore text="更多" toPath="/information" />
										</div>
										<div className="homeThirdRightFifthRightContentListImg">
											<img src="http://api.remote.dakapath.com/uploads/image/20180204/15/3.jpg"  />
										</div>
									</div>
								</div>
							</div>
							{/*第五模块结束*/}
							{/*第六模块*/}
							<div className="homeThirdRightSixth">
								<CommonTitle title="品牌推荐" showRightIcon={true} toPath="/information/enterprise"/>
								<ul className="homeThirdRightSixthContent clearfix">
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20180129/13/1.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20171220/09/629757567dc63cbf6a6b3c0285a04d0d.png"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170914/10/dm.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170914/10/qianmai_1505355038.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170630/19/brand3.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170619/19/pe.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170616/14/brand8.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170616/14/brand7.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170615/09/al.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170615/08/lilai.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170615/10/daka.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170614/15/roche.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170614/14/philips.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170614/14/leica.jpg"  />
										</NavLink>
									</li>
									<li>
										<NavLink   target="_blank"   to={`/microClassDetails/`}>
											<img src="http://api.remote.dakapath.com/uploads/image/20170614/14/abbvie_1497422490.jpg"  />
										</NavLink>
									</li>
								</ul>
							</div>
							{/*第六模块*/}
						</div>
					</div>
					<div className="homeMobileWrap">
						<div className="homeMobileFirst">
							<CommonTitle title="病理微课堂" showRightIcon={true} toPath="/microClass/micropathology"/>
							<div className="homeMobileFirstContent clearfix">
								<div className="homeMobileFirstContentLeft">
									<NavLink   target="_blank"   to={`/microClassDetails/${micronId}`}>
										<div className="homeMobileFirstContentLeftMenInduct">
											<div className="homeMobileFirstContentLeftMenInductImg">
												<img src={microClassList.length > 0 ? microClassList[0].tmp ? microClassList[0].tmp.avatar : '' : ''}  />
											</div>
											<h1>{microClassList.length > 0 ? microClassList[0].title : ''}</h1>
											<p>{microClassList.length > 0 ? microClassList[0].desc : ''}</p>
										</div>
									</NavLink>
								</div>
								<div className="homeMobileFirstContentRight">
									<ul className="homeMobileFirstContentRightList">
										{
											microClassList.length > 0 ? microClassList.map((item, index) => {
												return <li key={item.id} onClick={this.toPath.bind(this, `/microClassDetails/${item.id}`)}>{item.title}</li>
											}) : ''
										}
									</ul>
								</div>
							</div>
							<CommonMore text="更多" toPath="/find" />
						</div>
						<div className="homeMobileSecond">
							<CommonTitle title="微课堂" showRightIcon={true} toPath="/microClass/micropathology"/>
							<ul className="homeMobileSecondList">
								{
									microClassList.length > 0 ? microClassList.map((item, index) => {
										return (
											<li key={item.id} className="homeMobileSecondListContent clearfix" onClick={this.toPath.bind(this, `/microClassDetails/${item.id}`)}>
												<div className="homeMobileSecondListContentLeft">
													<img src={item.cover.src} />
												</div>
												<div className="homeMobileSecondListContentRight">
													<h1>{item.title}</h1>
													<p>{item.desc}</p>
													<span>{moment(item.publish_time).format('YYYY-MM-DD')}</span>
												</div>
											</li>
										)
									}) : ''
								}
							</ul>
						</div>
					</div>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

export default Home;




