import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import searchImg from '@/assets/searchImg.jpg';
import './index.css';

class CommonTmpList extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render () {
        const { articleList } = this.props;
        return (
            <Fragment>
                <NavLink   target="_blank"   to={`/experts/${articleList.id}`}>
                <div className="commonGuessListWrap">
                    <h1 className="commonGuessListTitle">
                        <NavLink   target="_blank"   to={`/experts/${articleList.id}`}>{articleList.name}</NavLink>
                    </h1>
                    <div className="commonGuessListContent clearfix">
                        <div className="commonGuessListContentLeft">
                            <NavLink   target="_blank"   to={`/experts/${articleList.id}`}>
                                <img src={articleList.avatar} />
                            </NavLink>
                        </div>
                        <div className="commonGuessListContentRight">
                            <NavLink   target="_blank"   to={`/experts/${articleList.id}`}>
                                <p className="commonGuessListContentRightText" >{articleList.synopsis}</p>

                            </NavLink>
                        </div>
                    </div>
                </div>
                </NavLink>
            </Fragment>
        )
    }
}

export default CommonTmpList;
