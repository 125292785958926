import React, { Component, Fragment } from 'react';
import Layout from '@/view/layout';
import CommunicateAll from './children/communicateAll';
import CommunicateDetail from './children/communicateDetail';
import './index.css';
const subRedirect = '/communicate';
const subToPath = '/communicate/communicateAll';
const subRoute = [
	{
		route: CommunicateAll,
		path: '/communicate/communicateAll'
	},
	{
		route: CommunicateDetail,
		path: '/communicate/communicateReadShare'
	},
	{
		route: CommunicateDetail,
		path: '/communicate/communicateCase'
	},
	{
		route: CommunicateDetail,
		path: '/communicate/communicateInternational'
	},
	{
		route: CommunicateDetail,
		path: '/communicate/communicateTroublesome'
	},
	{
		route: CommunicateDetail,
		path: '/communicate/communicatePractical'
	},
	{
		route: CommunicateDetail,
		path: '/communicate/communicateBoard'
	}
]

class Communicate extends Component {

	constructor(props) {
	    super(props);
		this.state = {}
	}

	render () {
		return (
			<Fragment>
				<Layout subRoute={subRoute} subRedirect={subRedirect} subToPath={subToPath} {...this.props}/>
			</Fragment>
		)
	}
}
export default Communicate;