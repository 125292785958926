import React,{ Component } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "@/view/login";
import Register from "@/view/register";
import ForgetPass from "@/view/forgetPass";
import UserInfo from "@/view/userInfo";
import Home from "@/view/home";
import Information from "@/view/information";
import Find from "@/view/find";
import Software from "@/view/software";
import MicroClass from "@/view/microClass";
import MicroClassDetails from "@/view/microClassDetails";
import Communicate from "@/view/communicate";
import CommunicateDetails from "@/view/communicateDetails";
import comDetails from "@/view/comDetails";
import Search from "@/view/search";
import Experts from "./view/experts";
import Tmp from "./view/tmp";
const customHistory = createBrowserHistory();

class App extends Component {
  render() {
    return (
		<Router history={customHistory}>
			<Switch>
				<Route path="/login" component={Login}/>
				<Route path="/register" component={Register}/>
				<Route path="/forgetPass" component={ForgetPass}/>
				<Route path="/userInfo" component={UserInfo}/>
				<Route exact path="/" component={Home}/>
				<Route path="/information" component={Information}/>
				<Route path="/find" component={Find}/>
				<Route path="/software" component={Software}/>
				<Route path="/microClass" component={MicroClass}/>
				<Route path="/microClassDetails/:id" component={MicroClassDetails}/>
				<Route path="/communicate" component={Communicate}/>
				<Route path="/communicateDetails/:id" component={CommunicateDetails}/>
				<Route path="/search/:id" component={Search}/>
				<Route path="/experts/:id" component={Experts}/>
				<Route path="/tmp" component={Tmp}/>
				<Redirect from="/" to="/" />
			</Switch>
		</Router>
    );
  }
}

export default App;
