import React, { Component, Fragment } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import PropTypes from 'prop-types';

import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';

import './index.css';

class Layout extends Component {
	constructor(props) {
		super(props);
	    this.state = {
			
		}
	}
	render () {
		const { subRoute, subRedirect, subToPath } = this.props;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				<div className="layoutWrap clearfix">
					<Switch>
						{
							subRoute.map((item, index) => {
								return (
									<Route key={index} path={item.path} component={item.route}/>
								)
							})
						}
						<Redirect from={subRedirect} to={subToPath} />
					</Switch>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

Layout.propTypes = {
	subRoute: PropTypes.array.isRequired,
	subRedirect: PropTypes.string.isRequired,
	subToPath: PropTypes.string.isRequired,
}

export default Layout;