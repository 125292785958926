import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';

class CommonSubnav extends Component {
	
	constructor(props) {
	    super(props);
		this.state = {
			
		}
	}
	
	render () {
		const { subNav } = this.props;
		const { pathname } = this.props.location;
		return (
			<ul className="commonSubnavWrap clearfix">
				{
					subNav.map((item, index) => {
						return (
							<li key={index}>
								<NavLink   target="_blank"   to={item.path} className={pathname === item.path ? 'commonSubnavWrapSelected' : ''}>{item.name}</NavLink>
							</li>
						)
					})
				}
			</ul>
		)
	}
}

CommonSubnav.propTypes = {
	subNav: PropTypes.array.isRequired
}


export default CommonSubnav;
