import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Pagination } from 'element-react';


import CommonHeader from '@/components/commonHeader';
import CommonFooter from '@/components/commonFooter';
import CommonTitle from '@/components/commonTitle';
import CommonCategory from '@/components/commonCategory';
import CommonGuessList from '@/components/commonGuessList'

import allRightImg from '@/assets/allRightImg.jpg';

import { getArticleList } from '@/api/microClass';

import './index.css';

class Search extends Component {

	constructor(props) {
	    super(props);
		this.state = {
			postData: {
				page: 1,
				start: 0,
				length: 20,
				kw: ''
			},
			searchArticleList: [],
			searchArticleListTotal: '',
			searchTitle: '',
			articleList: '',
			recommendationsList: '',
			pathologyList: '',
			readingList: '',
		}
	}

	componentDidMount () {
		const { id } = this.props.match.params;
		this.setState({
			postData: {
				page: 1,
				start: 0,
				length: 20,
				kw: id
			}
		}, () =>  {
			this.initSearchArticleList(this.state.postData);
		})

		this.initRecommendations();
	}

	// 搜索文章列表
	initSearchArticleList (data) {
		getArticleList(data).then(res => {
			if (res.success) {
				this.setState({
					searchArticleList: res.data.data,
					searchArticleListTotal: res.data.total
				}, () => {
				})
			}
		})
	}

	searchArticle (event) {
		let val = event.target.value;
		this.setState({
			searchTitle: val
		}, () => {
			console.log(this.state.searchTitle)
		})
	}

	searchBtn () {
		const  searchTitle  = this.state.searchTitle;
		console.log(searchTitle)
		this.setState({
			postData: {
				kw: searchTitle
			}
		})
		this.initSearchArticleList({kw: searchTitle})
	}

	toPath (pathName) {
		this.props.history.push(pathName);
	}

	handleSizeChange (val) {
		const { kw } = this.state.postData;
		this.setState({
			postData: {
				page: val,
				start: val * 20,
				length: 20,
				kw: kw
			}
		}, () => {
			this.initSearchArticleList(this.state.postData);
		})
	}

	handleCurrentChange (val) {
		const { kw } = this.state.postData;
		this.setState({
			postData: {
				page: val,
				start: (val - 1) * 20,
				length: 20,
				kw: kw
			}
		}, () => {
			this.initSearchArticleList(this.state.postData);
		})
	}

	initArticleList (category_id) {
		getArticleList({
			category_id
		}).then(res => {
			if (res.success) {
				this.setState({
					articleList: res.data.data
				})
			}
		})
	}

	// 专题推荐
	initRecommendations () {
		getArticleList({
			category_id: 59,
			length: 2
		}).then(res => {
			if (res.success) {
				this.setState({
					recommendationsList: res.data.data
				})
			}
		})
	}

	render () {
		const { postData, searchArticleList, searchArticleListTotal, searchTitle, articleList, recommendationsList, pathologyList, readingList } = this.state;
		const token = sessionStorage.getItem('token');
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
		return (
			<Fragment>
				<CommonHeader token={token} userInfo={userInfo} {...this.props} />
				<div className="searchWrap clearfix">
					<div className="searchLeft">
						<div className="searchFirstLeft">
							<form className="clearfix">
								<input type="text" defaultValue={postData.kw} onChange={this.searchArticle.bind(this)} />
								<input type="submit" defaultValue="搜索" onClick={this.searchBtn.bind(this)} />
							</form>
							<p>找到约 {searchArticleListTotal} 条结果</p>
						</div>
						<div className="searchSecondLeft">
							<h1>全部结果</h1>
						</div>
						<div className="guessContentLeftFirst">
							{
								searchArticleList.length > 0 ? searchArticleList.map((item, index) => {
									return (
										<CommonGuessList key={item.id} articleList={item} />
									)
								}) : ''
							}
							{
								searchArticleList.length > 0 ? <div className="guessContentLeftFirstPagination">
									<Pagination onSizeChange={this.handleSizeChange.bind(this)} onCurrentChange={this.handleCurrentChange.bind(this)} currentPage={postData.page} pageSizes="[5,10,15,20]" pageSize={postData.length} layout="prev, pager, next"  total={searchArticleListTotal}   />
								</div> : ''
							}
						</div>
					</div>
					<div className="searchRight">
						<div className="searchRightFirst">
							<CommonTitle title="专题推荐" toPath="/find/specialTopic"/>

								<div className="searchRightFirstContent">
									<div className="searchRightFirstContentImg">
										<img src={allRightImg} alt="searchRightImg" />
									</div>
									{
										recommendationsList.length > 0 ? recommendationsList.map((item, index) => {
											return (
												<CommonCategory key={item.id} showLeftIcon={false} categoryName={item.title} isShowRightNum={false} toPath={`/microClassDetails/${item.id}`} {...this.props} />
											)
										}) : ''
									}
								</div>
						</div>
						<div className="searchRightSecond">
							<CommonTitle title="热门标签" toPath="/find/specialTopic"/>
							<ul className="clearfix">
								<li>
									<NavLink   target="_blank"   to="/search/PD-1">PD-1</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/精准医学">精准医学</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/穿刺">穿刺</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/慢性淋巴性白血病">慢性淋巴性白血病</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/消化道肿瘤">消化道肿瘤</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/膀胱肉瘤样癌">膀胱肉瘤样癌</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/急性骨髓性白血病">急性骨髓性白血病</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/滤泡性肿瘤">滤泡性肿瘤</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/子宫内膜增生">子宫内膜增生</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/肾脏肿瘤新分类">肾脏肿瘤新分类</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/第三方医学检验">第三方医学检验</NavLink>
								</li>
								<li>
									<NavLink   target="_blank"   to="/search/肿瘤治疗">肿瘤治疗</NavLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<CommonFooter />
			</Fragment>
		)
	}
}

export default Search;
