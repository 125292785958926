import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import open from '../../assets/open.png';
import close from '../../assets/close.png';
import './index.css';

class CommonHeader extends Component {

	constructor(props) {
	    super(props);
		this.ulElement = React.createRef();
		this.state = {
			isShowNav: false,
			searchTitle: ''
		}
	}

	componentDidMount () {
		this.ulElement.current.addEventListener("click", () => {
			this.setState({
				isShowNav: false
			})
		});
	}

	searchArticle (event) {
		let val = event.target.value;
		this.setState({
			searchTitle: val
		}, () => {
			console.log(this.state.searchTitle)
		})
	}

	searchBtn () {
		const { searchTitle } = this.state;
		this.props.history.push('/search/' + searchTitle);
	}

	showNav () {
		const { isShowNav } = this.state;
		this.setState({
			isShowNav: !isShowNav
		})
	}

	toPath (pathName) {
		this.props.history.push('/' + pathName)
	}

	loginOut () {
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('userInfo');
		this.props.history.push('/login');
	}

	render () {
		const { isShowNav, searchTitle } = this.state;
		const { token, userInfo } = this.props;
		return (
			<Fragment>
				<div className="headerWrap">
					<div className="headerContainer clearfix">
						<ul className="headerNavList clearfix" ref={this.ulElement}>
							<li>
								<NavLink   target="_blank"   to="/home" activeClassName="navSelected">
									<i className="homeIcon"></i>
									首页
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/information" activeClassName="navSelected">
									<i className="newsIcon"></i>
									资讯
								</NavLink>
							</li>
							<li>
								<a href="http://dia.dakapath.com/" activeClassName="navSelected">
									<i className="newsEnquiry"></i>
									查询
								</a>
							</li>
							<li>
								<NavLink   target="_blank"   to="/find" activeClassName="navSelected">
									<i className="findIcon"></i>
									发现
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/software" activeClassName="navSelected">
									<i className="softwareIcon"></i>
									软件
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/microClass" activeClassName="navSelected">
									<i className="lessonIcon"></i>
									微课堂
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/communicate" activeClassName="navSelected">
									<i className="communicateIcon"></i>
									交流
								</NavLink>
							</li>
							<li>
								<a href="http://hz.dakapath.com/" activeClassName="navSelected">
									<i className="newsConsults"></i>
									会诊
								</a>
							</li>
							<li>
								<a href="http://report.dakapath.com/" activeClassName="navSelected">
									<i className="newsReportEnquiry"></i>
									报告查询
								</a>
							</li>
						</ul>
						{
							(!token || !userInfo) ? <div className="headerRight"><span onClick={this.toPath.bind(this, 'login')}>登录</span>&nbsp;/&nbsp;<span onClick={this.toPath.bind(this, 'register')}>注册</span></div> : <div className="headerRight"><span onClick={this.toPath.bind(this, 'userInfo')}>{userInfo.realname}</span>&nbsp;/&nbsp;<span onClick={this.loginOut.bind(this)}>[退出]</span></div>
						}
					</div>
					<div className="headerFooter">
						<div className="headerFooterContainer clearfix">
							<div className="headerFooterLogo">
								<span></span>
								<img src={logo} alt="logo" />
							</div>
							<form className="clearfix">
								<input type="text" defaultValue={searchTitle} onChange={this.searchArticle.bind(this)} placeholder="输入关键字" />
								<button onClick={this.searchBtn.bind(this)}>搜索</button>
							</form>
						</div>
					</div>
				</div>
				<div className="headerMobileWrap clearfix">
					<div className="headerMobileLogo" onClick={this.showNav.bind(this)}>
						{!isShowNav && <img src={open} alt="open" />}
						{isShowNav && <img src={close} alt="close" />}
					</div>
					{
						isShowNav && <div className="headerMobileContent">
						<ul className="headerMobileNavList">
							<li>
								<NavLink   target="_blank"   to="/home" activeClassName="navSelected">
									<i className="homeIcon"></i>
									首页
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/information" activeClassName="navSelected">
									<i className="newsIcon"></i>
									资讯
								</NavLink>
							</li>
							<li>
								<a href="http://dia.dakapath.com/" activeClassName="navSelected">
									<i className="newsEnquiry"></i>
									查询
								</a>
							</li>
							<li>
								<NavLink   target="_blank"   to="/find" activeClassName="navSelected">
									<i className="findIcon"></i>
									发现
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/software" activeClassName="navSelected">
									<i className="softwareIcon"></i>
									软件
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/microClass" activeClassName="navSelected">
									<i className="lessonIcon"></i>
									微课堂
								</NavLink>
							</li>
							<li>
								<NavLink   target="_blank"   to="/communicate" activeClassName="navSelected">
									<i className="communicateIcon"></i>
									交流
								</NavLink>
							</li>
							<li>
								<a href="http://hz.dakapath.com/" activeClassName="navSelected">
									<i className="newsConsults"></i>
									会诊
								</a>
							</li>
							<li>
								<a href="http://report.dakapath.com/" activeClassName="navSelected">
									<i className="newsReportEnquiry"></i>
									报告查询
								</a>
							</li>
						</ul>
						{
							(!token || !userInfo) ? <div className="headerMobileRight"><span onClick={this.toPath.bind(this, 'login')}>登录</span>&nbsp;/&nbsp;<span onClick={this.toPath.bind(this, 'register')}>注册</span></div> : <div className="headerMobileRight"><span onClick={this.toPath.bind(this, 'userInfo')} >{userInfo.realname}</span>&nbsp;/&nbsp;<span onClick={this.loginOut.bind(this)}>[退出]</span></div>
						}
						<div className="headerMobileFooter">
							<form className="clearfix">
								<input type="text" defaultValue={searchTitle} onChange={this.searchArticle.bind(this)} placeholder="输入关键字" />
								<button onClick={this.searchBtn.bind(this)}>搜索</button>
							</form>
						</div>
					</div>
					}
				</div>
			</Fragment>
		)
	}
}

export default CommonHeader;
