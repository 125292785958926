import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import './index.css';

class CommonFindList extends Component {

	constructor(props) {
	    super(props);
		this.state = {

		}
	}

	render () {
		const { item } = this.props;
		return (
			<Fragment>
				<div className="commonFindListWrap">
					<NavLink   target="_blank"   to={`/microClassDetails/${item.id}`} className="clearfix">
						<div className="commonFindListContentLeft">
							<h1>{moment(item.meet_time).format('MM-DD')}</h1>
							<p>{item.address}</p>
						</div>
						<div className="commonFindListContentRight">
							<h2 className="commonFindListContentRightTitle">{item.title}</h2>
							<p className="commonFindListContentRightSubTitle">{item.desc}</p>
							<div className="commonFindListContentRightBottom clearfix">
								<p className="commonFindListContentRightBottomTime">会议时间：{item.meet_time}——{item.meet_end_time}</p>
								<p className="commonFindListContentRightBottomAdress">地点：{item.address}</p>
							</div>
						</div>
					</NavLink>
				</div>
			</Fragment>
		)
	}
}

export default CommonFindList;
