import React, { Component, Fragment } from 'react';
import Layout from '@/view/layout';
import CorrectionTable from './children/correctionTable';
import Search from './children/search';
import Guess from './children/guess';
import Micropathology from './children/micropathology';
import './index.css';
const subRedirect = '/microClass';
const subToPath = '/microClass/micropathology';
const subRoute = [
	{
		route: CorrectionTable,
		path: '/microClass/correctionTable'
	},
	{
		route: Search,
		path: '/microClass/search'
	},
	{
		route: Guess,
		path: '/microClass/guess'
	},
	{
		route: Micropathology,
		path: '/microClass/micropathology'
	}
]

class MicroClass extends Component {

	constructor(props) {
		super(props);
	    this.state = {

		}
	}

	render () {
		return (
			<Fragment>
				<Layout subRoute={subRoute} subRedirect={subRedirect} subToPath={subToPath} {...this.props} />
			</Fragment>
		)
	}
}
export default MicroClass;
