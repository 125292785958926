import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import searchImg from '@/assets/searchImg.jpg';
import './index.css';

class CommonGuessList extends Component {
	constructor(props) {
	    super(props);
		this.state = {

		}
	}
	render () {
		const { articleList } = this.props;
		return (
			<Fragment>
				<div className="commonGuessListWrap">
					<h1 className="commonGuessListTitle">
						<NavLink   target="_blank"   to={`/microClassDetails/${articleList.id}`}>{articleList.title}</NavLink>
					</h1>
					<div className="commonGuessListContent clearfix">
						<div className="commonGuessListContentLeft">
							<NavLink   target="_blank"   to={`/microClassDetails/${articleList.id}`}>
								<img src={articleList.cover.src} />
							</NavLink>
						</div>
						<div className="commonGuessListContentRight">
							<NavLink   target="_blank"   to={`/microClassDetails/${articleList.id}`}>
								<p className="commonGuessListContentRightText">{articleList.desc}</p>
								<p className="commonGuessListContentRightTime">{moment(articleList.publish_time).format('YYYY-MM-DD')}</p>
							</NavLink>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default CommonGuessList;
