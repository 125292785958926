import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './index.css';

class commonPosition extends Component {

	constructor(props) {
	    super(props);
		this.state = {

		}
	}
	render () {
		const { positionArr, title } = this.props;
		return (
			<Fragment>
				<ul className="commonPositionWrap clearfix">
					{
						positionArr.length > 0 ? positionArr.map((item, index) => {
							return (
								<Fragment key={index}>
									<li>
										<NavLink   target="_blank"   to={item.url}>{item.name}</NavLink>
									</li>
									<li>
										>
									</li>
								</Fragment>
							)
						}) : ''
					}
					<li>
						<a href="javascript:;">{title}</a>
					</li>
				</ul>
				<ul className="commonPositionMobileWrap clearfix">
					{
						positionArr.length > 0 ? positionArr.map((item, index) => {
							return (
								<Fragment key={index}>
									<li>
										<NavLink   target="_blank"   to={item.url}>{item.name}</NavLink>
									</li>
									<li>
										>
									</li>
								</Fragment>
							)
						}) : ''
					}
					<li>
						<a href="javascript:;">{title}</a>
					</li>
				</ul>
			</Fragment>
		)
	}
}

export default commonPosition;
